import React, {useState, useEffect, useRef} from 'react';
import styles from './MapSection.module.scss';
import {ApartmentDto, Complex} from '../../../../services';
import { NearbyObjects } from "../../../ComplexPage/components/NearbyObjects";
import './MapSection.module.scss';
import {complexItemStore} from "../../../../stores/ComplexItemStore/store";

interface MapSectionProps {
  complexData: Complex;
  apartmentData: ApartmentDto;
}

export const MapSection: React.FC<MapSectionProps> = ({ complexData, apartmentData }) => {

  const nearRef = useRef<HTMLDivElement>(null);
  const complexPolygon = complexData?.polygon || [];
  const apartmentPolygonRaw = apartmentData?.polygon
    ? JSON.parse(apartmentData?.polygon)
    : [];
  const reversedComplex = complexItemStore.reversePolygonCoords(complexPolygon);
  const reversedApartment = complexItemStore.reversePolygonCoords(apartmentPolygonRaw);

  return (
    <section className={styles.sectionBlock}>
      <h2 className={styles.sectionTitle}>Расположение</h2>
      <div className={styles.content}>
        {complexData?.buildings && (
          <NearbyObjects
            innerRef={nearRef}
            description="Поблизости"
            buildings={complexData?.buildings}
            polygon={reversedComplex}
            polygon2={reversedApartment}
            className={styles.contentInner}
          />
        )}
      </div>
    </section>
  );
};





