import React from 'react';
import {useTheme} from '../../context/theme/useTheme'; // <-- Импортируйте ваш хук/контекст темы

type Props = {
  isActive: boolean;
};

const GenplanTableIconMobile = ({ isActive }: Props) => {
  const { theme } = useTheme();

  if (isActive) {
    return (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <mask id="path-1-inside-1_6845_7883" fill="white">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M4.5 2V4C4.5 4.55228 4.94772 5 5.5 5H19.5C20.0523 5 20.5 4.55228 20.5 4V2H22.5V4C22.5 5.65685 21.1569 7 19.5 7H5.5C3.84315 7 2.5 5.65685 2.5 4V2H4.5ZM20.5 22V20C20.5 19.4477 20.0523 19 19.5 19H5.5C4.94772 19 4.5 19.4477 4.5 20V22H2.5V20C2.5 18.3431 3.84315 17 5.5 17H19.5C21.1569 17 22.5 18.3431 22.5 20V22H20.5ZM5.5 10H19.5C20.0523 10 20.5 10.4477 20.5 11V13C20.5 13.5523 20.0523 14 19.5 14H5.5C4.94772 14 4.5 13.5523 4.5 13V11C4.5 10.4477 4.94772 10 5.5 10ZM2.5 11C2.5 9.34315 3.84315 8 5.5 8H19.5C21.1569 8 22.5 9.34315 22.5 11V13C22.5 14.6569 21.1569 16 19.5 16H5.5C3.84315 16 2.5 14.6569 2.5 13V11Z"/>
        </mask>
        <path fillRule="evenodd" clipRule="evenodd"
              d="M4.5 2V4C4.5 4.55228 4.94772 5 5.5 5H19.5C20.0523 5 20.5 4.55228 20.5 4V2H22.5V4C22.5 5.65685 21.1569 7 19.5 7H5.5C3.84315 7 2.5 5.65685 2.5 4V2H4.5ZM20.5 22V20C20.5 19.4477 20.0523 19 19.5 19H5.5C4.94772 19 4.5 19.4477 4.5 20V22H2.5V20C2.5 18.3431 3.84315 17 5.5 17H19.5C21.1569 17 22.5 18.3431 22.5 20V22H20.5ZM5.5 10H19.5C20.0523 10 20.5 10.4477 20.5 11V13C20.5 13.5523 20.0523 14 19.5 14H5.5C4.94772 14 4.5 13.5523 4.5 13V11C4.5 10.4477 4.94772 10 5.5 10ZM2.5 11C2.5 9.34315 3.84315 8 5.5 8H19.5C21.1569 8 22.5 9.34315 22.5 11V13C22.5 14.6569 21.1569 16 19.5 16H5.5C3.84315 16 2.5 14.6569 2.5 13V11Z"
              fill="#333338"/>
        <path
          d="M4.5 2H6.5V0H4.5V2ZM20.5 2V0H18.5V2H20.5ZM22.5 2H24.5V0H22.5V2ZM2.5 2V0H0.5V2H2.5ZM20.5 22H18.5V24H20.5V22ZM4.5 22V24H6.5V22H4.5ZM2.5 22H0.5V24H2.5V22ZM22.5 22V24H24.5V22H22.5ZM6.5 4V2H2.5V4H6.5ZM5.5 3C6.05228 3 6.5 3.44772 6.5 4H2.5C2.5 5.65685 3.84315 7 5.5 7V3ZM19.5 3H5.5V7H19.5V3ZM18.5 4C18.5 3.44771 18.9477 3 19.5 3V7C21.1569 7 22.5 5.65685 22.5 4H18.5ZM18.5 2V4H22.5V2H18.5ZM22.5 0H20.5V4H22.5V0ZM24.5 4V2H20.5V4H24.5ZM19.5 9C22.2614 9 24.5 6.76142 24.5 4H20.5C20.5 4.55228 20.0523 5 19.5 5V9ZM5.5 9H19.5V5H5.5V9ZM0.5 4C0.5 6.76142 2.73858 9 5.5 9V5C4.94772 5 4.5 4.55228 4.5 4H0.5ZM0.5 2V4H4.5V2H0.5ZM4.5 0H2.5V4H4.5V0ZM18.5 20V22H22.5V20H18.5ZM19.5 21C18.9477 21 18.5 20.5523 18.5 20H22.5C22.5 18.3431 21.1569 17 19.5 17V21ZM5.5 21H19.5V17H5.5V21ZM6.5 20C6.5 20.5523 6.05229 21 5.5 21V17C3.84315 17 2.5 18.3431 2.5 20H6.5ZM6.5 22V20H2.5V22H6.5ZM2.5 24H4.5V20H2.5V24ZM0.5 20V22H4.5V20H0.5ZM5.5 15C2.73858 15 0.5 17.2386 0.5 20H4.5C4.5 19.4477 4.94772 19 5.5 19V15ZM19.5 15H5.5V19H19.5V15ZM24.5 20C24.5 17.2386 22.2614 15 19.5 15V19C20.0523 19 20.5 19.4477 20.5 20H24.5ZM24.5 22V20H20.5V22H24.5ZM20.5 24H22.5V20H20.5V24ZM19.5 8H5.5V12H19.5V8ZM22.5 11C22.5 9.34314 21.1569 8 19.5 8V12C18.9477 12 18.5 11.5523 18.5 11H22.5ZM22.5 13V11H18.5V13H22.5ZM19.5 16C21.1569 16 22.5 14.6569 22.5 13H18.5C18.5 12.4477 18.9477 12 19.5 12V16ZM5.5 16H19.5V12H5.5V16ZM2.5 13C2.5 14.6569 3.84315 16 5.5 16V12C6.05228 12 6.5 12.4477 6.5 13H2.5ZM2.5 11V13H6.5V11H2.5ZM5.5 8C3.84315 8 2.5 9.34315 2.5 11H6.5C6.5 11.5523 6.05229 12 5.5 12V8ZM5.5 6C2.73858 6 0.5 8.23858 0.5 11H4.5C4.5 10.4477 4.94772 10 5.5 10V6ZM19.5 6H5.5V10H19.5V6ZM24.5 11C24.5 8.23858 22.2614 6 19.5 6V10C20.0523 10 20.5 10.4477 20.5 11H24.5ZM24.5 13V11H20.5V13H24.5ZM19.5 18C22.2614 18 24.5 15.7614 24.5 13H20.5C20.5 13.5523 20.0523 14 19.5 14V18ZM5.5 18H19.5V14H5.5V18ZM0.5 13C0.5 15.7614 2.73858 18 5.5 18V14C4.94772 14 4.5 13.5523 4.5 13H0.5ZM0.5 11V13H4.5V11H0.5Z"
          fill="#4E6AFF" mask="url(#path-1-inside-1_6845_7883)"/>
      </svg>
    );
  }

  if (theme === 'dark-theme') {
    return (
      <svg
        width="25"
        height="24"
        viewBox="0 0 25 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <mask id="path-1-inside-1_7183_50326" fill="white">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.5 2V4C4.5 4.55228 4.94772 5 5.5 5H19.5C20.0523 5 20.5 4.55228 20.5 4V2H22.5V4C22.5 5.65685 21.1569 7 19.5 7H5.5C3.84315 7 2.5 5.65685 2.5 4V2H4.5ZM20.5 22V20C20.5 19.4477 20.0523 19 19.5 19H5.5C4.94772 19 4.5 19.4477 4.5 20V22H2.5V20C2.5 18.3431 3.84315 17 5.5 17H19.5C21.1569 17 22.5 18.3431 22.5 20V22H20.5ZM5.5 10H19.5C20.0523 10 20.5 10.4477 20.5 11V13C20.5 13.5523 20.0523 14 19.5 14H5.5C4.94772 14 4.5 13.5523 4.5 13V11C4.5 10.4477 4.94772 10 5.5 10ZM2.5 11C2.5 9.34315 3.84315 8 5.5 8H19.5C21.1569 8 22.5 9.34315 22.5 11V13C22.5 14.6569 21.1569 16 19.5 16H5.5C3.84315 16 2.5 14.6569 2.5 13V11Z"
          />
        </mask>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 2V4C4.5 4.55228 4.94772 5 5.5 5H19.5C20.0523 5 20.5 4.55228 20.5 4V2H22.5V4C22.5 5.65685 21.1569 7 19.5 7H5.5C3.84315 7 2.5 5.65685 2.5 4V2H4.5ZM20.5 22V20C20.5 19.4477 20.0523 19 19.5 19H5.5C4.94772 19 4.5 19.4477 4.5 20V22H2.5V20C2.5 18.3431 3.84315 17 5.5 17H19.5C21.1569 17 22.5 18.3431 22.5 20V22H20.5ZM5.5 10H19.5C20.0523 10 20.5 10.4477 20.5 11V13C20.5 13.5523 20.0523 14 19.5 14H5.5C4.94772 14 4.5 13.5523 4.5 13V11C4.5 10.4477 4.94772 10 5.5 10ZM2.5 11C2.5 9.34315 3.84315 8 5.5 8H19.5C21.1569 8 22.5 9.34315 22.5 11V13C22.5 14.6569 21.1569 16 19.5 16H5.5C3.84315 16 2.5 14.6569 2.5 13V11Z"
          fill="#E1E4EC"
        />
        <path
          d="M4.5 2H6.5V0H4.5V2Z
             M20.5 2V0H18.5V2H20.5Z
             M22.5 2H24.5V0H22.5V2Z
             M2.5 2V0H0.5V2H2.5Z
             M20.5 22H18.5V24H20.5V22Z
             M4.5 22V24H6.5V22H4.5Z
             M2.5 22H0.5V24H2.5V22Z
             M22.5 22V24H24.5V22H22.5Z
             ...
             (остальные path-данные)
          "
          fill="#E1E4EC"
          mask="url(#path-1-inside-1_7183_50326)"
        />
      </svg>
    );
  }

  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_6845_6238" fill="white">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M4.5 2V4C4.5 4.55228 4.94772 5 5.5 5H19.5C20.0523 5 20.5 4.55228 20.5 4V2H22.5V4C22.5 5.65685 21.1569 7 19.5 7H5.5C3.84315 7 2.5 5.65685 2.5 4V2H4.5ZM20.5 22V20C20.5 19.4477 20.0523 19 19.5 19H5.5C4.94772 19 4.5 19.4477 4.5 20V22H2.5V20C2.5 18.3431 3.84315 17 5.5 17H19.5C21.1569 17 22.5 18.3431 22.5 20V22H20.5ZM5.5 10H19.5C20.0523 10 20.5 10.4477 20.5 11V13C20.5 13.5523 20.0523 14 19.5 14H5.5C4.94772 14 4.5 13.5523 4.5 13V11C4.5 10.4477 4.94772 10 5.5 10ZM2.5 11C2.5 9.34315 3.84315 8 5.5 8H19.5C21.1569 8 22.5 9.34315 22.5 11V13C22.5 14.6569 21.1569 16 19.5 16H5.5C3.84315 16 2.5 14.6569 2.5 13V11Z"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 2V4C4.5 4.55228 4.94772 5 5.5 5H19.5C20.0523 5 20.5 4.55228 20.5 4V2H22.5V4C22.5 5.65685 21.1569 7 19.5 7H5.5C3.84315 7 2.5 5.65685 2.5 4V2H4.5ZM20.5 22V20C20.5 19.4477 20.0523 19 19.5 19H5.5C4.94772 19 4.5 19.4477 4.5 20V22H2.5V20C2.5 18.3431 3.84315 17 5.5 17H19.5C21.1569 17 22.5 18.3431 22.5 20V22H20.5ZM5.5 10H19.5C20.0523 10 20.5 10.4477 20.5 11V13C20.5 13.5523 20.0523 14 19.5 14H5.5C4.94772 14 4.5 13.5523 4.5 13V11C4.5 10.4477 4.94772 10 5.5 10ZM2.5 11C2.5 9.34315 3.84315 8 5.5 8H19.5C21.1569 8 22.5 9.34315 22.5 11V13C22.5 14.6569 21.1569 16 19.5 16H5.5C3.84315 16 2.5 14.6569 2.5 13V11Z"
        fill="#333338"
      />
      <path
        d="M4.5 2H6.5V0H4.5V2Z
           M20.5 2V0H18.5V2H20.5Z
           M22.5 2H24.5V0H22.5V2Z
           M2.5 2V0H0.5V2H2.5Z
           M20.5 22H18.5V24H20.5V22Z
           M4.5 22V24H6.5V22H4.5Z
           M2.5 22H0.5V24H2.5V22Z
           M22.5 22V24H24.5V22H22.5Z
           ...
           (остальные path-данные)
        "
        fill="#333338"
        mask="url(#path-1-inside-1_6845_6238)"
      />
    </svg>
  );
};

export default GenplanTableIconMobile;
