import React, { useMemo } from 'react';
import { flow, get, isNil } from 'lodash/fp';
import cc from 'classcat';
import {AlbumList, ErrorBoundary, OverlayZoomButton, TruncateHtml} from 'components/atoms';
import {
  ApartmentDto,
  BuildingEntity,
  Complex,
  Feature,
  findBuildingByIdInComplex, Params, SalesDepartments,
} from 'services';
import { CottageHeader } from '../CottageHeader/CottageHeader';
// import { RenderBuildingToMap } from '../RenderBuildingToMap';
// import { ShowApartmentOnFloorLayout } from '../ShowApartmentOnFloorLayout';
import { getWindowsFromLayers, reversePolygonCoords } from '../../../../../utils';
import { useBuildingOnMapPopup } from '../../../../../containers/BuildingOnMapPopup';

import styles from './CottageViewContent.module.scss';
import { dataTestIdToProps } from '../../../../../../../utils/test/dataTestIdToProps';
import { rootApartmentStore } from '../../../../../../../stores/Apartment/RootApartmentStore';
import { Reward } from "../Reward/Reward";
import { RenderBuildingToMap } from "../../../../ApartmentView/components/RenderBuildingToMap";
import {ContactCard} from "../ContactCard/ContactCard";
import {TextDescription} from "../TextDescription/TextDescription";
import {Cottage} from "../../../../../../../services/v1/villageService/types";
import {useParams} from "react-router-dom";
import {villageStore} from "../../../../../../../stores";

type Props = {
  apartment?: Cottage;
  complex: Complex | null;
};

const ApartmentViewContentTestIDs = {
  wrapper: 'ApartmentViewContent_wrapper',
};

export const CottageViewContent = ({ apartment, complex }: Props) => {
  const { complexId, generalPlanId } =
    useParams<Pick<Params, 'complexId' | 'generalPlanId'>>();

  const polygonData = useMemo(() => {
    if (apartment?.polygon) {
      try {
        return JSON.parse(apartment.polygon);
      } catch (e) {
        console.error('Invalid polygon data', e);
        return null;
      }
    }
    return null;
  }, [apartment?.polygon]);

  // Step 2: Reverse the coordinates if necessary
  const reversedPolygon = useMemo(() => {
    if (polygonData) {
      return reversePolygonCoords(polygonData);
    }
    return null;
  }, [polygonData]);

  const { showBuildingOnMapPopup } = useBuildingOnMapPopup({
    windows: [],
    polygon: reversedPolygon,
  });

  return (
    <>
      <div
        {...dataTestIdToProps(ApartmentViewContentTestIDs.wrapper)}
        className={cc([
          styles.wrapper,
          // {
          //   [styles.wrapperIsNotFloorPlan]: isNotShowFloorPlan,
          // },
        ])}
      >
        {/* @todo: PromotionsHeader */}
        <CottageHeader apartment={apartment} />

        {
          apartment?.agent_commission_sum && <Reward count={apartment?.agent_commission_sum} percent={apartment?.agent_commission_percent ? apartment?.agent_commission_percent : '' } />
        }

        {/*/!* @todo: Map *!/*/}
        {reversedPolygon && (
          <div className={styles.mapWrapper}>
            <ErrorBoundary>
              <OverlayZoomButton
                className={styles.complexMap}
                onClick={showBuildingOnMapPopup}
              >
                <RenderBuildingToMap
                  hideZoomControl
                  className={styles.complexMap}
                  polygon={reversedPolygon}
                  windows={[]}
                />
              </OverlayZoomButton>
            </ErrorBoundary>
          </div>
        )}

        {
          villageStore && villageStore?.villageDetails && villageStore?.villageDetails?.salesDepartments && (
            <ContactCard />
          )
        }

        {
          apartment?.albums && apartment?.albums?.length > 0 && complexId && (
            <div className={styles.complexAlbums}>
              <AlbumList albums={apartment?.albums} complexId={String(apartment?.id)} isCottage={true} />
            </div>
          )
        }

        {
          apartment?.description && (
            <TextDescription description={apartment?.description ? apartment?.description : ''} />
          )
        }

        {/* @todo: Description */}
        {/* @todo: Sales department */}

        {/*
        @todo: import Albums from 'components/containers/ResidentialComplexPanel/components/Albums';
       */}
      </div>
      {/*{!isNotShowFloorPlan && (*/}
      {/*  <div className={styles.floorPlanWrapper}>*/}
      {/*    <ErrorBoundary>*/}
      {/*      <ShowApartmentOnFloorLayout*/}
      {/*        hideZoomControl*/}
      {/*        apartment={apartmentUIStore.activeApartment}*/}
      {/*      />*/}
      {/*    </ErrorBoundary>*/}
      {/*  </div>*/}
      {/*)}*/}
    </>
  );
};
