import React from 'react';
import { Complex } from "../../../../services";
import styles from './ResidentialComplex.module.scss';

interface ResidentialComplexProps {
  complexData: Complex;
  objectNameQP?: boolean;
  developerNameQP: boolean;
}

export const ResidentialComplex: React.FC<ResidentialComplexProps> = ({ complexData, objectNameQP, developerNameQP }) => {
  // Данные о ЖК
  const complexTitle = complexData?.title || '';
  const complexImage = complexData?.photo?.url || '';

  // Данные о застройщике
  const developer = complexData?.developer;
  const developerName = developer?.title || '';
  const developerLogo = developer?.logo?.url || '';
  const developerSlogan = developer?.synonym || '';

  return (
    <section className={styles.residentialComplex}>
      <div className={styles.bottomBoxShedow}></div>
      <div className={styles.imageContainer}>
        {complexImage && (
          <img src={complexImage} alt={complexTitle} className={styles.image} />
        )}
        <div className={styles.infoOverlay}>
          {complexTitle && objectNameQP && <h2 className={styles.name}>{complexTitle}</h2>}
          {developer && developerNameQP && (
            <div className={styles.developer}>
              {developerLogo && (
                <img
                  src={developerLogo}
                  alt={developerName}
                  className={styles.developerLogo}
                />
              )}
              <div className={styles.developerDetails}>
                {developerName && (
                  <p className={styles.developerName}>{developerName}</p>
                )}
                {developerSlogan && (
                  <p className={styles.developerStats}>{developerSlogan}</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
