import React from 'react';
import { toJS } from 'mobx';
import { observer } from "mobx-react";
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { Badge } from 'components/atoms';
import { Icon } from 'components/atoms/Icon';
import { getCountActiveFilter } from 'components/containers/Search/utils';
import CloseIcon from "../../../../../../components/ui/CloseIcon";
import { columnsStore } from "../../../../../../stores";
import useWindowSize from "../../../../../../hooks/useWindowSize";
import { useTheme } from "../../../../../../context/theme/useTheme";
import filterIconBlue from 'assets/filtersIconBlue.svg';
import options from "assets/optionsColorIcon.svg";
import optionsDark from "assets/optionsDarkIcon.svg";
import styles from './styles.module.scss';

type Props<Filter extends {}> = {
  onFilterReset?: () => void;
  onFilterOpen?: () => void;
  resultedFilters?: Partial<Filter>;
  hideSaveButton?: boolean;
};

export const FilterButtonWithFilterCountTestIDs = {
  open: 'LayoutFiltersOpen',
  reset: 'LayoutFiltersReset',
};

export const FilterButtonWithFilterCount = observer(<Filter extends {}>({
  onFilterOpen,
  onFilterReset,
  resultedFilters,
}: Props<Filter>) => {
  const countActiveFilter = getCountActiveFilter(toJS(resultedFilters));
  const { columns } = columnsStore;
  const { width } = useWindowSize();
  const { theme } = useTheme();

  return (
    <>

      {
        width && width > 768 ? (
          <>
            <ButtonBase
              data-test-id={FilterButtonWithFilterCountTestIDs.open}
              onClick={onFilterOpen}
              icon={
                <Icon alt="more filter" src={filterIconBlue} />
                // <FilterIcon />
              }
              className={styles.filterButton}
            >
              {
                !(columns === 3 && countActiveFilter > 0) && (
                  <span className={styles.filtersText}>Фильтры</span>
                )
              }
              {
                countActiveFilter > 1  && (
                  <Badge size="medium" isCircle variants="alert">
                    {countActiveFilter - 1}
                  </Badge>
                )
              }
            </ButtonBase>
            {countActiveFilter > 1 && (
              <>
                <ButtonBase
                  data-test-id={FilterButtonWithFilterCountTestIDs.reset}
                  onClick={onFilterReset}
                  // icon={<Icon size={10} alt="clear filter" src={close} />}
                  icon={<CloseIcon />}
                  className={styles.resetButton}
                >
                  {
                    columns === 3 ? '' : 'Сбросить'
                  }
                </ButtonBase>
              </>
            )}
          </>
        ) : (
          <div className={styles.filtersMobileBlock}>
            <ButtonBase
              onClick={onFilterOpen}
              color="transparent"
              icon={<Icon src={theme === 'dark-theme' ? optionsDark : options} size={24} alt="открыть фильтр"/>}
              className={styles.villageFiltersBtn}
            />
            {
              countActiveFilter > 1  && (
                <Badge size="medium" isCircle variants="alert" className={styles.filtersCountMobile}>
                  {countActiveFilter - 1}
                </Badge>
              )
            }
          </div>
        )
      }
    </>
  );
});
