import React, { MouseEvent, useEffect } from 'react';
import { useId } from 'react-id-generator';
import { observer } from 'mobx-react';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { CottagesCard } from "./CottagesCard";
import { EmptyFavourites } from "../EmptyFavourites/EmptyFavourites";
import styles from './styles.module.css';

export const CottageListRender = () => {
  const {
    state: { cottages },
    loadFavoritesCottages,
    setCottages,
  } = rootProfileStore.favoritesStore;
  const { favoritesIds } = rootProfileStore.favoritesComposeAuth;
  const { cottageIds } = favoritesIds;

  const idList: string[] = useId(cottages.length, 'cottage');

  const toggleCottageFavorites = (id: number) => async (evt: MouseEvent) => {
    evt.preventDefault();
    const result = await rootProfileStore.favoritesComposeAuth.toggleCottage(id);
    if (result) {
      if (!favoritesIds.cottageIds.length) {
        setCottages([]);
        return;
      }
      void loadFavoritesCottages(favoritesIds.cottageIds);
    }
  };

  useEffect(() => {
    if (!cottageIds.length) {
      setCottages([]);
      return;
    }
    // Если есть какие-то ID коттеджей, загружаем их
    void loadFavoritesCottages(cottageIds);
  }, [cottageIds, loadFavoritesCottages, setCottages]);

  // useEffect(() => {
  //   if (!favoritesIds.apartmentIds.length) {
  //     setCottages([]);
  //     return;
  //   }
  //   void loadFavoritesCottages(favoritesIds.cottageIds);
  // }, []);

  return (
    <main
      className={styles.main}
    >
      {!cottages.length && (
        <EmptyFavourites
          title="В избранном пока нет коттеджей"
          description="Нажимайте на сердечко, чтобы объекты оказались на этой странице"
        />
      )}

      {cottages.length > 0 && (
        <ul className={styles.list}>
          {cottages.map((cottage, index) => (
            <li key={idList[index]} className={styles.listItem}>
              <CottagesCard
                apartment={cottage}
                toggleCottageFavorites={toggleCottageFavorites}
              />
            </li>
          ))}
        </ul>
      )}
    </main>
  );
};

export const CottageList = observer(CottageListRender);
