import React, { ComponentProps, useMemo } from 'react';
import { useModal } from 'react-modal-hook';
import { useParams } from "react-router-dom";
import ReactModal from 'react-modal';
import { useMediaQuery } from 'beautiful-react-hooks';
import { AllFiltersPopup } from '../AllFiltersPopup';
import { MobilePopup, Text } from '../../../atoms';
import { Icon } from '../../../atoms/Icon';
import { useTheme } from "../../../../context/theme/useTheme";
import arrowLeft from '../../../../assets/arrowLeftDark.svg';
import arrowLeftDark from '../../../../assets/lightArrowLeft.svg';
import { ContextValueVillageFilters } from 'context';
import {BuildingFiltersStore, complexFilterStore} from 'stores';
import { Params } from "../../../../services";
import styles from '../styles.module.scss';

export const useAllFiltersPopup = (
  openType: 'complex' | 'village' | "main",
  store?: BuildingFiltersStore['filters'] | {filters: ContextValueVillageFilters['villageDataFilter']},
  handleResetFilter?: ContextValueVillageFilters["handleResetVillageFilter"] | BuildingFiltersStore['filters']['reset'],
  handleChangeStore?: ContextValueVillageFilters["handleChangeVillageFilter"],
) => {
  const isLarge = useMediaQuery('(min-width: 1024px)');
  const { generalPlanId } = useParams<Pick<Params, 'generalPlanId'>>();
  const { complexId } = useParams<Pick<Params, 'complexId'>>();
  const isCottage = Boolean(generalPlanId);
  const {theme} = useTheme();

  const PopupWrapper = useMemo(() => {
    if (isLarge) {
      return (props: ComponentProps<typeof ReactModal>) => (
        <ReactModal
          {...props}
          isOpen
          shouldCloseOnEsc
          shouldCloseOnOverlayClick
          ariaHideApp={false}
          overlayClassName={styles.modalOverlay}
          className={styles.modal}
        />
      );
    }
    return (
      props: Pick<
        ComponentProps<typeof MobilePopup>,
        'children' | 'isOpen' | 'onClose'
      >,
    ) => (
      <MobilePopup
        IconBack={<Icon src={theme === 'dark-theme' ? arrowLeftDark : arrowLeft} />}
        headerChildren={
          <div className={styles.headerMobileFilters}>
            <Text align="center" size="L" weight="700">
              Фильтр
            </Text>
            <span className={styles.mobileFiltersReset} onClick={handleResetFilter || complexFilterStore.filters.reset}>
              Сбросить
            </span>
          </div>
        }
        {...props}
      />
    );
  }, [isLarge]);

  const [showAllFiltersPopup, hideAllFiltersPopup] = useModal(
    () => (
      <PopupWrapper
        isOpen           
        onClose={hideAllFiltersPopup}
        onRequestClose={hideAllFiltersPopup}
      >
        <AllFiltersPopup
          onClose={hideAllFiltersPopup}
          openType={openType}
          store={store}
          handleChangeStore={handleChangeStore}
          handleResetFilter={handleResetFilter}
          isCottage={isCottage}
          complexId={complexId}
        />
      </PopupWrapper>
    ),
    [PopupWrapper, store, handleChangeStore],
  );

  return {
    showAllFiltersPopup,
    hideAllFiltersPopup,
  };
};
