import React from 'react';
import {useTheme} from "../../context/theme/useTheme";

const ReservedIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
          fill="#2D2E31"/>
        <path
          d="M9.63604 9.63604L22.364 22.364M25 16C25 20.9706 20.9706 25 16 25C11.0294 25 7 20.9706 7 16C7 11.0294 11.0294 7 16 7C20.9706 7 25 11.0294 25 16Z"
          stroke="#585B68" strokeWidth="2" strokeLinecap="round"/>
      </svg>
    ) : (
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 8C0 3.58172 3.58172 0 8 0H24C28.4183 0 32 3.58172 32 8V24C32 28.4183 28.4183 32 24 32H8C3.58172 32 0 28.4183 0 24V8Z"
          fill="#F2F2F2"/>
        <path
          d="M9.63604 9.63604L22.364 22.364M25 16C25 20.9706 20.9706 25 16 25C11.0294 25 7 20.9706 7 16C7 11.0294 11.0294 7 16 7C20.9706 7 25 11.0294 25 16Z"
          stroke="#AFB4CB" strokeWidth="2" strokeLinecap="round"/>
      </svg>
    )
  );
};

export default ReservedIcon;
