import { FC, useState } from 'react';
import cc from "classcat";
import { SalesDepartment } from "../../../../../../services/v1/rc/viewVillage/types";
import {complexItemStore} from "../../../../../../stores/ComplexItemStore/store";
import {ContactModal} from "../../../GeneralPlanVillage/components/ContactModal/ContactModal";
import defaultUserImage from '../../../GeneralPlanVillage/assets/contacts/default-user-icon.svg';
import styles from './ContactCardApartment.module.scss';

interface Props {
  className?: string;
}

export const ContactCardApartment: FC<Props> = ({ className }) => {
  const {
    target: complexData,
  } = complexItemStore;

  const salesDepartments: SalesDepartment | undefined = complexData
  && complexData?.salesDepartments
  && complexData?.salesDepartments[0]
    ? complexData?.salesDepartments[0] : {};

  const contacts = complexData &&  complexData?.salesDepartments
  && complexData?.salesDepartments[0]
  && complexData?.salesDepartments[0]?.contacts
    ? complexData?.salesDepartments[0]?.contacts : [];

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <div className={`${styles.contactCard} ${className}`}>
      <div className={styles.companyInfoBlock}>
        {
          salesDepartments?.company?.logo?.url && (
            <div className={styles.logoWrapper}>
              <img
                src={salesDepartments?.company?.logo?.url}
                alt="Логотип компании"
                className={styles.logo}
              />
            </div>
          )
        }
        <div className={styles.companyInfo}>
          <div className={styles.companyName}>{salesDepartments?.company?.title}</div>
          <div className={styles.companyType}>Застройщик</div>
        </div>
      </div>

      <div className={styles.salesDepartment}>
        <div className={styles.departmentTitle}>{salesDepartments?.title}</div>
        <div className={styles.contactsBlock}>
          <div className={styles.contacts}>
            {contacts?.slice(0, 2).map((contact, index) => {
              return (
                contact?.logo?.url ? (
                  <img
                    key={contact.id}
                    src={contact?.logo?.url}
                    alt={contact?.fio ? contact?.fio : ''}
                    className={cc([styles.contactAvatar, index > 0 && styles.contactAvatarTwo])}
                  />
                ) : (
                  <img
                    key={contact?.id}
                    src={defaultUserImage}
                    alt={contact?.fio ? contact?.fio : ''}
                    className={cc([styles.contactAvatar, index > 0 && styles.contactAvatarTwo])}
                  />
                )
              )
            })}
            {contacts && contacts?.length > 2 && (
              <div className={styles.moreContacts}>+{contacts?.length - 2}</div>
            )}
          </div>
          <div className={styles.statusBlock}>
            <div className={styles.statusBlockTop}>
              {contacts?.slice(0, 2).map((contact, index) => (
                <span key={contact.id}>
                  {contact?.fio?.split(' ')[1]}
                  {index < contacts.slice(0, 2).length - 1 ? ', ' : ''}
                </span>
              ))}
            </div>
            <div className={styles.status}>
              {salesDepartments?.is_working ? (
                <>
                  <span className={styles.onlineIndicator}></span>
                  <span>Сейчас на связи</span>
                </>
              ) : (
                <span>Не работает</span>
              )}
            </div>
          </div>
        </div>
      </div>

      <button className={styles.button} onClick={openModal}>Показать контакты</button>

      <ContactModal
        isOpen={isModalOpen}
        onClose={closeModal}
        salesDepartments={salesDepartments}
      />
    </div>
  );
};

