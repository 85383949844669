import React, { MouseEvent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cc from 'classcat';
import { useId } from 'react-id-generator';
import { observer } from 'mobx-react';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { ResidentialComplexCard } from 'components/atoms';
import { appRoutesService } from 'services';
import { dataTestIdToProps } from 'utils/test/dataTestIdToProps';
import { EmptyFavourites } from "../EmptyFavourites/EmptyFavourites";
import styles from './styles.module.css';

export const ComplexesListTestIDs = {
  wrapper: 'ComplexesList_wrapper',
};

export const ComplexesListRenderer = () => {
  const {
    state: { complexes },
    loadFavoritesComplexes,
    setComplexes,
  } = rootProfileStore.favoritesStore;

  const { favoritesIds } = rootProfileStore.favoritesComposeAuth;

  const idList: string[] = useId(complexes.length, 'complexes');

  const complexesMod = complexes as any[];

  const toggleComplexFavorites = (id: number) => async (evt: MouseEvent) => {
    evt.preventDefault();
    // @todo: добавить поверку была ли ошибка при добавлении/удалении комплекса
    void (await rootProfileStore.favoritesComposeAuth.toggleComplex(id));
    if (!favoritesIds.complexIds.length) {
      setComplexes([]);
      return;
    }
    void loadFavoritesComplexes(favoritesIds.complexIds);
  };

  useEffect(() => {
    if (!favoritesIds.complexIds.length) {
      setComplexes([]);
      return;
    }
    void loadFavoritesComplexes(favoritesIds.complexIds);
  }, []);

  return (
    <main
      {...dataTestIdToProps(ComplexesListTestIDs.wrapper)}
      className={cc([
        styles.main,
        {
          [styles.noDataFavorites]: !complexes.length,
        },
      ])}
    >
      {!complexes.length && (
        <EmptyFavourites
          title="В избранном пока нет жилых комплексов"
          description="Нажимайте на сердечко, чтобы объекты оказались на этой странице"
        />
      )}

      {complexes.length > 0 && (
        <ul className={styles.list}>
          {complexesMod.map((building, index) => (
            <li key={idList[index]} className={styles.listItem}>
              <Link
                to={{
                  pathname: appRoutesService.replaceRoute('view', {
                    complexId: building.id.toString(),
                  }),
                }}
                className={styles.card}
              >
                <ResidentialComplexCard
                  buildingObject={building}
                  toggleComplexFavorites={toggleComplexFavorites}
                  imageClassName={styles.imageFavorites}
                />
              </Link>
            </li>
          ))}
        </ul>
      )}
    </main>
  );
};

export const ComplexesList = observer(ComplexesListRenderer);
