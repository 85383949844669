import React, { MouseEvent } from 'react';
import cc from 'classcat';
import { observer } from 'mobx-react';

import { Text } from 'components/atoms/Text';

import { ShortBuildingObject } from 'services';

import { rootProfileStore } from 'stores/user/RootProfileStore';
import { DictionaryBadge } from 'components/atoms/DictionaryBadge';
import styles from './styles.module.css';
import { indexOf } from "lodash";

type Props = {
  buildingObject: ShortBuildingObject;
  toggleComplexFavorites?: (id: number) => (evt: MouseEvent) => void;
  imageClassName?: string;
};

export const ResidentialVillageRender = ({
  buildingObject,
  toggleComplexFavorites,
  imageClassName,
}: Props) => {
  const maxApartmentsStatsItems = 3;
  const apartmentStatsOrder = ['rooms_studio', 'rooms_1', 'rooms_1e', 'rooms_2', 'rooms_2e', 'rooms_3', 'rooms_3e', 'rooms_4', 'rooms_4e', 'rooms_n'];

  const isVillage = buildingObject.type === 'village';

  const targetBadges = [...(buildingObject.badges || [])].sort((a, b) => a - b);
  const apartmentsStats = buildingObject.stats.apartments;
  const cottagesStats = buildingObject.stats.cottages;

  const getApartmentRoomsLabel = (key: string, isLastItem: boolean = false) => {
    let label = '';

    switch (key) {
      case 'rooms_studio':
        label = 'Студия';
        break;
      case 'rooms_1':
        label = '1 комната';
        break;
      case 'rooms_1e':
        label = '1E комната';
        break;
      case 'rooms_2':
        label = '2 комнаты';
        break;
      case 'rooms_2e':
        label = '2E комнаты';
        break;
      case 'rooms_3':
        label = '3 комнаты';
        break;
      case 'rooms_3e':
        label = '3E комнаты';
        break;
      case 'rooms_4':
        label = '4 комнаты';
        break;
      case 'rooms_4e':
        label = '4E комнаты';
        break;
      case 'rooms_n':
        label = 'Свободная';
        break;
      default:
        label = ``;
    }

    if (isLastItem) {
      return label.replace(' ', '+ ')
    }

    return label;
  };

  const getCottagesLabel = (key: string) => {
    switch (key) {
      case 'cottage':
        return 'Коттеджи';
      case 'duplex':
        return 'Дуплексы';
      case 'townhouse':
        return 'Таунхаусы';
      case 'triplex':
        return 'Триплексы';
      case 'quad_house':
        return '4х местные';
      default:
        return ``;
    }
  };

  const { includeVillage } = rootProfileStore.favoritesIdsStore;

  return (
    <>
      <div className={styles.imageWrapper}>
        <img
          className={cc([styles.images, imageClassName])}
          src={buildingObject.photo}
          loading="lazy"
          alt="object"
        />

        <div className={styles.informationOverlay}>
          {targetBadges && targetBadges.length > 0 && (
            <div className={styles.tagList}>
              <DictionaryBadge
                badgeInner
                dictItemId={targetBadges}
                dictKey="residential_complex_badges"
              />
            </div>
          )}

          <div className={styles.mainComplexInfoBlock}>
            {/*<div className={styles.mainComplexInfoOffers}>*/}
            {/*  <OffersStatusAndAddButton apartmentId={buildingObject?.id}/>*/}
            {/*</div>*/}
            <div className={styles.mainComplexInfoImg}>
              <img src={buildingObject?.logo} alt={'logo'} />
            </div>

            <div className={styles.mainComplexInfo}>
              <div className={styles.builder}>{buildingObject?.developer}</div>
              <div className={styles.name}>{buildingObject?.title}</div>
              <div className={styles.address}>{buildingObject?.address}</div>
            </div>

            <button
              type="button"
              onClick={
                toggleComplexFavorites &&
                toggleComplexFavorites(buildingObject.id)
              }
              className={styles.iconFavorites}
            >
              <svg
                className={cc({
                  [styles.initial]: !includeVillage(buildingObject.id),
                  [styles.red]: includeVillage(buildingObject.id),
                })}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >

                <path
                  d="M12.5197 19.7848L19.5544 12.3432C21.2829 10.5148 21.5379 7.50664 19.9022 5.58506C19.4925 5.10234 18.9941 4.713 18.4374 4.44077C17.8807 4.16855 17.2775 4.01917 16.6644 4.00173C16.0514 3.98429 15.4414 4.09916 14.8717 4.33934C14.302 4.57952 13.7845 4.93997 13.3508 5.39869L12.0286 6.79738L10.887 5.58975C9.15855 3.76128 6.31491 3.49152 4.49839 5.22187C4.04207 5.6553 3.67401 6.18253 3.41667 6.7714C3.15934 7.36027 3.01812 7.99843 3.00163 8.64694C2.98515 9.29545 3.09374 9.94072 3.32079 10.5434C3.54783 11.146 3.88857 11.6934 4.32222 12.1522L11.5375 19.7848C11.6678 19.9226 11.8444 20 12.0286 20C12.2128 20 12.3895 19.9226 12.5197 19.7848Z"
                  stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>
          </div>
        </div>
      </div>

      {!isVillage && !apartmentsStats && (
        <div className={styles.emptyInfoBlock}>
          <Text tagName="h3" className={styles.noFlats}>
            Нет квартир в продаже
          </Text>
        </div>
      )}

      {!isVillage &&
        apartmentsStats &&
        Object.keys(apartmentsStats).length > 0 && (
          <div className={styles.infoBlock}>
            {Object.keys(apartmentsStats)
              .sort((idA, idB) => indexOf(apartmentStatsOrder, idA) - indexOf(apartmentStatsOrder, idB))
              .filter((key, index) => index < maxApartmentsStatsItems)
              .map((key, index) => (
                <div className={styles.apartmentData} key={key}>
                      <span className={styles.apartmentDataType}>
                        {index+1 === maxApartmentsStatsItems && Object.keys(apartmentsStats).length > maxApartmentsStatsItems ? getApartmentRoomsLabel(key, true) : getApartmentRoomsLabel(key)}
                      </span>
                    <div className={styles.priceWrapper}>
                        <span className={styles.apartmentDataType}>
                          {apartmentsStats[key].count > 1 ? 'от ' : ''}
                        </span>
                      <span className={styles.apartmentDataPrice}>
                            {(apartmentsStats[key].sumMin / 1e6).toFixed(1)}
                          </span>
                      <span className={styles.apartmentDataType}>млн</span>
                    </div>
                  </div>)
              )}
          </div>
        )}

      {isVillage && cottagesStats && Object.keys(cottagesStats).length > 0 && (
        <div className={styles.infoBlock}>
          {Object.keys(cottagesStats)
            .sort()
            .map((key) => (
              <div className={styles.apartmentData} key={key}>
                <span className={styles.apartmentDataType}>
                  {getCottagesLabel(key)}
                </span>
                <div className={styles.priceWrapper}>
                  <span className={styles.apartmentDataType}>
                    {cottagesStats[key].count > 1 ? 'от ' : ''}
                  </span>
                  <span className={styles.apartmentDataPrice}>
                    {(cottagesStats[key].sumMin / 1e6).toFixed(1)}
                  </span>
                  <span className={styles.apartmentDataType}>млн</span>
                </div>
              </div>
            ))}
        </div>
      )}

      {isVillage && !cottagesStats && (
        <div className={styles.emptyInfoBlock}>
          <Text tagName="h3" className={styles.noFlats}>
            Нет коттеджей в продаже
          </Text>
        </div>
      )}
    </>
  );
};

export const ResidentialVillageCard = observer(ResidentialVillageRender);
