import { FC } from 'react';
import styles from './TooltipInfo.module.scss';

interface Props {
  className?: string;
  content: string;
  width?: string;
  position?: 'top' | 'bottom' | 'left' | 'right';
}

export const TooltipInfo: FC<Props> = ({ className, content, width, position = 'top' }) => {
  return (
    <div className={`${styles.tooltip} ${className} ${styles[position]}`} style={{ width }}>
      <div className={`${styles.triangle} ${styles[`triangle-${position}`]}`} />
      {content}
    </div>
  );
};