import React from 'react';

type Props = {
  isActive: boolean;
}

const PlanCottageMobileIcon = ({isActive}: Props) => {
  return (
    isActive ? (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
            <path
              d="M6.66667 3.33333V6.66667H6V3.33333H3.33333V8.66667H6V8H6.66667V11.3333H6V9.33333H3.33333V12.6667H8V11.3333H8.66667V12.6667H12.6667V11.3333H14V14H2V2H14V10H12.6667V6.66667H8.66667V10H8V6H12.6667V3.33333H6.66667Z"
              fill="#4E6AFF"/>
          </svg>
        )
      :
        (
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 16 16" fill="none">
            <path
              d="M6.66667 3.33333V6.66667H6V3.33333H3.33333V8.66667H6V8H6.66667V11.3333H6V9.33333H3.33333V12.6667H8V11.3333H8.66667V12.6667H12.6667V11.3333H14V14H2V2H14V10H12.6667V6.66667H8.66667V10H8V6H12.6667V3.33333H6.66667Z"
              fill="#333338"/>
          </svg>
        )
    )
  ;
};

export default PlanCottageMobileIcon;
