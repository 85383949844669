import React from 'react';
import { observer } from 'mobx-react';
import { infoStore, cityStore } from 'stores';
import { userProfileService } from "../../../../../../services";
import { rootProfileStore } from "stores/user/RootProfileStore";
import { useTheme } from "../../../../../../context/theme/useTheme";
import styles from './CityMobile.module.scss';
import closeIcon from "../../../../../../assets/closeIconLight.svg";
import closeIconDark from "../../../../../../assets/closeIconDark.svg";

type Props = {
  isOpen?: boolean;
  onClose: () => void;
};

const groupCitiesByRegion = (cities) => {
  return cities.reduce((acc, city) => {
    const regionKey = `${city.region} ${city.region_type}`;
    if (!acc[regionKey]) {
      acc[regionKey] = [];
    }
    acc[regionKey].push(city);
    return acc;
  }, {});
};

export const CityMobile = observer(({ onClose }: Props) => {
  const { theme } = useTheme();

  const handleCitySelect = async (cityId: number) => {
    const selectedCity = cityStore?.cities?.find(city => city.id === cityId);

    if (selectedCity) {
      try {
        const { authUser } = rootProfileStore.authStore;
        const user = authUser?.user;

        if (!user?.name || !user?.surname || !user?.role) {
          console.error("Ошибка: Не все обязательные поля заполнены.");
          return;
        }

        await userProfileService.saveUserProfile({
          name: user?.name,
          surname: user?.surname,
          role: user?.role,
          agencyId: user?.agency?.id,
          cityId: selectedCity?.id,
        });
        infoStore.addCity(selectedCity);
        await infoStore.loadInfo();
      } catch (error) {
        console.error("Ошибка обновления города:", error);
      }
    }
    onClose();
  };

  const groupedCities = groupCitiesByRegion(cityStore?.cities || []);

  return (
    <div className={styles.modalContent}>
      <div className={styles.modalContentHeader}>
        <h2>Выберите регион или город</h2>
        <button className={styles.closeBtn} onClick={onClose}>
          <img src={theme === 'dark-theme' ? closeIcon : closeIconDark} alt="close"/>
        </button>
      </div>
      <div className={styles.cityList}>
        {Object.entries(groupedCities).map(([region, cities], index) => (
          <div key={region} className={styles.regionBlock}>
            <h3 className={styles.regionTitle}>{region}</h3>
            <div className={styles.cityItems}>
              {cities.map(city => (
                <div key={city.id} className={styles.cityItem} onClick={() => handleCitySelect(city.id)}>
                  {city.name}
                </div>
              ))}
            </div>
            {/*{index < Object.keys(groupedCities).length - 1 && <hr className={styles.divider} />}*/}
          </div>
        ))}
      </div>
    </div>
  );
});