import React, { useCallback } from 'react';
import { Map as MapType } from 'leaflet';
import { MayBe } from 'types';
import { useMap } from 'react-leaflet';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { Icon } from 'components/atoms/Icon';
import { useTheme } from "../../../../context/theme/useTheme";
import zoomIn from 'assets/zoomIn.svg';
import zoomInLight from 'assets/zoomInLight.svg';
import zoomOut from 'assets/zoomOut.svg';
import zoomOutLight from 'assets/zoomOutLight.svg';
import styles from './styles.module.css';
import cc from "classcat";

type Props = {
  mapInstance?: MayBe<MapType>;
  className?: string;
};

export const LeafletZoom = ({ mapInstance, className = '' }: Props) => {
  const _mapInstance: MapType = useMap();
  const {theme} = useTheme();

  const onZoomIn = useCallback(
    (mapInstance?: MayBe<MapType>) => () => {
      if (mapInstance) {
        mapInstance.zoomIn();
      }
    },
    [],
  );

  const onZoomOut = useCallback(
    (mapInstance?: MayBe<MapType>) => () => {
      if (mapInstance) {
        mapInstance.zoomOut();
      }
    },
    [],
  );

  return (
    <div className={cc([styles.zoom, className])}>
      <ButtonBase
        className={styles.button}
        icon={<Icon alt="zoom in" src={theme === 'dark-theme' ? zoomInLight : zoomIn}/>}
        onClick={onZoomIn(mapInstance || _mapInstance)}
      />
      <div className={styles.divider}></div>
      {/* Разделительная линия */}
      <ButtonBase
        className={styles.button}
        icon={<Icon alt="zoom out" src={theme === 'dark-theme' ? zoomOutLight : zoomOut}/>}
        onClick={onZoomOut(mapInstance || _mapInstance)}
      />
    </div>
  );
};
