import React from 'react';
import { observer } from "mobx-react";
import { ButtonBase } from '../../../../../pages/LayoutPage/components/ButtonBase';
import { Icon } from '../../../../atoms/Icon';
import close from '../../../../../assets/close.svg';
import { villageStore } from "../../../../../stores";
import { complexItemStore } from "../../../../../stores/ComplexItemStore/store";
import { rootApartmentStore } from "../../../../../stores/Apartment/RootApartmentStore";
import styles from './styles.module.scss';

type Props = {
  onClose: () => void;
  onCleanFilter: () => void;
  isCottage?: boolean;
};

export const FilterBottomButtons = observer(({ onClose, onCleanFilter, isCottage }: Props) => {
  const { apartmentsStore } = rootApartmentStore;

  const {
    target: complexData,
  } = complexItemStore;

  const totalApartmentsStore = apartmentsStore?.apartments?.data?.length;
  const totalApartments = complexData?.stats?.total?.count || 0;

  const totalCottages = villageStore?.cottages?.length ?? '';

  return (
    <>
      <div className={styles.desktopButtons}>
        <ButtonBase
          onClick={onCleanFilter}
          icon={<Icon size={10} alt="close" src={close}/>}
          className={styles.desktopButtonReset}
        >
          Сбросить
        </ButtonBase>
        <ButtonBase onClick={onClose} color="primary" className={styles.desktopButtonShow}>
          Показать {isCottage ? totalCottages + ' объектов' : ''}
        </ButtonBase>
      </div>

      <div className={styles.mobileButtons}>
        <ButtonBase color="primary" onClick={onClose} fullWidth>
          Показать {isCottage ? totalCottages + ' объектов' : ''}
        </ButtonBase>
        {/*<ButtonBase fullWidth>Сохранить поиск</ButtonBase>*/}
        <ButtonBase onClick={onCleanFilter} fullWidth className={styles.desktopOnly}>
          Сбросить
        </ButtonBase>
      </div>
    </>
  )
});
