import React, { useState, ReactNode } from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';
import Dropdown from 'rc-dropdown';
import { TagButton } from './components/TagButton';
import { District } from 'services';
import { DropdownOverlay } from './components/DropdownOverlay';
import { FilterStore } from '../../../stores';
import { toJS } from 'mobx';
import styles from 'components/atoms/Select/SelectSingle.module.css';

type Props = {
  filterKey: any;
  placeholder?: string;
  disabled?: boolean;
  data: District[];
  store: FilterStore<any, any>;
  usePortal?: boolean;
};

export const DistrictsChoicerModal = observer(({
  placeholder = 'Выбрать',
  usePortal = true,
  filterKey,
  disabled,
  data,
  store,
}: Props) => {
  const [dropdownVisible, toggleDropdownVisibility] = useState(false);
  const [displayingValues, setDisplayingValues] = useState<{ title: string, id: number }[]>([]);

  const filterItem: number[] | null = store.filters[filterKey];

  const handleVisibilityChange = () => {
    if (!disabled) {
      toggleDropdownVisibility(!dropdownVisible);
    }
  };

  const handleDeleteTag = (id: number) => {
    const currentValuesIds = toJS(store.getField('districtIds'));
    store.setField('districtIds', currentValuesIds.filter((el: number) => el !== id));
    setDisplayingValues(prev => prev.filter(el => el.id !== id));
  }

  const clearFilter = () => {
    store.resetField('districtIds');
    setDisplayingValues([]);
  };

  const displayingValue: ReactNode =
    displayingValues.length > 0 && filterItem && filterItem.length > 0 ? (
      displayingValues.map(({title,id}) => (
        // <span className={styles.dispalyingValueWrapper} key={title}>
          <TagButton
            key={title}
            type="button"
            isSelected
            className="selectedButton"
            disabled={disabled}
            onDelete={(e) => { e.stopPropagation(); handleDeleteTag(id) }}>
            {title}
          </TagButton>
        // </span>
      ))
    ) : (
      <span className={styles.placeholder}>{placeholder}</span>
    );

  return (
    <Dropdown
      trigger={['click']}
      overlayClassName={styles.overlayWrapper}
      onVisibleChange={handleVisibilityChange}
      visible={dropdownVisible}
      overlay={
        <DropdownOverlay
          data={data}
          isOpen={dropdownVisible}
          filterItem={filterItem}
          onClose={handleVisibilityChange}
          clearFilter={clearFilter}
          setDisplayingValues={setDisplayingValues}
          displayingValues={displayingValues}
          store={store}
          filterKey={filterKey}
        />
      }
      animation="slide-up"
      getPopupContainer={
        usePortal
          ? undefined
          : (triggerNode) => triggerNode.parentNode as HTMLElement
      }
      // align={{ offset: [0, -1] }}
    >
      <div className={disabled ? styles.disabledContainer : ''}>
        <div
          className={cc([
            styles.root,
            styles.withTags,
            {
              [styles.disabled]: disabled,
              [styles.withTags]: filterItem,
              [styles.isOpen]: dropdownVisible,
            },
          ])}
        >
          {displayingValue}
        </div>
      </div>
    </Dropdown>
  );
});
