import { makeAutoObservable, runInAction } from 'mobx';
import { villageService } from '../../services/v1/villageService';
import {
  VillageDetails,
  Cottage,
  CottageDetails,
} from '../../services/v1/villageService/types';

class VillageStore {
  villageDetails: VillageDetails | null = null;
  cottages: Cottage[] = [];
  cottageDetails: CottageDetails | null = null;
  loadingVillage = false;
  loadingCottages = false;
  loadingCottageDetails = false;

  constructor() {
    makeAutoObservable(this);
  }

  isSectionEmpty(sectionId: number): boolean {
    const cottagesInSection = this.cottages.filter((cottage) => cottage.section_id === sectionId);
    return cottagesInSection.length === 0;
  }

  async fetchVillageDetails(villageId: string) {
    this.loadingVillage = true;
    try {
      const data = await villageService.getVillageDetails(villageId);
      runInAction(() => {
        this.villageDetails = data;
      });
    } catch (error) {
      console.error('Ошибка при загрузке данных поселка:', error);
    } finally {
      runInAction(() => {
        this.loadingVillage = false;
      });
    }
  }

  async fetchCottages(params: Record<string, any>) {
    this.loadingCottages = true;
    try {
      const { filtersForAPI, ...restParams } = params;

      // Первый запрос
      const firstResponse = await villageService.getCottages({ ...restParams, page: 1 }, filtersForAPI);
      const totalPages = firstResponse.totalPages;
      let allCottages = firstResponse.data;

      // Дозагрузка остальных страниц
      const requests = [];
      for (let page = 2; page <= totalPages; page++) {
        requests.push(villageService.getCottages({ ...restParams, page }, filtersForAPI));
      }

      // Параллельная загрузка
      const responses = await Promise.all(requests);
      responses.forEach(response => allCottages.push(...response.data));

      // Обновляем store
      runInAction(() => {
        this.cottages = allCottages;
      });
    } catch (error) {
      console.error('Ошибка при загрузке коттеджей:', error);
    } finally {
      runInAction(() => {
        this.loadingCottages = false;
      });
    }
  }


  async fetchCottageDetails(apartmentId: string) {
    this.loadingCottageDetails = true;
    try {
      const data = await villageService.getCottageDetails(apartmentId);
      runInAction(() => {
        this.cottageDetails = data;
      });
    } catch (error) {
      console.error('Ошибка при загрузке данных коттеджа:', error);
    } finally {
      runInAction(() => {
        this.loadingCottageDetails = false;
      });
    }
  }
}

export const villageStore = new VillageStore();
