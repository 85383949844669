import React, { FC, useEffect, useState, useRef } from 'react';
import SwiperCore, { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import styles from './BannersSlider.module.scss';
import { TelegramComponent } from '../TelegramComponent/TelegramComponent';
import { AppBanner } from '../AppBanner/AppBanner';
import { columnsStore } from '../../../../../../stores';
import ResizeObserver from 'resize-observer-polyfill';

SwiperCore.use([Autoplay, Pagination]);

export const BannersSlider: FC = () => {
  const [isClosed, setIsClosed] = useState(false);
  const { columns } = columnsStore;
  const swiperRef = useRef<any>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const delay = 8000; // 8 секунд

  useEffect(() => {
    const closed = localStorage.getItem('bannersClosed');
    if (closed === 'true') {
      setIsClosed(true);
    }
  }, []);

  useEffect(() => {
    if (swiperRef.current) {
      swiperRef.current.update();
      if (swiperRef.current.autoplay) {
        swiperRef.current.autoplay.start();
      }
    }
  }, [columns]);

  useEffect(() => {
    if (!containerRef.current) return;

    const resizeObserver = new ResizeObserver(() => {
      if (swiperRef.current) {
        swiperRef.current.update();
        if (swiperRef.current.autoplay) {
          swiperRef.current.autoplay.start();
        }
      }
    });

    resizeObserver.observe(containerRef.current);
    return () => resizeObserver.disconnect();
  }, []);

  const handleClose = () => {
    setIsClosed(true);
    localStorage.setItem('bannersClosed', 'true');
  };

  const handleBulletClick = (index: number) => {
    if (swiperRef.current) {
      swiperRef.current.slideToLoop(index); // Переключаем Swiper на нужный слайд
      setActiveIndex(index);
    }
  };

  if (isClosed) return null;

  return (
    <div className={styles.bannerSlider} ref={containerRef}>
      <Swiper
        ref={swiperRef}
        autoplay={{ delay, disableOnInteraction: false }}
        loop
        slidesPerView={1}
        spaceBetween={10}
        pagination={{ clickable: true }}
        onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
        observer
        observeParents
        observeSlideChildren
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
          if (swiper.autoplay) {
            swiper.autoplay.start();
          }
        }}
      >
        <SwiperSlide className={styles.fullWidthSlide}>
          <TelegramComponent setIsClosed={handleClose} />
        </SwiperSlide>
        <SwiperSlide className={styles.fullWidthSlide}>
          <AppBanner columns={columns} setIsClosed={handleClose} />
        </SwiperSlide>
      </Swiper>

      {/* Кастомные точки с индикатором прогресса */}
      <div className={styles.pagination}>
        {[...Array(2)].map((_, index) => (
          <span
            key={index}
            className={`${styles.bullet} ${index === activeIndex ? styles.activeBullet : ''}`}
            onClick={() => handleBulletClick(index)} // Теперь клик переводит на нужный слайд
          >
            {index === activeIndex && (
              <span className={styles.progress}></span>
            )}
          </span>
        ))}
      </div>
    </div>
  );
};
