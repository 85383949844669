import React, { useState } from 'react';
import { observer } from "mobx-react";
import {Loader, Text} from 'components/atoms';
import { Icon } from 'components/atoms/Icon';
import closeIcon from 'assets/closeIconLight.svg'
import closeIconDark from "assets/closeIconDark.svg";
import { ButtonBase } from '../../../LayoutPage/components/ButtonBase';
import { ApartmentDto, authTokenStorageService, Complex } from "../../../../services";
import { API_ROUTES } from "../../../../services/constants";
import styles from './PdfSettingsPopup.module.scss';
import { useTheme } from "../../../../context/theme/useTheme";

type PdfSettings = {
  objectName: boolean | undefined;
  developerName: boolean | undefined;
  objectAddress: boolean | undefined;
  layout: boolean | undefined;
  badges: boolean | undefined;
  description: boolean | undefined;
  advantagesComplex: boolean | undefined;
  advantagesCottage: boolean | undefined;
  photos: boolean | undefined;
  constructionProgress: boolean | undefined;
  locationAndView: boolean | undefined;
  price: boolean | undefined;
  agentContact: boolean | undefined;
};

type Props = {
  onClose: () => void;
  complexData: Complex;
  apartmentData: ApartmentDto | null;
};

export const PdfSettingsCottagePopup = observer(({ onClose, complexData, apartmentData }: Props) => {
  const token = authTokenStorageService?.getToken();
  const [isLoading, setIsLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState<string | null>(null);
  const {theme} = useTheme();

  const constructionAlbums = complexData?.albums?.filter(
    (album) => album.name === "Ход строительства"
  );

  const [settings, setSettings] = useState<PdfSettings>({
    objectName: Boolean(complexData?.title),
    developerName: Boolean(complexData?.developer),
    objectAddress: Boolean(complexData?.address),
    badges: Boolean(apartmentData?.catalogs?.apartment_status),
    description: Boolean(apartmentData?.catalogs),
    advantagesComplex: Boolean(complexData?.catalogs?.village_advantages),
    advantagesCottage: Boolean(apartmentData?.catalogs?.village_apartment_advantages),
    photos: Boolean(apartmentData?.presentation),
    constructionProgress: Boolean(constructionAlbums && constructionAlbums?.length > 0),
    locationAndView: Boolean(complexData?.polygon),
    price: Boolean(apartmentData?.price),
    layout: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.photo),
    agentContact: Boolean(token),
  });

  const toggleSetting = (key: keyof PdfSettings) => {
    setSettings((prev) => ({ ...prev, [key]: !prev[key] }));
  };

  const onReset = () => {
    setSettings({
      objectName: Boolean(complexData?.title),
      developerName: Boolean(complexData?.developer),
      objectAddress: Boolean(complexData?.address),
      badges: Boolean(apartmentData?.catalogs?.apartment_status),
      description: Boolean(apartmentData?.catalogs),
      advantagesComplex: Boolean(complexData?.catalogs?.village_advantages),
      advantagesCottage: Boolean(apartmentData?.catalogs?.village_apartment_advantages),
      photos: Boolean(apartmentData?.presentation),
      constructionProgress: Boolean(constructionAlbums && constructionAlbums?.length > 0),
      locationAndView: Boolean(complexData?.polygon),
      price: Boolean(apartmentData?.price),
      layout: Boolean(apartmentData?.layouts && apartmentData?.layouts?.[0]?.photo),
      agentContact: Boolean(token),
    });
    onClose();
  };

  const onCreatePdf = async () => {
    setIsLoading(true);
    setPdfUrl(null);

    const queryParams = new URLSearchParams();

    Object.entries(settings).forEach(([key, value]) => {
      const val = value ? 'true' : 'false';
      queryParams.append(key, val);
    });

    const apartmentId = apartmentData?.id;
    if (!apartmentId) {
      console.error('No apartmentId found');
      setIsLoading(false);
      return;
    }

    const url = `${process.env.REACT_APP_API_V1}${API_ROUTES.pdfCottage}/${apartmentId}/pdf?${queryParams.toString()}`;

    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: token ? { 'X-Auth-Token': `${token?.token}` } : {},
      });

      if (!response.ok) {
        console.error('Failed to fetch PDF:', response.statusText);
        setIsLoading(false);
        return;
      }

      const data = await response.json();
      const pdfUrlFromServer = data.url;
      setPdfUrl(pdfUrlFromServer);
    } catch (error) {
      console.error('Error fetching PDF:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const onOpenPdf = () => {
    if (pdfUrl) {
      window.open(pdfUrl, '_blank');
      onClose();
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.headerMobile}></div>
        <Text size="XL" weight="700" className={styles.title}>Настройка PDF</Text>
        <ButtonBase
          onClick={onClose}
          icon={<Icon src={theme === 'dark-theme' ? closeIcon : closeIconDark} className={styles.closeIcon}/>}
          className={styles.textClassName}
        />
      </div>
      <div className={styles.subHeader}>
        Отрегулируйте информацию видимую клиенту
      </div>

      <div className={styles.columns}>
        <div className={styles.column}>
          <label
            className={`${styles.switchLabel} ${!complexData?.title ? styles.disabled : ''}`}
          >
            <span>Название объекта</span>
            <input
              type="checkbox"
              checked={settings?.objectName}
              onChange={() => toggleSetting('objectName')}
              className={styles.switch}
              disabled={!complexData?.title}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!complexData?.developer ? styles.disabled : ''}`}
          >
            <span>Название застройщика</span>
            <input
              type="checkbox"
              checked={settings.developerName}
              onChange={() => toggleSetting('developerName')}
              className={styles.switch}
              disabled={!complexData?.developer}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!complexData?.address ? styles.disabled : ''}`}
          >
            <span>Адрес объекта</span>
            <input
              type="checkbox"
              checked={settings.objectAddress}
              onChange={() => toggleSetting('objectAddress')}
              className={styles.switch}
              disabled={!complexData?.address}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!apartmentData?.catalogs?.apartment_status ? styles.disabled : ''}`}
          >
            <span>Бэйджи</span>
            <input
              type="checkbox"
              checked={settings.badges}
              onChange={() => toggleSetting('badges')}
              className={styles.switch}
              disabled={!apartmentData?.catalogs?.apartment_status}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!complexData?.catalogs ? styles.disabled : ''}`}
          >
            <span>Описание</span>
            <input
              type="checkbox"
              checked={settings.description}
              onChange={() => toggleSetting('description')}
              className={styles.switch}
              disabled={!complexData?.catalogs}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!complexData?.catalogs?.village_advantages ? styles.disabled : ''}`}
          >
            <span>Преимущество поселка</span>
            <input
              type="checkbox"
              checked={settings.advantagesComplex}
              onChange={() => toggleSetting('advantagesComplex')}
              className={styles.switch}
              disabled={!complexData?.catalogs?.village_advantages}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!apartmentData?.catalogs?.village_apartment_advantages ? styles.disabled : ''}`}
          >
            <span>Преимущество объекта</span>
            <input
              type="checkbox"
              checked={settings.advantagesCottage}
              onChange={() => toggleSetting('advantagesCottage')}
              className={styles.switch}
              disabled={!apartmentData?.catalogs?.village_apartment_advantages}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!apartmentData?.presentation ? styles.disabled : ''}`}
          >
            <span>Фотографии</span>
            <input
              type="checkbox"
              checked={settings.photos}
              onChange={() => toggleSetting('photos')}
              className={styles.switch}
              disabled={!apartmentData?.presentation}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${(!constructionAlbums || constructionAlbums?.length === 0) ? styles.disabled : ''}`}
          >
            <span>Ход строительства</span>
            <input
              type="checkbox"
              checked={settings.constructionProgress}
              onChange={() => toggleSetting('constructionProgress')}
              className={styles.switch}
              disabled={!constructionAlbums || constructionAlbums?.length === 0}
            />
          </label>
        </div>
        <div className={styles.column}>
          <label
            className={`${styles.switchLabel} ${!(apartmentData?.layouts && apartmentData?.layouts[0]?.photo) ? styles.disabled : ''}`}
          >
            <span>Планировка</span>
            <input
              type="checkbox"
              checked={settings.layout}
              onChange={() => toggleSetting('layout')}
              className={styles.switch}
              disabled={!(apartmentData?.layouts && apartmentData?.layouts[0]?.photo)}
            />
          </label>
          {/*<label*/}
          {/*  className={`${styles.switchLabel} ${!(apartmentData?.layouts && apartmentData?.layouts[0]?.floor_photo) ? styles.disabled : ''}`}*/}
          {/*>*/}
          {/*  <span>План этажа</span>*/}
          {/*  <input*/}
          {/*    type="checkbox"*/}
          {/*    checked={settings.floorPlan}*/}
          {/*    onChange={() => toggleSetting('floorPlan')}*/}
          {/*    className={styles.switch}*/}
          {/*    disabled={!(apartmentData?.layouts && apartmentData?.layouts[0]?.floor_photo)}*/}
          {/*  />*/}
          {/*</label>*/}
          <label
            className={`${styles.switchLabel} ${!(complexData?.polygon) ? styles.disabled : ''}`}
          >
            <span>Расположение</span>
            <input
              type="checkbox"
              checked={settings.locationAndView}
              onChange={() => toggleSetting('locationAndView')}
              className={styles.switch}
              disabled={!complexData?.polygon}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!apartmentData?.price ? styles.disabled : ''}`}
          >
            <span>Стоимость объекта</span>
            <input
              type="checkbox"
              checked={settings.price}
              onChange={() => toggleSetting('price')}
              className={styles.switch}
              disabled={!apartmentData?.price}
            />
          </label>
          <label
            className={`${styles.switchLabel} ${!token ? styles.disabled : ''}`}
          >
            <span>Контакты агента</span>
            <input
              type="checkbox"
              checked={settings.agentContact}
              onChange={() => toggleSetting('agentContact')}
              className={styles.switch}
              disabled={!token}
            />
          </label>
        </div>
      </div>


      <div className={styles.actions}>
        <button onClick={onReset} className={styles.resetButton}>
          Отмена
        </button>

        {pdfUrl ? (
          <button onClick={onOpenPdf} className={styles.applyButton}>
            Открыть PDF файл
          </button>
        ) : (
          <button onClick={onCreatePdf} className={styles.applyButton} disabled={isLoading}>
            Создать PDF {isLoading && <Loader size="normal" color="primary" />}
          </button>
        )}
      </div>
    </div>
  );
});

