import React, { memo } from 'react';

import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { requestApiInstance } from 'stores/request/RequestApiStore';

import { ReactiveInput } from './ReactiveInput';

import { BuildingFiltersStore } from 'stores';
import { ReactiveButtonGroupSelect } from "../../../atoms/ReactiveButtonGroupSelect/ReactiveButtonGroupSelect";
import ReactiveCheckbox from "../villages/ReactiveCheckbox";
import { ReactiveSelect } from "../villages/ReactiveSelect";
import { DistrictsChoicerModal } from "../../DistrictsChoicerModal";
import { filter, flow, sortBy } from "lodash/fp";
import { District } from "../../../../services";
import styles from '../../AllFilters/AllFilters.module.scss';

type Props = {
  isShowFromComplex?: boolean,
  store?: BuildingFiltersStore['filters'],
}

export const MainComplexFilters = memo(({isShowFromComplex, store}:Props ) => {
  const { isLoading } = requestApiInstance;
  const { districts } = infoStore;

  const districtsNew = flow(
    filter(({ type, rc_count }) => type === 'район' && rc_count > 0),
    sortBy(({ title }: District) => title),
  )(districts);

  return (
    <>
      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Район</span>*/}
      {/*  <ReactiveSelect*/}
      {/*    store={complexFilterStore.filters}*/}
      {/*    filterKey="districtIds"*/}
      {/*    data={districts}*/}
      {/*    placeholder="Любой"*/}
      {/*    usePortal={false}*/}
      {/*    disabled={isLoading || isShowFromComplex}*/}
      {/*  />*/}
      {/*</div>*/}

      {
        districtsNew && districtsNew?.length > 0 && (
          <div className={styles.formSingleItem}>
            <span className={styles.formLabel}>Район</span>
            <DistrictsChoicerModal
              data={districts}
              filterKey="districtIds"
              store={complexFilterStore.filters}
              disabled={isLoading || isShowFromComplex}
              placeholder="Любой"
              usePortal={false}
            />
          </div>
        )
      }

      <span className={styles.formLabel}>Стоимость</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceMin"
          placeholder=""
          prefixText="от"
          suffixIcon="₽"
          isFormatValue={true}
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceMax"
          placeholder=""
          prefixText="до"
          suffixIcon="₽"
          isFormatValue={true}
          clearable={true}
        />
      </div>

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Тип дома</span>*/}
      {/*  <ReactiveSelect*/}
      {/*    store={complexFilterStore.filters}*/}
      {/*    filterKey="houseType"*/}
      {/*    dictKey="residential_complex_house_type"*/}
      {/*    placeholder="Не важно"*/}
      {/*    disabled={isShowFromComplex}*/}
      {/*  />*/}
      {/*</div>*/}

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Тип дома</span>*/}
      {/*  <ReactiveButtonGroupSelect*/}
      {/*    store={complexFilterStore.filters}*/}
      {/*    filterKey="houseType"*/}
      {/*    placeholder="Любой"*/}
      {/*    dictKey="residential_complex_house_type"*/}
      {/*    multiselect={true}*/}
      {/*  />*/}
      {/*</div>*/}

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип объекта</span>
        <ReactiveButtonGroupSelect
          store={complexFilterStore.filters}
          filterKey="complexType"
          placeholder="Любой"
          dictKey="residential_complex_type"
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип помещения</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="roomType"
          placeholder="Любой"
          dictKey="apartment_type_room"
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип квартиры</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="rooms"
          placeholder="Выберите тип квартиры"
          dictKey="rooms"
          usePortal={false}
          filterDictionaryItems={(dictionaryItems) => {
            const result = [...dictionaryItems];
            // 1. «Свободная» (rooms_n) в начало
            const freeIndex = result.findIndex((item) => item.key === 'rooms_n');
            if (freeIndex !== -1) {
              const [freeItem] = result.splice(freeIndex, 1);
              result.unshift(freeItem);
            }
            // 2. «5E комнат» (rooms_5e) перед «6 комнат» (rooms_6)
            const fiveEIndex = result.findIndex((item) => item.key === 'rooms_5e');
            const sixIndex = result.findIndex((item) => item.key === 'rooms_6');
            if (fiveEIndex !== -1 && sixIndex !== -1 && fiveEIndex > sixIndex) {
              const [fiveEItem] = result.splice(fiveEIndex, 1);
              result.splice(sixIndex, 0, fiveEItem);
            }
            return result;
          }}
        />
      </div>

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Район</span>*/}
      {/*  <ReactiveSelect*/}
      {/*    store={complexFilterStore.filters}*/}
      {/*    filterKey="districtIds"*/}
      {/*    data={districts}*/}
      {/*    placeholder="Любой"*/}
      {/*    usePortal={false}*/}
      {/*    disabled={isLoading || isShowFromComplex}*/}
      {/*  />*/}
      {/*</div>*/}

      <span className={styles.formLabel}>Этаж</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="floorMin"
          prefixText="Не ниже"
          placeholder=''
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="floorMax"
          prefixText="Не выше"
          placeholder=''
          clearable={true}
        />
      </div>

      <div className={styles.floorsCheckboxes}>
        <ReactiveCheckbox
          store={store || complexFilterStore.filters}
          filterKey="notFirstFloor"
          placeholder="Не первый"
          className={styles.floorsCheckbox}
        />

        <ReactiveCheckbox
          store={store || complexFilterStore.filters}
          filterKey="notLastFloor"
          placeholder="Не последний"
          className={styles.floorsCheckbox}
        />

        <ReactiveCheckbox
          store={store || complexFilterStore.filters}
          filterKey="isLastFloor"
          placeholder="Только последний"
          className={styles.floorsCheckbox}
        />

        <ReactiveCheckbox
          store={store || complexFilterStore.filters}
          filterKey="isPenthouse"
          placeholder="Пентхаус"
          className={styles.floorsCheckbox}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Статус продаж</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="salesStatus"
          dictKey="residential_complex_sales_status"
          placeholder="Любой"
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип договора</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="contractType"
          dictKey="residential_complex_contract_type"
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Способ оплаты</span>
        <ReactiveButtonGroupSelect
          store={complexFilterStore.filters}
          filterKey="paymentType"
          dictKey="residential_complex_payment_type"
          multiselect={true}
        />
      </div>

      <span className={styles.formLabel}>Год постройки</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="constructionYearMin"
          prefixText="от"
          placeholder=''
          minValue={2000}
          maxValue={2100}
          className={styles.complexInputLeft}
          disabled={isShowFromComplex}
          clearable={true}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="constructionYearMax"
          prefixText="до"
          placeholder=''
          minValue={2000}
          maxValue={2100}
          className={styles.complexInputRight}
          disabled={isShowFromComplex}
          clearable={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Класс дома</span>
        <ReactiveButtonGroupSelect
          store={complexFilterStore.filters}
          filterKey="complexClass"
          placeholder="Любой"
          dictKey="residential_complex_class"
          disabled={isShowFromComplex}
          multiselect={true}
        />
      </div>

      <span className={styles.formLabel}>Стоимость (м²)</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceM2Min"
          placeholder=""
          prefixText="от"
          suffixIcon="₽"
          isFormatValue={true}
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceM2Max"
          placeholder=""
          prefixText="до"
          suffixIcon="₽"
          isFormatValue={true}
          clearable={true}
        />
      </div>

      <span className={styles.formLabel}>Общая площадь (м²)</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareMin"
          placeholder=""
          prefixText="от"
          suffixIcon="м²"
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareMax"
          placeholder=""
          prefixText="до"
          suffixIcon="м²"
          clearable={true}
        />
      </div>

      <span className={styles.formLabel}>Площадь кухни (м²)</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareKitchenMin"
          placeholder=""
          prefixText="от"
          suffixIcon="м²"
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareKitchenMax"
          placeholder=""
          prefixText="до"
          suffixIcon="м²"
          clearable={true}
        />
      </div>

      <span className={styles.formLabel}>Жилая площадь (м²)</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareLivingMin"
          placeholder=""
          prefixText="от"
          suffixIcon="м²"
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="squareLivingMax"
          placeholder=""
          prefixText="до"
          suffixIcon="м²"
          clearable={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Преимущества</span>
        <ReactiveButtonGroupSelect
          store={complexFilterStore.filters}
          filterKey="advantages"
          placeholder="Любой"
          dictKey="residential_complex_advantages"
          disabled={isShowFromComplex}
          multiselect={true}
        />
      </div>

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Преимущества</span>*/}
      {/*  <ReactiveSelect*/}
      {/*    store={complexFilterStore.filters}*/}
      {/*    filterKey="advantages"*/}
      {/*    placeholder="Выберите преимущества"*/}
      {/*    dictKey="residential_complex_advantages"*/}
      {/*    usePortal={false}*/}
      {/*  />*/}
      {/*</div>*/}

      <span className={styles.formLabel}>Лифт</span>
      <div className={styles.floorsCheckboxes}>
        {/*<ReactiveCheckbox*/}
        {/*  store={store || complexFilterStore.filters}*/}
        {/*  filterKey="hasPassengerElevator"*/}
        {/*  placeholder="Есть"*/}
        {/*  className={styles.floorsCheckbox}*/}
        {/*/>*/}
        {/*<ReactiveCheckbox*/}
        {/*  store={store || complexFilterStore.filters}*/}
        {/*  filterKey="hasNoElevatorPassenger"*/}
        {/*  placeholder="Нет"*/}
        {/*  className={styles.floorsCheckbox}*/}
        {/*/>*/}
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="hasElevator"
          buttons={[
            {label: "Нет", value: '0', id: 1},
            {label: "Есть", value: '1', id: 2},
            {label: "Грузовой", value: '2', id: 3},
          ]}
          multiselect={false}
        />
        {/*<ReactiveCheckbox*/}
        {/*  store={store || complexFilterStore.filters}*/}
        {/*  filterKey="hasFreightElevator"*/}
        {/*  placeholder="Грузовой"*/}
        {/*  className={styles.floorsCheckbox}*/}
        {/*/>*/}
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Вид</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="windowType"
          dictKey="apartment_window_type"
          multiselect={true}
        />
      </div>

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Потолки</span>*/}
      {/*  <ReactiveButtonGroupSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    filterKey="ceilingHeightMin"*/}
      {/*    placeholder=""*/}
      {/*    buttons={[*/}
      {/*      {label: 'от 2.5 м', value: 'от 2.5 м', id: 1},*/}
      {/*      {label: 'от 2.7 м', value: 'от 2.7 м', id: 2},*/}
      {/*      {label: 'от 3 м', value: 'от 3 м', id: 3},*/}
      {/*      {label: 'от 4 м', value: 'от 4 м', id: 4},*/}
      {/*    ]}*/}
      {/*    multiselect={true}*/}
      {/*  />*/}
      {/*</div>*/}

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Материал здания</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="material"
          dictKey="residential_complex_material"
          disabled={isShowFromComplex}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Отделка</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="finishing"
          dictKey="residential_complex_finishing"
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Дата обновления ЖК</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="publishPeriod"
          placeholder="Любой"
          // values={{day: '24 часа', week: 'За неделю', month: 'За месяц'}}
          buttons={[
            {label: '24 часа', value: 'day', id: 1},
            {label: 'За неделю', value: 'week', id: 2},
            {label: 'За месяц', value: 'month', id: 3},
          ]}
          multiselect={false}
        />
      </div>
    </>
  );
});
