import { pull } from 'lodash';
import { flow } from 'lodash/fp';
import { action, computed, makeObservable, observable } from 'mobx';
import { FAVORITES_IDS_STORAGE, favoritesIdsStorage } from 'services';
import { favoritesService } from 'services/v1/rc/favorites';
import { ID } from '../../../types';
import { SyncTrunk } from '../../SyncTrunk/SyncTrunk';
import { favoritesIdsStorageAdapter } from './lib/adapters/favoritesIdsStorageAdapter';

const toggleIdInList = (list: ID[]) => (id: ID) =>
  list.includes(id) ? pull(list, id) : [...list, id];

export type FavoritesIdsStoreState = {
  complexIds: ID[];
  apartmentIds: ID[];
  villagesIds: ID[];
  cottageIds: ID[];
};

/**
 * @desc хранит только ID добавленных в избранное квартир и ЖК
 * */
export class FavoritesIdsStore {
  state: FavoritesIdsStoreState = {
    complexIds: [],
    apartmentIds: [],
    cottageIds: [],
    villagesIds: [],
  };

  constructor() {
    makeObservable(this, {
      state: observable,

      clear: action,

      toggleComplex: action,
      toggleApartment: action,
      setComplexIds: action,
      setApartmentIds: action,

      toggleCottage: action,
      toggleVillages: action,
      setCottageIds: action,
      setVillagesIds: action,

      complexIds: computed,
      apartmentIds: computed,
      cottageIds: computed,
      villagesIds: computed,
    });
  }

  get complexIds() {
    return this.state.complexIds;
  }

  get apartmentIds() {
    return this.state.apartmentIds;
  }

  get villagesIds() {
    return this.state.villagesIds;
  }

  get cottageIds() {
    return this.state.cottageIds;
  }

  clearState = () => {
    this.state = {
      complexIds: [],
      apartmentIds: [],
      villagesIds: [],
      cottageIds: [],
    };
  };

  clearStorage = () => favoritesIdsStorage.remove();

  /**
   * @desc полный сброс стора и хранилища
   * */
  clear = flow(this.clearState, this.clearStorage);

  includeComplex = (id: ID) => this.state.complexIds.includes(id);
  includeApartment = (id: ID) => this.state.apartmentIds.includes(id);

  includeCottage = (id: ID) => this.state.cottageIds.includes(id);
  includeVillage = (id: ID) => this.state.villagesIds.includes(id);

  /**
   * @desc добавляет/удаляет ID в списке ЖК
   * */
  toggleComplex = (id: ID) => {
    this.state.complexIds = toggleIdInList(this.state.complexIds)(id);
  };

  /**
   * @desc добавляет/удаляет ID в списке квартир
   * */
  toggleApartment = (id: ID) => {
    this.state.apartmentIds = toggleIdInList(this.state.apartmentIds)(id);
  };

  // Новые методы (коттеджные поселки, коттеджи)

  toggleCottage = (id: ID) => {
    this.state.cottageIds = toggleIdInList(this.state.cottageIds)(id);
  };

  toggleVillages = (id: ID) => {
    this.state.villagesIds = toggleIdInList(this.state.villagesIds)(id);
  };

  /**
   * @desc перезаписывает список id ЖК
   * */
  setComplexIds = (complexIds?: ID[]) => {
    this.state.complexIds = complexIds || [];
  };

  /**
   * @desc перезаписывает список id квартир
   * */
  setApartmentIds = (apartmentIds?: ID[]) => {
    this.state.apartmentIds = apartmentIds || [];
  };

  // Новые сеттеры
  setVillagesIds = (villagesIds?: ID[]) => {
    this.state.villagesIds = villagesIds || [];
  };

  setCottageIds = (cottageIds?: ID[]) => {
    this.state.cottageIds = cottageIds || [];
  };

  loadFavoritesIds = async () => {
    const [error, result] = await favoritesService.listIds();
    if (result) {
      this.state = {
        complexIds: result.complexIds ?? [],
        apartmentIds: result.apartmentIds ?? [],
        villagesIds: result.villagesIds ?? [],
        cottageIds: result.cottageIds ?? [],
      };
    }
    if (error) {
      throw new Error(error.toString());
    }
    return [error, result];
  };

  syncTrunk = () => {
    void new SyncTrunk({
      store: this,
      storage: favoritesIdsStorageAdapter,
      storageKey: FAVORITES_IDS_STORAGE,
      autoInit: true,
    });
  };
}
