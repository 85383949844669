import React, {MouseEventHandler, useCallback, useEffect, useState} from 'react';
import { observer } from 'mobx-react';
import { toJS } from "mobx";
import { DropdownButton, Button, ButtonGroup } from 'components/atoms';
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { useAllFiltersPopup } from 'components/containers/AllFiltersPopup';
import { SuggestByAddressComplexesAndDevelopers } from './components/SuggestByAddressComplexesAndDevelopers';
import { PriceFilter } from './components/PriceFilter/PriceFilter';
import { RoomsСottagesFilter } from './components/RoomsСottagesFilter';
import { FilterButtonWithFilterCount } from './components/FilterButtonWithFilterCount';
import { useSuggestOnChange } from './components/SuggestByAddressComplexesAndDevelopers/hooks/useSuggestOnChange';
import { suggestStore } from 'stores/SuggestStore/SuggestStore';
import { leafletStore } from 'stores/LeafletStore';
import { getCountActiveFilter } from "components/containers/Search/utils";
import { columnsStore } from "../../../../stores";
import useWindowSize from "../../../../hooks/useWindowSize";
import smallDropDown from 'assets/arrow-dropdown-small.svg';
import styles from './SmallFilters.module.css';
import cc from "classcat";

export const SearchAndFilterBarRender = () => {
  const [isPriceVisible, setIsPriceVisible] = useState(false);
  const { width = 0 } = useWindowSize();
  const handlePriceVisibility = useCallback(() => {
    setIsPriceVisible(!isPriceVisible);
  }, [isPriceVisible]);
  const closePriceVisibility: MouseEventHandler = (e) => {
    e.stopPropagation();
    setIsPriceVisible(false);
  };

  const { columns } = columnsStore;

    const { setSearchValue } = useSuggestOnChange({
    filterStore: complexFilterStore.filters,
    suggestStore,
  });


  const [isFlatsQuantityVisible, setIsFlatsQuantityVisible] = useState(false);
  const handleFlatsQuantityVisible = useCallback(() => {
    setIsFlatsQuantityVisible(!isFlatsQuantityVisible);
  }, [isFlatsQuantityVisible]);

  const { showAllFiltersPopup } = useAllFiltersPopup('main');
  const maxRoomsInViewport = 0;
  const roomButtonLabel = 'ЖК, КП';

  const firstRoomsItems =
    infoStore?.dictionaries?.rooms?.items &&
    infoStore.getItemsBeforeIndex(
      infoStore?.dictionaries?.rooms?.items,
      maxRoomsInViewport,
    );

  const resFilters = complexFilterStore.filters.filterToApiFormat;
  const [countActiveFilter, setCountActiveFilter] = useState(getCountActiveFilter(toJS(resFilters)));

  useEffect(() => {
    setCountActiveFilter(getCountActiveFilter(toJS(resFilters)));
  }, [complexFilterStore.filters.filterToApiFormat])
  
  return (
    <div className={styles.scrollBarWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.filters}>
          <div className={styles.searchWrapper}>
            <SuggestByAddressComplexesAndDevelopers />
          </div>
          <ButtonGroup>
            <>
              {firstRoomsItems?.map(({ id, value }) => (
                <Button
                  key={value}
                  variant={
                    complexFilterStore.filters
                      .getField('rooms')
                      ?.includes(String(id))
                      ? 'primary'
                      : 'secondary'
                  }
                  onClick={() => {
                    let rooms = complexFilterStore.filters.getField('rooms');
                    if (Array.isArray(rooms)) {
                      if (rooms.includes(String(id))) {
                        rooms = rooms.filter((item) => item !== String(id));
                      } else {
                        rooms.push(String(id));
                      }
                    } else {
                      rooms = [String(id)];
                    }
                    complexFilterStore.filters.setField('rooms', rooms);
                  }}
                >
                  {value}
                </Button>
              ))}
            </>
            <DropdownButton
              className={cc([styles.roomWrapper, isFlatsQuantityVisible && styles.open])}
              onVisibleChange={handleFlatsQuantityVisible}
              visible={isFlatsQuantityVisible}
              overlayContent={RoomsСottagesFilter({ maxRoomsInViewport })}
            >
              <span>
                {roomButtonLabel}
              </span>
              <img src={smallDropDown} alt=''/>
            </DropdownButton>
          </ButtonGroup>
          {
            !(columns === 3 && countActiveFilter > 0) && (
              <DropdownButton
                className={cc([styles.priceWrapper, isPriceVisible && styles.open])}
                onVisibleChange={handlePriceVisibility}
                visible={isPriceVisible}
                overlayContent={PriceFilter({closePriceVisibility})}
              >
                <span>
                  ₽
                </span>
                <img src={smallDropDown} alt=''/>
              </DropdownButton>
            )
          }
        </div>

        {/* // на главной странице */}
        <div className={styles.otherButtons}>
          <FilterButtonWithFilterCount
            onFilterReset={() => {
              complexFilterStore.filters.reset();
              setSearchValue('');
              leafletStore.setDisplayedPointsType('all');
              leafletStore.setSuppressMoveHandler(false)
            }}
            onFilterOpen={showAllFiltersPopup}
            resultedFilters={complexFilterStore.filters.filterToApiFormat}
          />
        </div>
      </div>
    </div>
  );
};

export const SearchAndFilterBar = observer(SearchAndFilterBarRender);
