import React, {FC} from 'react';
import { ApartmentDto } from "../../../../services";
import { DictionaryView } from "../../../../components/atoms";
import styles from './ApartmentInfo.module.scss';

interface ApartmentInfoProps {
  apartmentData: ApartmentDto;
}

export const ApartmentInfo: FC<ApartmentInfoProps> = ({ apartmentData }) => {
  // Дата подготовки презентации
  const presentationDate = new Date().toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
  });

  const totalArea = apartmentData?.square;
  const livingArea = apartmentData?.square_living;
  const kitchenArea = apartmentData?.square_kitchen;
  const bathroomArea = apartmentData?.square_bathroom;
  const villageTypeHouseValues = apartmentData?.catalogs?.village_type_house;
  const squareLand = apartmentData?.square_land;
  const numberFloors = apartmentData?.number_floors;
  const numberParking = apartmentData?.number_parking_spaces;



  return (
    <section className={styles.apartmentInfo}>
      <p className={styles.note}>Презентация подготовлена на {presentationDate}</p>
      <div className={styles.details}>
        <div className={styles.item}>
          <p className={styles.label}>Площадь дома</p>
          <p className={styles.value}>{totalArea ? `${totalArea} м²` : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Площадь жилая</p>
          <p className={styles.value}>{livingArea ? `${livingArea} м²` : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Площадь участка</p>
          <p className={styles.value}>{squareLand ? `${squareLand} сот.` : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Количество этажей</p>
          <div className={styles.value}>{numberFloors ? `${numberFloors}` : '-'}</div>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Площадь санузла</p>
          <p className={styles.value}>{bathroomArea ? `${bathroomArea} м²` : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Площадь кухни</p>
          <p className={styles.value}>{kitchenArea ? `${kitchenArea} м²` : '-'}</p>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Машиномест</p>
          <div className={styles.value}>{numberParking ? `${numberParking}` : '-'}</div>
        </div>
        <div className={styles.item}>
          <p className={styles.label}>Тип дома</p>
          <div className={styles.value}>{villageTypeHouseValues ? <DictionaryView dictKey="village_type_house" dictItemId={apartmentData?.catalogs?.village_type_house}/> : '-'}</div>
        </div>
      </div>
    </section>
  );
};
