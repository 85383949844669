import React from 'react';
import cc from 'classcat';
import { flow, get, head, isEqual } from 'lodash/fp';
import {
  apartmentUIPropertyVisibilityFilter,
  ApartmentsViewFilterValues,
} from 'stores/Apartment/ApartmentsViewFilters/ApartmentsViewFilters';
import { observer } from 'mobx-react';
import { ApartmentDto, DictionaryItem } from 'services';

import { UIPropertyVisibilityFilters } from 'stores';
import { buildingGridStore } from '../../../../stores/buildingGridStore';
import { GridCardInfo } from '../GridCardInfo';
import styles from './styles.module.css';

type Props = {
  apartment: ApartmentDto | undefined;
  isActive?: boolean;
  notPublished?: boolean;
  viewFilter?: UIPropertyVisibilityFilters<ApartmentsViewFilterValues>;
};

const hasInfoInApartmentCatalogs = (infoItem: DictionaryItem) =>
  flow(
    get(['catalogs', 'apartment_status']),
    head,
    isEqual(get('id', infoItem)),
  );

export const GridCardTestIDs: Record<keyof ApartmentsViewFilterValues, string> &
  Record<keyof Pick<ApartmentDto, 'number'>, string> = {
  apartment_type_room: `GridCard_apartment_type_room`,
  residential_complex_finishing: `GridCard_residential_complex_finishing`,
  rooms: `GridCard_rooms`,
  price_m2: `GridCard_price_m2`,
  square: `GridCard_square`,
  price: `GridCard_price`,
  layouts: `GridCard_layouts`,
  number: `GridCard_number`,
};

export const GridCardRender = ({
  isActive,
  apartment,
  viewFilter,
  notPublished,
}: Props) => {
  /** найти значения в словаре соответствующие статусам "Резерв", "Бронь" и "Продана" */
  const { reservationInfo, bookedInfo, soldInfo } =
    buildingGridStore.apartmentStatusDictionary;

  const viewFilterStatuses = {
    apartment_type_room: viewFilter?.isActive('apartment_type_room'),
    rooms: viewFilter?.isActive('rooms'),
    residential_complex_finishing: viewFilter?.isActive(
      'residential_complex_finishing',
    ),
    price_m2: viewFilter?.isActive('price_m2'),
    square: viewFilter?.isActive('square'),
    price: viewFilter?.isActive('price'),
    layouts: viewFilter?.isActive('layouts'),
  };

  if (!reservationInfo || !bookedInfo || !soldInfo) {
    return null;
  }

  const [isReservation, isBooked, isSold] = [
    hasInfoInApartmentCatalogs(reservationInfo)(apartment),
    hasInfoInApartmentCatalogs(bookedInfo)(apartment),
    hasInfoInApartmentCatalogs(soldInfo)(apartment),
  ];

  if (!apartment) {
    return null;
  }

  return (
    <div
      className={cc([
        styles.wrapper,
        {
          [styles.active]: isActive,
          [styles.sold]: isSold,
          [styles.booked]: isBooked || isReservation,
          [styles.notPublished]: notPublished,
        },
      ])}
    >
      <GridCardInfo
        apartment={apartment}
        viewFilterStatuses={viewFilterStatuses}
        isActive={isActive}
      />
    </div>
  );
};

GridCardRender.defaultProps = {
  viewFilter: apartmentUIPropertyVisibilityFilter,
};

export const GridCard = observer(GridCardRender);
