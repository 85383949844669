import React, {memo, useEffect, useState} from 'react';
import axios from "axios";
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { requestApiInstance } from 'stores/request/RequestApiStore';
import { ReactiveSelect } from './ReactiveSelect';
import { ReactiveInput } from './ReactiveInput';
import styles from '../../AllFilters/AllFilters.module.scss';
import { ContextValueVillageFilters } from 'context';
import {ReactiveButtonGroupSelect} from "../../../atoms/ReactiveButtonGroupSelect/ReactiveButtonGroupSelect";

type Props = {
  isShowFromVillage?: boolean,
  store?: { filters: ContextValueVillageFilters['villageDataFilter'] },
  handleChangeStore?:ContextValueVillageFilters["handleChangeVillageFilter"],
  complexId?: string
}

export const FirstColumnFilter = memo(({isShowFromVillage, store, handleChangeStore, complexId}: Props) => {
  const { districts } = infoStore;
  const { isLoading } = requestApiInstance;
  const [villageViewData, setVillageViewData] = useState<any>(null);

  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_V1}/v1/village/view/${complexId}`).then((res) => {
      setVillageViewData(res.data);
    });
  }, [complexId]);

  const cottageFilters = villageViewData?.cottageFilters;

  const typeHouseIds = cottageFilters?.village_type_house ?? [];
  const typeHouseBlock = typeHouseIds.length ? (
    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Тип дома</span>
      <ReactiveButtonGroupSelect
        store={store || complexFilterStore.filters}
        filterKey="villageTypeHouse"
        dictKey="village_type_house"
        multiselect
        onChangeVillageTypeHouseContext={handleChangeStore}
        filterDictionaryItems={(dictItems) => {
          return dictItems.filter((item) => typeHouseIds.includes(item.id));
        }}
      />
    </div>
  ) : null;

  // ==== Статус строительства (catalog_construction_phase) ====
  //
  const constructionPhaseIds = cottageFilters?.catalog_construction_phase ?? [];
  const constructionPhaseBlock = constructionPhaseIds.length ? (
    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Статус строительства</span>
      <ReactiveButtonGroupSelect
        store={store || complexFilterStore.filters}
        filterKey="constructionPhase"
        dictKey="construction_phase_status"
        multiselect={true}
        onChangeVillageTypeHouseContext={handleChangeStore}
        filterDictionaryItems={(dictItems) => {
          return dictItems.filter((item) => constructionPhaseIds.includes(item.id));
        }}
      />
    </div>
  ) : null;

  // ==== Преимущества (village_apartment_advantages) ====
  //
  const advantagesIds = cottageFilters?.village_apartment_advantages ?? [];
  const advantagesBlock = advantagesIds.length ? (
    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Преимущества</span>
      <ReactiveSelect
        store={store || complexFilterStore.filters}
        filterKey="villageAdvantages"
        dictKey="village_apartment_advantages"
        placeholder='Выберите значение'
        usePortal={false}
        onChangeVillageTypeHouseContext={handleChangeStore}
        filterDictionaryItems={(dictItems) => {
          return dictItems.filter((item) => advantagesIds.includes(item.id));
        }}
      />
    </div>
  ) : null;

  // ==== Материал здания (village_material) ====
  //
  const materialIds = cottageFilters?.village_material ?? [];
  const materialBlock = materialIds.length ? (
    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Материал здания</span>
      <ReactiveButtonGroupSelect
        store={store || complexFilterStore.filters}
        filterKey="villageMaterial"
        dictKey="village_material"
        multiselect
        onChangeVillageTypeHouseContext={handleChangeStore}
        filterDictionaryItems={(dictItems) => dictItems.filter((i) => materialIds.includes(i.id))}
      />
    </div>
  ) : null;

  // ==== Отделка (catalog_finishing) => dictKey='village_facade' ====
  //
  const finishingIds = cottageFilters?.village_facade ?? [];
  const finishingBlock = finishingIds.length ? (
    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Отделка</span>
      <ReactiveButtonGroupSelect
        store={store || complexFilterStore.filters}
        filterKey="villageFacade"
        dictKey="village_facade"
        multiselect
        onChangeVillageTypeHouseContext={handleChangeStore}
        filterDictionaryItems={(dictItems) => dictItems.filter((i) => finishingIds.includes(i.id))}
      />
    </div>
  ) : null;

  // ==== Гараж (village_garage) ====
  //
  const garageIds = cottageFilters?.village_garage ?? [];
  const garageBlock = garageIds.length ? (
    <div className={styles.formSingleItem}>
      <span className={styles.formLabel}>Гараж</span>
      <ReactiveSelect
        store={store || complexFilterStore.filters}
        filterKey="villageGarage"
        dictKey="village_garage"
        placeholder='Выберите значение'
        usePortal={false}
        onChangeVillageTypeHouseContext={handleChangeStore}
        filterDictionaryItems={(dictItems) => dictItems.filter((i) => garageIds.includes(i.id))}
      />
    </div>
  ) : null;

  // ==== Дворовая территория (village_yard_territory) ====
  //
  const yardIds = cottageFilters?.village_yard_territory ?? [];
  const yardBlock = yardIds.length ? (
    <div className={styles.formSubColumnWrapper}>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Дворовая территория</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="villageYardTerritory"
          dictKey="village_yard_territory"
          multiselect
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterDictionaryItems={(dictItems) => dictItems.filter((i) => yardIds.includes(i.id))}
        />
      </div>
    </div>
  ) : null;

  return (
    <>
      <span className={styles.formLabel}>Стоимость</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceMin"
          placeholder=""
          prefixText="от"
          suffixIcon="₽"
          clearable={true}
        />
        <ReactiveInput
          store={store || complexFilterStore.filters}
          filterKey="priceMax"
          placeholder=""
          prefixText="до"
          suffixIcon="₽"
          clearable={true}
        />
      </div>

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Тип дома</span>*/}
      {/*  <ReactiveButtonGroupSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    filterKey="villageTypeHouse"*/}
      {/*    dictKey="village_type_house"*/}
      {/*    onChangeVillageTypeHouseContext={handleChangeStore}*/}
      {/*    multiselect={true}*/}
      {/*    filterDictionaryItems={(dictItems) => {*/}
      {/*      const allowedIds = cottageFilters?.village_type_house ?? [];*/}
      {/*      return dictItems.filter((item) => allowedIds.includes(item.id));*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      {typeHouseBlock}

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Статус строительства</span>*/}
      {/*  <ReactiveButtonGroupSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    filterKey="constructionPhase"*/}
      {/*    dictKey="construction_phase_status"*/}
      {/*    onChangeVillageTypeHouseContext={handleChangeStore}*/}
      {/*    multiselect={true}*/}
      {/*    filterDictionaryItems={(dictItems) => {*/}
      {/*      const allowedIds = cottageFilters?.catalog_construction_phase ?? [];*/}
      {/*      return dictItems?.filter((item) => allowedIds?.includes(item.id));*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      {constructionPhaseBlock}

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Преимущества</span>*/}
      {/*  <ReactiveSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    onChangeVillageTypeHouseContext={handleChangeStore}*/}
      {/*    filterKey="villageAdvantages"*/}
      {/*    dictKey="residential_complex_advantages"*/}
      {/*    filterDictionaryItems={(dictItems) => {*/}
      {/*      const allowedIds = cottageFilters?.village_apartment_advantages ?? [];*/}
      {/*      return dictItems.filter((item) => allowedIds.includes(item.id));*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      {advantagesBlock}

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Этажность</span>
        <ReactiveButtonGroupSelect
          buttons={[
            {label: '1 этаж', value: '1', id: 1},
            {label: '2 этажа', value: '2', id: 2},
            {label: '3 этажа', value: '3+', id: 3},
          ]}
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageFloor"
          multiselect={true}
        />
      </div>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Количество спален</span>
        <ReactiveButtonGroupSelect
          buttons={[
            {label: "1", value: '1', id: 1},
            {label: "2", value: '2', id: 2},
            {label: "3", value: '3', id: 3},
            {label: "4+", value: '4+', id: 4}
          ]}
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageBedrooms"

        />
      </div>
      <div>
        <span className={styles.formLabel}>Площадь дома</span>
        <div className={styles.formInputs}>
          <ReactiveInput
            store={store || complexFilterStore.filters}
            filterKey="squareMin"
            placeholder=""
            prefixText="от"
            suffixIcon="м²"
            clearable={true}
          />
          <ReactiveInput
            store={store || complexFilterStore.filters}
            filterKey="squareMax"
            placeholder=""
            prefixText="до"
            suffixIcon="м²"
            clearable={true}
          />
        </div>
      </div>
      <div>
        <span className={styles.formLabel}>Площадь участка</span>
        <div className={styles.formInputs}>
          <ReactiveInput
            store={store || complexFilterStore.filters}
            filterKey="squareLandMin"
            placeholder=""
            prefixText="от"
            suffixIcon="Сотка"
            clearable={true}
          />
          <ReactiveInput
            store={store || complexFilterStore.filters}
            filterKey="squareLandMax"
            placeholder=""
            prefixText="до"
            suffixIcon="Сотка"
            clearable={true}
          />
        </div>
      </div>
      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Материал здания</span>*/}
      {/*  <ReactiveButtonGroupSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    onChangeVillageTypeHouseContext={handleChangeStore}*/}
      {/*    filterKey='villageMaterial'*/}
      {/*    dictKey='village_material'*/}
      {/*    multiselect={true}*/}
      {/*    filterDictionaryItems={(dictItems) => {*/}
      {/*      const allowedIds = villageViewData?.cottageFilters?.village_material ?? [];*/}
      {/*      return dictItems.filter((item) => allowedIds.includes(item.id));*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      {materialBlock}

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Отделка</span>*/}
      {/*  <ReactiveButtonGroupSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    onChangeVillageTypeHouseContext={handleChangeStore}*/}
      {/*    filterKey='villageFacade'*/}
      {/*    dictKey='village_facade'*/}
      {/*    multiselect={true}*/}
      {/*    filterDictionaryItems={(dictItems) => {*/}
      {/*      const allowedIds = cottageFilters?.catalog_finishing ?? [];*/}
      {/*      return dictItems.filter((item) => allowedIds.includes(item.id));*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      {finishingBlock}

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Гараж</span>*/}
      {/*  <ReactiveSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    onChangeVillageTypeHouseContext={handleChangeStore}*/}
      {/*    filterKey="villageGarage"*/}
      {/*    dictKey="village_garage"*/}
      {/*    filterDictionaryItems={(dictItems) => {*/}
      {/*      const allowedIds = cottageFilters?.village_garage ?? [];*/}
      {/*      return dictItems.filter((item) => allowedIds.includes(item.id));*/}
      {/*    }}*/}
      {/*  />*/}
      {/*</div>*/}

      {garageBlock}

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Количество машиномест</span>*/}
      {/*  <ReactiveButtonGroupSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    onChangeVillageTypeHouseContext={handleChangeStore}*/}
      {/*    filterKey="parkingSpaces"*/}
      {/*    multiselect={true}*/}
      {/*  />*/}
      {/*</div>*/}

      {/*<div className={styles.formSubColumnWrapper}>*/}
      {/*  <div className={styles.formSingleItem}>*/}
      {/*    <span className={styles.formLabel}>Дворовая территория</span>*/}
      {/*    <ReactiveButtonGroupSelect*/}
      {/*      store={store || complexFilterStore.filters}*/}
      {/*      onChangeVillageTypeHouseContext={handleChangeStore}*/}
      {/*      filterKey="villageYardTerritory"*/}
      {/*      dictKey="village_yard_territory"*/}
      {/*      multiselect={true}*/}
      {/*      filterDictionaryItems={(dictItems) => {*/}
      {/*        const allowedIds = cottageFilters?.village_yard_territory ?? [];*/}
      {/*        return dictItems.filter((item) => allowedIds.includes(item.id));*/}
      {/*      }}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}

      {yardBlock}

      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Район</span>*/}
      {/*  <PopupDistrictsChoicer*/}
      {/*    data={districts || []}*/}
      {/*    filterKey="districtIds"*/}
      {/*    store={complexFilterStore.filters}*/}
      {/*    disabled={isLoading || isShowFromVillage}*/}
      {/*    placeholder="Любой"*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={styles.formSubColumnWrapper}>*/}
      {/*  <div className={styles.formItem}>*/}
      {/*    <span className={styles.formLabel}>Тип участка</span>*/}
      {/*    <ReactiveSelect*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="villageTypeLand"*/}
      {/*      dictKey="village_type_land"*/}
      {/*      placeholder="Не важно"*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*  </div>*/}

      {/*  <div className={styles.formItem}>*/}
      {/*    <span className={styles.formLabel}>ВРИ</span>*/}
      {/*    <ReactiveSelect*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="villageVRI"*/}
      {/*      dictKey="village_vri"*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {/*<div className={styles.formInputs}>*/}
      {/*  <span className={styles.formLabel}>Расстояние до города</span>*/}
      {/*  <InputGroup>*/}
      {/*    <ReactiveInput*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="distanceToCityMin"*/}
      {/*      placeholder="От"*/}
      {/*      className={styles.complexInputLeft}*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*    <ReactiveInput*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="distanceToCityMax"*/}
      {/*      placeholder="До"*/}
      {/*      className={styles.complexInputRight}*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*  </InputGroup>*/}
      {/*</div>*/}
      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Дата обновления КП</span>*/}
      {/*  <ReactiveSelect*/}
      {/*    store={store || complexFilterStore.filters}*/}
      {/*    filterKey="publishPeriod"*/}
      {/*    placeholder="Любой"*/}
      {/*    onChangeVillageTypeHouseContext={handleChangeStore}*/}
      {/*    values={{day: '24 часа', week: 'За неделю', month: 'За месяц'}}*/}
      {/*    multiselect={false}*/}
      {/*  />*/}
      {/*</div>*/}
      {/*<div className={styles.formInputs}>*/}
      {/*  <span className={styles.formLabel}>Агентское вознаграждение, %</span>*/}
      {/*  <InputGroup>*/}
      {/*    <ReactiveInput*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="agentCommissionPercentMin"*/}
      {/*      placeholder="От"*/}
      {/*      className={styles.complexInputLeft}*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*    <ReactiveInput*/}
      {/*      store={complexFilterStore.filters}*/}
      {/*      filterKey="agentCommissionPercentMax"*/}
      {/*      placeholder="До"*/}
      {/*      className={styles.complexInputRight}*/}
      {/*      disabled={isShowFromVillage}*/}
      {/*    />*/}
      {/*  </InputGroup>*/}
      {/*</div>*/}
    </>
  );
});
