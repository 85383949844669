import 'intersection-observer';

import React from 'react';
import ReactDOM from 'react-dom';
import { numeralService } from 'services';
import 'simplebar/dist/simplebar.min.css';
import 'swiper/swiper-bundle.css';
import 'react-flexbox-grid/dist/react-flexbox-grid.css';
import 'globalStyles/swipe.css';
import 'globalStyles/reset.css';
import 'globalStyles/variables.css';
import './index.css';
import { App } from 'App';
import reportWebVitals from './reportWebVitals';
import ThemeProvider from "./context/theme/ThemeProvider";

numeralService();

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.getRegistrations().then(registrations => {
//     registrations.forEach(registration => registration.unregister());
//   });
//   console.log('❌ Service Worker отключен и удален.');
//
//   if ('caches' in window) {
//     caches.keys().then(cacheNames => {
//       cacheNames.forEach(cache => {
//         caches.delete(cache);
//       });
//     });
//     console.log('🗑 Все кеши удалены.');
//   }
// }

if ('serviceWorker' in navigator) {
  window.addEventListener('load', () => {
    navigator.serviceWorker.register('/service-worker.js')
      .then(() => console.log('✅ Service Worker установлен с офлайн-страницей'))
      .catch(() => console.log('❌ Ошибка регистрации Service Worker'));
  });
}


ReactDOM.render(
  <ThemeProvider>
    <App />
  </ThemeProvider>, document.getElementById('root'));

// measuring performance in app
// eslint-disable-next-line no-console
reportWebVitals(console.log);
