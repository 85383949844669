import React from 'react';
import styles from './ComplexDescription.module.scss';

interface ComplexDescriptionProps {
  complexData: any;
  objectNameQP?: boolean;
}

export const ComplexDescription: React.FC<ComplexDescriptionProps> = ({ complexData, objectNameQP }) => {
  if (!complexData) {
    return null;
  }

  const presentationImages = complexData?.presentation || [];
  const building1Img = presentationImages[0]?.url;
  const building2Img = presentationImages[1]?.url;
  const playgroundImg = presentationImages[2]?.url;
  const logoImg = complexData?.logo?.url;

  const description = complexData?.description || '';

  return (
    <section className={styles.complexDescription}>
      <h2 className={styles.sectionTitle}>Описание КП</h2>
      <div className={styles.container}>
        <div className={styles.images}>
          {
            building1Img && (
              <div className={styles.imageLeft}>
                <img src={building1Img} alt="Изображение 1"/>
              </div>
            )
          }
          <div className={styles.imageRight}>
            {
              building2Img && (
                <div>
                  <img src={building2Img} alt="Изображение 2" className={styles.imageTop}/>
                </div>
              )
            }
            <div className={`${styles.imageBottom} ${(!logoImg || !objectNameQP) ? styles.fullWidth : ''}`}>
              {
                playgroundImg && (
                  <div>
                    <img src={playgroundImg} alt="Изображение 3"/>
                  </div>
                )
              }
              {
                logoImg && objectNameQP && (
                  <div>
                    <img src={logoImg} alt="Логотип" className={styles.logo}/>
                  </div>
                )
              }
            </div>
          </div>
        </div>
        <div className={styles.text}>
          <div dangerouslySetInnerHTML={{ __html: description }} />
        </div>
      </div>
    </section>
  );
};
