import React from 'react';
import {useTheme} from "../../context/theme/useTheme";

const ArrowLeftIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.25 12H3.75M3.75 12L10.5 5.25M3.75 12L10.5 18.75" stroke="#E1E4EC" strokeWidth="2"
              strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    ) : (
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.25 12H3.75M3.75 12L10.5 5.25M3.75 12L10.5 18.75" stroke="#333338" strokeWidth="2"
              strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
    )
  );
};

export default ArrowLeftIcon;
