import React from 'react';
import { Text, NumberFormat, DictionaryView } from 'components/atoms';
import cc from 'classcat';
import { get } from 'lodash/fp';
import { observer } from 'mobx-react';
import { ApartmentDto } from 'services';
import typeHouseIcon from '../../assets/cottageInfo/type-house-icon.svg';
import squareCottageIcon from '../../assets/cottageInfo/square-cottage.svg';
import residentialComplexIcon from '../../assets/cottageInfo/residentiali-complex.svg';
import cottagePriceIcon from '../../assets/cottageInfo/cottage-price.svg';
import cottageFloor from '../../assets/cottageInfo/cottage-floor.svg';

import styles from './GridCottageInfo.module.scss';
import { CottagesViewFilterValues } from "./CottagesViewFilters";

type Props = {
  apartment: ApartmentDto | undefined;
  className?: string;
  viewFilterStatuses?: {
    village_type_house: boolean | undefined;
    layouts: boolean | undefined;
    rooms: boolean | undefined;
    residential_complex_finishing: boolean | undefined;
    price_m2: boolean | undefined;
    square: boolean | undefined;
    price: boolean | undefined;
    number_floors: boolean | undefined;
  };
};

export const GridCardTestIDs: Record<keyof CottagesViewFilterValues, string> &
  Record<keyof Pick<ApartmentDto, 'number'>, string> = {
  village_type_house: `GridCard_village_type_house`,
  residential_complex_finishing: `GridCard_residential_complex_finishing`,
  rooms: `GridCard_rooms`,
  price_m2: `GridCard_price_m2`,
  square: `GridCard_square`,
  price: `GridCard_price`,
  layouts: `GridCard_layouts`,
  number: `GridCard_number`,
  number_floors: `GridCard_number_floors`,
};

export const GridCottageInfoRender = ({
  apartment,
  viewFilterStatuses,
  className,
}: Props) => {
  if (!apartment) {
    return null;
  }

  return (
    <div className={cc([styles.wrapper, className])}>
      <header className={styles.infoBlock}>
        <div className={styles.infoBlockInner}>
          <img src={typeHouseIcon} alt={'type house'} />
          {viewFilterStatuses?.village_type_house && (
            <Text
              whiteSpace="nowrap"
              className={cc([styles.grayText, styles.marginRight10])}
              size="XXS"
              textTransform="uppercase"
              tagName="div"
            >
              <DictionaryView
                data-test-id={GridCardTestIDs?.village_type_house}
                dictKey="village_type_house"
                dictItemId={apartment?.catalogs?.village_type_house || []}
              />
            </Text>
          )}
        </div>
        <Text
          whiteSpace="nowrap"
          className={styles.marginRight10}
          data-test-id={GridCardTestIDs.number}
          lineHeight="L"
          tagName="div"
          size="S"
        >
          №{get('number', apartment)}
        </Text>
      </header>

      <section className={styles.body}>
        <div className={styles.infoBlock}>
          <img src={residentialComplexIcon} alt={'type house'}/>
          {viewFilterStatuses?.residential_complex_finishing && (
            <Text
              whiteSpace="nowrap"
              lineHeight="L"
              align="right"
              tagName="div"
              size="XS"
            >
              <DictionaryView
                data-test-id={GridCardTestIDs.residential_complex_finishing}
                dictKey="residential_complex_finishing"
                dictItemId={
                  apartment.catalogs?.residential_complex_finishing || []
                }
              />
            </Text>
          )}
        </div>

        <div className={styles.infoBlock}>
          <img src={squareCottageIcon} alt={'type house'}/>
          {viewFilterStatuses?.square && (
            <Text
              whiteSpace="nowrap"
              className={styles.marginRight10}
              tagName="div"
              size="XS"
            >
              <NumberFormat
                data-test-id={GridCardTestIDs.square}
                format="meter2"
                value={apartment?.square}
              />
            </Text>
          )}
        </div>

        <div className={styles.infoBlock}>
          <img src={cottageFloor} alt={'type house'}/>
          {viewFilterStatuses?.number_floors && (
            <Text
              whiteSpace="nowrap"
              className={styles.marginRight10}
              tagName="div"
              size="XS"
            >
              {get('number_floors', apartment)} этажа
            </Text>
          )}
        </div>

        <div className={styles.infoBlock}>
          <img src={cottagePriceIcon} alt={'type house'}/>
          <div className={styles.infoBlockPrice}>
            {viewFilterStatuses?.square && (
              <Text
                whiteSpace="nowrap"
                className={styles.infoBlockPriceMeter}
                tagName="div"
                size="XS"
              >
                <NumberFormat
                  data-test-id={GridCardTestIDs.square}
                  format="rub_meter2"
                  value={apartment?.price_m2}
                />
              </Text>
            )}
            {viewFilterStatuses?.price && (
              <Text whiteSpace="nowrap" tagName="div" size="S" className={styles.infoBlockPrice}>
                <NumberFormat
                  data-test-id={GridCardTestIDs.price}
                  format="rub"
                  value={apartment.price}
                />
              </Text>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

GridCottageInfoRender.defaultProps = {
  viewFilterStatuses: {
    village_type_house: true,
    layouts: true,
    rooms: true,
    residential_complex_finishing: true,
    price_m2: true,
    square: true,
    price: true,
    number_floors: true,
  },
};

export const GridCottageInfo = observer(GridCottageInfoRender);
