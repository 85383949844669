import { makeAutoObservable } from 'mobx';

class ColumnsStore {
  columns = 2;

  constructor() {
    makeAutoObservable(this);
  }

  setColumns(value: number) {
    this.columns = value;
  }
}

export const columnsStore = new ColumnsStore();
