import React from 'react';
import ReactModal from 'react-modal';
import { observer } from 'mobx-react';
import { CityList } from "../CityList/CityList";
import styles from './CitySelectionModal.module.scss';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const CitySelectionModal = observer(({ isOpen, onClose }: Props) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={styles.modal}
      overlayClassName={styles.overlay}
      ariaHideApp={false}
    >
      <CityList onClose={onClose} />
    </ReactModal>
  );
});
