import React from 'react';
import { RenderInputComponentProps } from 'react-autosuggest';
import search from '../../../assets/search.svg';
import close from '../../../assets/closeIconDark.svg';
import styles from './styles.module.css';

/**
 * Дополняем базовые пропсы, чтобы принять нашу функцию onClearClick
 */
type ExtendedInputProps = RenderInputComponentProps & {
  onClearClick?: () => void;
};

/**
 * @link https://github.com/moroshko/react-autosuggest#renderinputcomponent-optional
 */
export const renderInputComponent = (inputProps: ExtendedInputProps) => {
  // Распакуем наши дополнительные пропсы
  const { onClearClick, ...rest } = inputProps;

  // Проверяем, есть ли значение в поле
  const hasValue = !!rest.value && String(rest.value).length > 0;

  return (
    <div className={styles.inputWrapper}>
      {/* Иконка лупы слева */}
      <div className={styles.inputIcon}>
        <img src={search} alt="search" />
      </div>

      {/* Сам инпут, сюда передаются onChange, value, placeholder и т.д. */}
      <input {...rest} />

      {/* Крестик для очистки — показываем только если есть введённый текст */}
      {hasValue && (
        <div
          className={styles.inputCloseIcon}
          onClick={() => onClearClick && onClearClick()}
          role="button"
          tabIndex={0}
          onKeyPress={(e) => {
            // Для доступности по Enter/Space тоже вызываем очистку
            if (onClearClick && (e.key === 'Enter' || e.key === ' ')) {
              onClearClick();
            }
          }}
        >
          <img src={close} alt="close" />
        </div>
      )}
    </div>
  );
};

