import React from 'react';

type Props = {
  isActive: boolean;
}

const CottageVillagesIcon = ({isActive}: Props) => {
  return (
    isActive ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.3035 5.44695L8.0001 2.62829L14.6333 7.69361L14.4367 7.95161L13.6337 7.34935L12.8337 6.74935V7.74935V13.4993H3.16699V7.74935V6.73821L2.36347 7.35201L1.56433 7.96247L1.36383 7.69514L2.97121 6.46282L3.16699 6.31272V6.06602V4.49935H3.50033V5.04935V6.05937L4.3035 5.44695ZM3.50033 12.666V13.166H4.00033H7.33366H7.83366V12.666V10.4993H8.16699V12.666V13.166H8.66699H12.0003H12.5003V12.666V6.73268V6.48516L12.3035 6.33508L8.3035 3.28508L8.00033 3.05391L7.69715 3.28508L3.69715 6.33508L3.50033 6.48516V6.73268V12.666ZM3.24498 2.83268C3.31565 2.62454 3.43475 2.4387 3.60388 2.26957C3.89793 1.97551 4.24253 1.83268 4.66699 1.83268C4.98206 1.83268 5.26966 1.72045 5.49588 1.49424C5.59454 1.39558 5.67151 1.28524 5.7269 1.16602H6.08901C6.01833 1.37416 5.89923 1.56 5.73011 1.72913C5.43605 2.02318 5.09145 2.16602 4.66699 2.16602C4.3519 2.16602 4.06416 2.27828 3.83816 2.50508C3.73995 2.60362 3.66326 2.71375 3.60795 2.83268H3.24498Z"
          fill="#4E6AFF" stroke="#4E6AFF"/>
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.30301 5.44695L7.99961 2.62829L14.6328 7.69361L14.4362 7.95161L13.6332 7.34935L12.8332 6.74935V7.74935V13.4993H3.1665V7.74935V6.73821L2.36298 7.35201L1.56384 7.96247L1.36335 7.69514L2.97072 6.46282L3.1665 6.31272V6.06602V4.49935H3.49984V5.04935V6.05937L4.30301 5.44695ZM3.49984 12.666V13.166H3.99984H7.33317H7.83317V12.666V10.4993H8.1665V12.666V13.166H8.6665H11.9998H12.4998V12.666V6.73268V6.48516L12.303 6.33508L8.30301 3.28508L7.99984 3.05391L7.69667 3.28508L3.69667 6.33508L3.49984 6.48516V6.73268V12.666ZM3.24449 2.83268C3.31516 2.62454 3.43426 2.4387 3.60339 2.26957C3.89745 1.97551 4.24204 1.83268 4.6665 1.83268C4.98158 1.83268 5.26917 1.72045 5.49539 1.49424C5.59405 1.39558 5.67103 1.28524 5.72642 1.16602H6.08852C6.01784 1.37416 5.89874 1.56 5.72962 1.72913C5.43556 2.02318 5.09096 2.16602 4.6665 2.16602C4.35142 2.16602 4.06368 2.27828 3.83767 2.50508C3.73947 2.60362 3.66277 2.71375 3.60746 2.83268H3.24449Z"
          fill="#4E6AFF" stroke="#333338"/>
      </svg>
    )
  );
};

export default CottageVillagesIcon;
