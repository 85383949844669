import React, {ReactNode} from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type Props = {
  onClick?: () => void;
  label: string;
  helperText?: string;
  badgeText?: string;
  isActive?: boolean;
  tabIcon?: ReactNode;
  className?: string;
  [k: string]: any;
};

export const TabButton = ({
  label,
  helperText,
  onClick,
  badgeText,
  isActive,
  tabIcon,
  className = '',
  ...rest
}: Props) => (
  <button
    {...rest}
    type="button"
    className={cc([styles.button, isActive && styles.active, className])}
    onClick={onClick}
  >
    <div className={styles.labelContainer}>
      <div className={styles.label}>{tabIcon}{label}</div>
      {helperText && <div className={styles.helperText}>{helperText}</div>}
    </div>
    {badgeText && <div className={styles.badge}>{badgeText}</div>}
  </button>
);
