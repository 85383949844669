import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { flatten } from 'lodash/fp';
import { PolygonCoordinates } from 'types/PolygonCoordinates';

type Props = {
  polygons: PolygonCoordinates[][] | undefined;
  delay?: number;
  disableFitBounds?: boolean; // Новый флаг для блокировки fitBounds()
};

export const FitBoundsOfPolygons = ({ polygons, delay, disableFitBounds = false }: Props) => {
  const map = useMap();

  useEffect(() => {
    let timerId: NodeJS.Timeout | undefined;
    if (polygons?.length && !disableFitBounds) {
      // Отключаем fitBounds если disableFitBounds = true
      timerId = setTimeout(
        () => map.fitBounds(flatten(polygons)),
        delay || 250,
      );
    }

    return () => timerId && clearTimeout(timerId);
  }, [polygons, disableFitBounds]); // Добавляем зависимость disableFitBounds

  return null;
};
