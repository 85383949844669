import {FC, useState} from 'react';
import flatbyText from "assets/banners/flatbyText.svg";
import flatbyLogo from "assets/banners/flatbyIcon.svg";
import phoneImage from "assets/banners/phonesImg.png";
import downloadIcon from "assets/banners/downloadIcon.svg";
import appSetup1 from "assets/banners/appSetup1.png";
import appSetup2 from "assets/banners/appSetup2.png";
import appSetup3 from "assets/banners/appSetup3.png";
import appSetup4 from "assets/banners/appSetup4.png";
import appSetup5 from "assets/banners/appSetup5.png";
import appSetup6 from "assets/banners/appSetup6.png";
import appSetup7 from "assets/banners/appSetup7.png";
import appSetup8 from "assets/banners/appSetup8.png";
import appSetup9 from "assets/banners/appSetup9.png";
import appSetup10 from "assets/banners/appSetup10.png";
import rustoreBanner from "assets/banners/rustoreBanner.svg";
import rustoreIcon from "assets/banners/rustoreIcon.svg";
import styles from "./DownloadApp.module.scss";

export const DownloadApp: FC = () => {

  const [isInstructionsVisible, setInstructionsVisible] = useState(false);

  const toggleInstructions = () => {
    const newState = !isInstructionsVisible;
    setInstructionsVisible(newState);

    if (newState) {
      setTimeout(() => {
        const firstStep = document.getElementById('first-step');
        firstStep?.scrollIntoView({
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
  };

  return (
    <div className={styles.downloadPage}>
      <div className={styles.downloadPageImg}>
        <img src={flatbyText} alt='FLATBY'/>
      </div>
      <div className={styles.container}>
        <img src={flatbyLogo} alt="Flatby Logo" className={styles.logo}/>

        <div className={styles.content}>
          <div className={styles.imageWrapper}>
            <img src={phoneImage} alt="Flatby App Preview" className={styles.phoneImage}/>
          </div>

          <div className={styles.textBlock}>
            <h1 className={styles.title}>Flatby для Android</h1>

            <a
              href='https://www.rustore.ru/catalog/app/xyz.appmaker.jjmozw'
              target='_blank'
              className={styles.downloadLink}
            >
              <img src={rustoreBanner} alt='' className={styles.desktopLogo} />
              <img src={rustoreIcon} alt='' className={styles.mobileLogo} />
            </a>

            <p className={styles.description}>
              Здесь можно скачать клиент Flatby для Android если у Вас нет доступа к данным магазинам приложений. Эта версия приложения автоматически обновляется напрямую с сайта flatby.pro.
              {' '}
            </p>

            <a href="/files/app-release-signed.apk" className={styles.downloadButton} download>
              <img src={downloadIcon} alt='Download'/>
              Загрузить Flatby
            </a>
          </div>
        </div>

        <div className={styles.setupAppText}>
          <span
            className={styles.setupAppText1}
            onClick={toggleInstructions}
            aria-expanded={isInstructionsVisible}
          >
            Возникли сложности?
          </span>
          <span className={styles.setupAppText2}>Вот пошаговая инструкция</span>
        </div>

        {isInstructionsVisible && (
          <div className={styles.setupApp} id="first-step">
            <div className={styles.setupAppBlock}>
              <span className={styles.setupAppNum}>1</span>
              <img src={appSetup1} alt=''/>
            </div>
            <div className={styles.setupAppBlock}>
              <span className={styles.setupAppNum}>2</span>
              <img src={appSetup2} alt=''/>
            </div>
            <div className={styles.setupAppBlock}>
              <span className={styles.setupAppNum}>3</span>
              <img src={appSetup3} alt=''/>
            </div>
            <div className={styles.setupAppBlock}>
              <span className={styles.setupAppNum}>4</span>
              <img src={appSetup4} alt=''/>
            </div>
            <div className={styles.setupAppBlock}>
              <span className={styles.setupAppNum}>5</span>
              <img src={appSetup5} alt=''/>
            </div>
            <div className={styles.setupAppBlock}>
              <span className={styles.setupAppNum}>6</span>
              <img src={appSetup6} alt=''/>
            </div>
            <div className={styles.setupAppBlock}>
              <span className={styles.setupAppNum}>7</span>
              <img src={appSetup7} alt=''/>
            </div>
            <div className={styles.setupAppBlock}>
              <span className={styles.setupAppNum}>8</span>
              <img src={appSetup8} alt=''/>
            </div>
            <div className={styles.setupAppBlock}>
              <span className={styles.setupAppNum}>9</span>
              <img src={appSetup9} alt=''/>
            </div>
            <div className={styles.setupAppBlock}>
              <img src={appSetup10} alt=''/>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
