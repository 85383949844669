import React, {
  Dispatch,
  FC,
  SetStateAction,
  useEffect,
  useState,
} from 'react';
import { observer } from 'mobx-react';
import { useHistory, useParams } from 'react-router-dom';
import { complexFilterStore, villageStore} from '../../../../../../stores';
import { Section, Cottage } from '../../../../../../services/v1/villageService/types';
import {
  BuildingSearchParameters,
  appRoutesService
} from '../../../../../../services';
import { PlanToggle } from '../PlanToggle/PlanToggle';
import { TableRow } from '../TableRow/TableRow';
import { CottageView } from '../CottageView/CottageView';
import { NoResults } from '../NoResults/NoResults';
import { FiltersPopup } from "../FiltersPopup/FiltersPopup";

import iconUpDown from 'assets/iconUpDown.svg';
import iconUpActive from 'assets/iconUpActive.svg';
import iconDownActive from 'assets/iconDownActive.svg';
import iconArrowDown from 'assets/arrowDownGraySmall.svg';
import iconArrowDownActive from 'assets/arrowDownGraySmallActive.svg';
import iconSortSelect from 'assets/sortIconSelect.svg';
import iconSortSelectActive from 'assets/sortIconSelectActive.svg';

import styles from './GeneralPlanTable.module.scss';
import {Skeleton} from "../../../../../../components/atoms";

interface RouteParams {
  complexId: string;
  generalPlanId: string;
  viewType: string;
  sectionId: string;
  cottageId?: string;
  cottageNumber?: string;
}

interface GeneralPlanTableProps {
  villageId: string;
  sections: Section[];
  selectedSectionId: number | null;
  setSelectedSectionId: Dispatch<SetStateAction<number | null>>;
  filtersForAPI?: Partial<BuildingSearchParameters>;
}

export const GeneralPlanTable: FC<GeneralPlanTableProps> = observer(({
  villageId,
  sections,
  selectedSectionId,
  setSelectedSectionId,
  filtersForAPI,
}) => {
  const [cottages, setCottages] = useState<Cottage[]>([]);
  const [loadingCottages, setLoadingCottages] = useState<boolean>(false);

  // const [isMaterialFilterOpen, setIsMaterialFilterOpen] = useState(false);
  // const [isFacadeFilterOpen, setIsFacadeFilterOpen] = useState(false);
  // const [isTypeFilterOpen, setIsTypeFilterOpen] = useState(false);

  const [isApartmentViewOpen, setIsApartmentViewOpen] = useState<boolean>(false);
  const [selectedCottage, setSelectedCottage] = useState<Cottage | null>(null);

  const history = useHistory();
  const params = useParams<RouteParams>();

  const [sortField, setSortField] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<'asc' | 'desc' | 'none'>('none');

  const toggleSort = (field: string) => {
    if (field === sortField) {
      setSortDirection((prev) => {
        if (prev === 'none') return 'asc';
        if (prev === 'asc') return 'desc';
        return 'none';
      });
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  useEffect(() => {
    if (sections.length > 0 && selectedSectionId === null) {
      const sectionWithCottages = sections.find(
        (section) => section.stats.apartments > 0
      );
      if (sectionWithCottages) {
        setSelectedSectionId(sectionWithCottages.id);
      } else {
        // Если ни в одной секции нет коттеджей, берём первую
        setSelectedSectionId(sections[0].id);
      }
    }
  }, [sections, selectedSectionId, setSelectedSectionId]);

  useEffect(() => {
    if (selectedSectionId !== null) {
      setLoadingCottages(true);

      const realSort = sortDirection === 'none' ? '' : sortField;
      const realDirection = sortDirection === 'none' ? '' : sortDirection;

      const finalFilters: Partial<any> = {
        ...complexFilterStore.filters.filters,
        ...filtersForAPI,
      };

      villageStore
        .fetchCottages({
          complexIds: [villageId],
          sectionIds: [selectedSectionId],
          filtersForAPI: finalFilters,
          limit: 10000,
          withoutSold: 1,

          // поля сортировки:
          sort: realSort,
          sortDirection: realDirection,
        })
        .then(() => {
          setCottages(villageStore.cottages);
          setLoadingCottages(false);
        });
    }
  }, [
    selectedSectionId,
    villageId,
    filtersForAPI,
    // complexFilterStore.filters.filters.villageTypeHouse,
    // complexFilterStore.filters.filters.villageMaterial,
    // complexFilterStore.filters.filters.finishing,
    sortField,
    sortDirection
  ]);

  const sectionCounts: { [key: number]: number } = {};
  sections.forEach((section) => {
    sectionCounts[section.id] = section?.stats?.apartments_without_sold || 0;
  });

  useEffect(() => {
    if (params.cottageId && cottages.length > 0) {
      const found = cottages.find((c) => c.id === Number(params.cottageId));
      if (found) {
        setSelectedCottage(found);
        setIsApartmentViewOpen(true);
      }
    }
  }, [cottages, params.cottageId]);

  const onRowClick = (cottage: Cottage) => {
    const newParams = {
      ...params,
      cottageId: cottage.id.toString(),
      cottageNumber: cottage.number,
    };
    const newRoute = appRoutesService.replaceRoute(
      'generalPlanCottage',
      newParams
    );
    history.push(newRoute);

    setSelectedCottage(cottage);
    setIsApartmentViewOpen(true);
  };

  const closeApartmentView = () => {
    const { cottageId, cottageNumber, ...restParams } = params;
    const newRoute = appRoutesService.replaceRoute('generalPlan', restParams);
    history.push(newRoute);

    setSelectedCottage(null);
    setIsApartmentViewOpen(false);
  };

  const areFiltersApplied =
    filtersForAPI && Object.keys(filtersForAPI).length > 0;

  const showNoResults =
    !loadingCottages && areFiltersApplied && cottages.length === 0;

  if (showNoResults) {
    return <NoResults />;
  }

  const finalCottages = cottages;

  return (
    <div className={styles.generalPlanTable}>
      <div className={styles.topRow}>
        <PlanToggle
          sections={sections}
          selectedSectionId={selectedSectionId}
          onSelectSection={(sectionId) => {
            if (sectionId !== selectedSectionId) {
              setSelectedSectionId(sectionId);
              setCottages([]);
              setSelectedCottage(null);
              setIsApartmentViewOpen(false);
            }
          }}
          sectionCounts={sectionCounts}
        />

        <div
          className={styles.sortButton}
          onClick={() => toggleSort('price')}
          title="Сортировка по цене"
        >
          {sortField === 'price' ? (
            sortDirection === 'asc' ? (
              <img src={iconUpActive} alt="Сортировка возр." />
            ) : sortDirection === 'desc' ? (
              <img src={iconDownActive} alt="Сортировка убыв." />
            ) : (
              <img src={iconUpDown} alt="Нет сортировки" />
            )
          ) : (
            <img src={iconUpDown} alt="Нет сортировки" />
          )}
        </div>
      </div>

      <>
        <div className={styles.tableHeader}>
          <div
            className={styles.tableHeaderRow}
            onClick={() => toggleSort('apartmentStatus')}
          >
            <span>Статус</span>
            <div>
              {sortField === 'apartmentStatus' && sortDirection === 'desc' ? (
                <img src={iconArrowDownActive} alt='' style={{transform: 'rotate(180deg)'}}/>
              ) : (
                <img src={iconArrowDown} alt='' style={{transform: 'rotate(180deg)'}}/>
              )}
              {sortField === 'apartmentStatus' && sortDirection === 'asc' ? (
                <img src={iconArrowDownActive} alt=''/>
              ) : (
                <img src={iconArrowDown} alt=''/>
              )}
            </div>
          </div>

          <div>
            <span>План</span>
          </div>

          {/* 3. Номер */}
          <div
            className={styles.tableHeaderRow}
            onClick={() => toggleSort('number')}
          >
            <span>№</span>
            <div>
              {sortField === 'number' && sortDirection === 'desc' ? (
                <img src={iconArrowDownActive} alt='' style={{transform: 'rotate(180deg)'}}/>
              ) : (
                <img src={iconArrowDown} alt='' style={{transform: 'rotate(180deg)'}}/>
              )}
              {sortField === 'number' && sortDirection === 'asc' ? (
                <img src={iconArrowDownActive} alt=''/>
              ) : (
                <img src={iconArrowDown} alt=''/>
              )}
            </div>
          </div>

          <div
            className={styles.tableHeaderRow}
            onClick={() => toggleSort('numberFloors')}
          >
            <span>Этажи</span>
            <div>
              {sortField === 'numberFloors' && sortDirection === 'desc' ? (
                <img src={iconArrowDownActive} alt='' style={{transform: 'rotate(180deg)'}}/>
              ) : (
                <img src={iconArrowDown} alt='' style={{transform: 'rotate(180deg)'}}/>
              )}
              {sortField === 'numberFloors' && sortDirection === 'asc' ? (
                <img src={iconArrowDownActive} alt=''/>
              ) : (
                <img src={iconArrowDown} alt=''/>
              )}
            </div>
          </div>

          <div
            className={styles.tableHeaderRow}
            onClick={() => toggleSort('numberBedrooms')}
          >
            <span>Спальни</span>
            <div>
              {sortField === 'numberBedrooms' && sortDirection === 'desc' ? (
                <img src={iconArrowDownActive} alt='' style={{transform: 'rotate(180deg)'}}/>
              ) : (
                <img src={iconArrowDown} alt='' style={{transform: 'rotate(180deg)'}}/>
              )}
              {sortField === 'numberBedrooms' && sortDirection === 'asc' ? (
                <img src={iconArrowDownActive} alt=''/>
              ) : (
                <img src={iconArrowDown} alt=''/>
              )}
            </div>
          </div>

          <div
            className={styles.tableHeaderRow}
          >
            {/*<div*/}
            {/*  className={styles.sortSelectIcon}*/}
            {/*  onClick={(e) => {*/}
            {/*    e.stopPropagation();*/}
            {/*    setIsTypeFilterOpen((prev) => !prev);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <img*/}
            {/*    src={isTypeFilterOpen ? iconSortSelectActive : iconSortSelect}*/}
            {/*    alt=''*/}
            {/*  />*/}
            {/*</div>*/}
            <span onClick={() => toggleSort('villageTypeHouse')}>Тип</span>
            <div onClick={() => toggleSort('villageTypeHouse')}>
              {sortField === 'villageTypeHouse' && sortDirection === 'desc' ? (
                <img src={iconArrowDownActive} alt='' style={{transform: 'rotate(180deg)'}}/>
              ) : (
                <img src={iconArrowDown} alt='' style={{transform: 'rotate(180deg)'}}/>
              )}
              {sortField === 'villageTypeHouse' && sortDirection === 'asc' ? (
                <img src={iconArrowDownActive} alt=''/>
              ) : (
                <img src={iconArrowDown} alt=''/>
              )}
            </div>
            {/*{isTypeFilterOpen && (*/}
            {/*  <FiltersPopup*/}
            {/*    onClose={() => setIsTypeFilterOpen(false)}*/}
            {/*    filterKey="villageTypeHouse"*/}
            {/*    dictKey="village_type_house"*/}
            {/*  />*/}
            {/*)}*/}
          </div>

          <div
            className={styles.tableHeaderRow}
            onClick={() => toggleSort('squareLand')}
          >
            <span>Площадь участка</span>
            <div>
              {sortField === 'squareLand' && sortDirection === 'desc' ? (
                <img src={iconArrowDownActive} alt='' style={{transform: 'rotate(180deg)'}}/>
              ) : (
                <img src={iconArrowDown} alt='' style={{transform: 'rotate(180deg)'}}/>
              )}
              {sortField === 'squareLand' && sortDirection === 'asc' ? (
                <img src={iconArrowDownActive} alt=''/>
              ) : (
                <img src={iconArrowDown} alt=''/>
              )}
            </div>
          </div>

          <div
            className={styles.tableHeaderRow}
            onClick={() => toggleSort('square')}
          >
            <span>Площадь</span>
            <div>
              {sortField === 'square' && sortDirection === 'desc' ? (
                <img src={iconArrowDownActive} alt='' style={{transform: 'rotate(180deg)'}}/>
              ) : (
                <img src={iconArrowDown} alt='' style={{transform: 'rotate(180deg)'}}/>
              )}
              {sortField === 'square' && sortDirection === 'asc' ? (
                <img src={iconArrowDownActive} alt=''/>
              ) : (
                <img src={iconArrowDown} alt=''/>
              )}
            </div>
          </div>

          <div
            className={styles.tableHeaderRow}
            onClick={() => toggleSort('priceM2')}
          >
            <span>₽ / м²</span>
            <div>
              {sortField === 'priceM2' && sortDirection === 'desc' ? (
                <img src={iconArrowDownActive} alt='' style={{transform: 'rotate(180deg)'}}/>
              ) : (
                <img src={iconArrowDown} alt='' style={{transform: 'rotate(180deg)'}}/>
              )}
              {sortField === 'priceM2' && sortDirection === 'asc' ? (
                <img src={iconArrowDownActive} alt=''/>
              ) : (
                <img src={iconArrowDown} alt=''/>
              )}
            </div>
          </div>

          <div
            className={styles.tableHeaderRow}
            onClick={() => toggleSort('price')}
          >
            <span>Стоимость</span>
            <div>
              {sortField === 'price' && sortDirection === 'desc' ? (
                <img src={iconArrowDownActive} alt='' style={{transform: 'rotate(180deg)'}}/>
              ) : (
                <img src={iconArrowDown} alt='' style={{transform: 'rotate(180deg)'}}/>
              )}
              {sortField === 'price' && sortDirection === 'asc' ? (
                <img src={iconArrowDownActive} alt=''/>
              ) : (
                <img src={iconArrowDown} alt=''/>
              )}
            </div>
          </div>

          <div
            className={styles.tableHeaderRow}
          >
            {/*<div*/}
            {/*  className={styles.sortSelectIcon}*/}
            {/*  onClick={(e) => {*/}
            {/*    e.stopPropagation();*/}
            {/*    setIsMaterialFilterOpen((prev) => !prev);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <img*/}
            {/*    src={isMaterialFilterOpen ? iconSortSelectActive : iconSortSelect}*/}
            {/*    alt=''*/}
            {/*  />*/}
            {/*</div>*/}

            <span onClick={() => toggleSort('villageMaterial')}>Материал</span>

            <div onClick={() => toggleSort('villageMaterial')}>
              {sortField === 'villageMaterial' && sortDirection === 'desc' ? (
                <img src={iconArrowDownActive} alt='' style={{transform: 'rotate(180deg)'}}/>
              ) : (
                <img src={iconArrowDown} alt='' style={{transform: 'rotate(180deg)'}}/>
              )}
              {sortField === 'villageMaterial' && sortDirection === 'asc' ? (
                <img src={iconArrowDownActive} alt=''/>
              ) : (
                <img src={iconArrowDown} alt=''/>
              )}
            </div>

            {/*{isMaterialFilterOpen && (*/}
            {/*  <FiltersPopup*/}
            {/*    onClose={() => setIsMaterialFilterOpen(false)}*/}
            {/*    filterKey="villageMaterial"*/}
            {/*    dictKey="village_material"*/}
            {/*  />*/}
            {/*)}*/}
          </div>

          {/* 12. Отделка */}
          <div
            className={styles.tableHeaderRow}
          >
            {/*<div*/}
            {/*  className={styles.sortSelectIcon}*/}
            {/*  onClick={(e) => {*/}
            {/*    setIsFacadeFilterOpen((prev) => !prev);*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <img*/}
            {/*    src={isFacadeFilterOpen ? iconSortSelectActive : iconSortSelect}*/}
            {/*    alt=''*/}
            {/*  />*/}
            {/*</div>*/}
            <span onClick={() => toggleSort('apartmentFinishing')}>Отделка</span>
            <div onClick={() => toggleSort('apartmentFinishing')}>
              {sortField === 'apartmentFinishing' && sortDirection === 'desc' ? (
                <img src={iconArrowDownActive} alt='' style={{transform: 'rotate(180deg)'}}/>
              ) : (
                <img src={iconArrowDown} alt='' style={{transform: 'rotate(180deg)'}}/>
              )}
              {sortField === 'apartmentFinishing' && sortDirection === 'asc' ? (
                <img src={iconArrowDownActive} alt=''/>
              ) : (
                <img src={iconArrowDown} alt=''/>
              )}
            </div>
            {/*{isFacadeFilterOpen && (*/}
            {/*  <FiltersPopup*/}
            {/*    onClose={() => setIsFacadeFilterOpen(false)}*/}
            {/*    filterKey="finishing"*/}
            {/*    dictKey="residential_complex_finishing"*/}
            {/*  />*/}
            {/*)}*/}
          </div>
        </div>

        {finalCottages.length === 0 ? (
          <div style={{padding: '1rem'}}>Нет коттеджей</div>
        ) : (
          finalCottages.map((cottage) => {
            const isSelected = selectedCottage?.id === cottage.id;
            return (
              <TableRow
                key={cottage.id}
                data={cottage}
                onRowClick={onRowClick}
                isSelected={isSelected}
              />
            );
          })
        )}
      </>

      {/* Модалка/детальная карточка для выбранного коттеджа */}
      {selectedCottage && (
        <CottageView
          onClose={closeApartmentView}
          apartment={selectedCottage}
          isOpen={isApartmentViewOpen}
        />
      )}
    </div>
  );
});