import React, {ComponentProps, useState} from 'react';
import L from 'leaflet';
import {
  BaseMapContainer,
  Text,
  FloorPlanOverlay,
  OverlayZoomButton,
} from 'components/atoms';
import { ApartmentDto } from 'services/v1/rc/apartment/types';
import { MayBe } from 'types';
import { PolygonCoordinates } from 'types/PolygonCoordinates';
import { getAllApartmentPolygons, getAllFloorPlans } from 'services';
import styles from './styles.module.css';
import { LevelStepper } from '../LevelStepper';
import { useApartmentPlanOnMap } from '../../hooks/useApartmentPlanOnMap';
import { useShowApartmentOnFloorPopup } from '../../../../containers/ShowApartmentOnFloorPopup';
import { ApartmentsOnFloorPlan } from '../../../FloorPlans/containers/ApartmentsOnFloorPlan';
import smallDropDown from 'assets/arrow-dropdown-small.svg';
import cc from "classcat";

type Props = {
  apartment: ApartmentDto | undefined;
  className?: string;
  isPdf?: boolean;
} & Pick<ComponentProps<typeof BaseMapContainer>, 'hideZoomControl'>;

/**
 * @desc компонент для отрисовки плана квартиры поверх плана этажа,
 * если квартира много этажная появится специальный управляющий переключения между этажами элемент
 * */
export const ShowApartmentOnFloorLayout = ({
  apartment,
  hideZoomControl,
  className,
  isPdf = false,
}: Props) => {
  const floorPlans: MayBe<string>[] = getAllFloorPlans(apartment);
  const apartmentPolygons: (PolygonCoordinates[] | undefined)[] =
    getAllApartmentPolygons(apartment);

  const {
    currentPlan,
    currentPolygon,
    isShowLevelStepper,
    count,
    onDecrement,
    onIncrement,
    maxLevel,
  } = useApartmentPlanOnMap({
    plans: floorPlans,
    polygons: apartmentPolygons,
  });

  const { showShowApartmentOnFloorPopup } = useShowApartmentOnFloorPopup(
    apartment,
    count,
  );

  const [isExpanded, setIsExpanded] = useState(true);

  const toggleExpand = () => setIsExpanded(prev => !prev);

  return (
    <div className={cc([styles.wrapper, className])}>
      <div className={styles.toggleBlock}>
        <button className={styles.toggleButton} onClick={toggleExpand}>
          <img src={smallDropDown} alt='' className={isExpanded ? styles.dropDownExpanded : styles.dropDownNotExpanded}  />
          <span>План этажа</span>
        </button>

        {isShowLevelStepper && (
          <div className={styles.header}>
            <Text weight="bold">План этажа</Text>
            <LevelStepper
              count={count}
              onDecrement={onDecrement}
              onIncrement={onIncrement}
              minCount={1}
              maxCount={maxLevel}
            />
          </div>
        )}
      </div>

      <OverlayZoomButton
        className={cc([styles.mapWrapper, className, isExpanded && styles.mapWrapperExpanded])}
        onClick={showShowApartmentOnFloorPopup}
      >
        <BaseMapContainer
          crs={L.CRS.Simple}
          minZoom={isPdf ? -1 : -3}
          maxZoom={isPdf ? -1 : -2}
          maxBoundsViscosity={1}
          className={styles.map}
          hideZoomControl={hideZoomControl}
        >
          <FloorPlanOverlay fitBoundsOfPlan imageUrl={currentPlan}/>

          {currentPolygon && (
            <ApartmentsOnFloorPlan apartmentPosition={[currentPolygon]}/>
          )}
        </BaseMapContainer>
      </OverlayZoomButton>
    </div>
  );
};
