import React from 'react';
import cc from 'classcat';
import styles from './BottomSheet.module.scss';

interface BottomSheetProps {
  /** Открыта/закрыта (если true – шторка «частично» открывается) */
  isOpen: boolean;
  /** Закрытие (уезжаем вниз полностью) */
  onClose: () => void;
  /** Сколько пикселей торчит шторка, если частично открыта */
  minHeight?: number;
  /** Показывать ли затемняющий фон и блокировать клики за шторкой */
  withBackdrop?: boolean;
  className?: string;
}

/**
 * Шторка с 3 состояниями (закрыто, частично, полностью),
 * но теперь перетаскивать можно ТОЛЬКО за верхнюю «ручку»,
 * а контент внутри (children) прокручивается отдельно.
 */
export const BottomSheet: React.FC<BottomSheetProps> = ({
  isOpen,
  onClose,
  minHeight = 200,
  withBackdrop = true,
  children,
  className='',
}) => {
  // Высота экрана
  const fullHeight = React.useMemo(() => window.innerHeight, []);
  // Положение для "частичного" открытия
  const partialOpenY = fullHeight - minHeight;

  // Текущее смещение по оси Y
  const [translateY, setTranslateY] = React.useState(fullHeight);
  // Включаем/выключаем плавную анимацию (transition)
  const [withTransition, setWithTransition] = React.useState(true);

  // Запоминаем начало жеста
  const startYRef = React.useRef<number | null>(null);
  const baseTranslateYRef = React.useRef<number>(0);

  // При isOpen=true => переходим в частичное открытие, иначе закрываем (fullHeight)
  React.useEffect(() => {
    setWithTransition(true);
    if (isOpen) {
      setTranslateY(partialOpenY);
    } else {
      setTranslateY(fullHeight);
    }
  }, [isOpen, partialOpenY, fullHeight]);

  /** Закрыть (уходит вниз) и вызвать onClose() */
  function closeSheet() {
    setWithTransition(true);
    setTranslateY(fullHeight);
    // По завершении анимации (300мс) вызываем onClose
    setTimeout(() => {
      onClose();
    }, 300);
  }

  /**
   * "Защёлкиваем" шторку в одно из трёх положений,
   * исходя из того, где мы начали (baseTranslateYRef.current)
   * и где закончили (finalY).
   */
  function snapToNextState(finalY: number) {
    const threshold = 50; // "немного" = 50px
    const startPos = baseTranslateYRef.current;

    // 1) Если старт = 0 (полностью открыто)
    if (startPos === 0) {
      // Тянем вниз > threshold => уходим в partial
      if (finalY > threshold) {
        setTranslateY(partialOpenY);
      } else {
        setTranslateY(0);
      }
      return;
    }

    // 2) Если старт = partialOpenY
    if (startPos === partialOpenY) {
      const moved = finalY - partialOpenY;
      if (moved < -threshold) {
        // потянули вверх > 50px => fully open
        setTranslateY(0);
      } else if (moved > threshold) {
        // потянули вниз > 50px => закрыть
        closeSheet();
      } else {
        // остаться частично
        setTranslateY(partialOpenY);
      }
      return;
    }

    // 3) Если старт = fullHeight (закрыто)
    if (startPos === fullHeight) {
      const moved = fullHeight - finalY;
      // потянули вверх > 50 => partial
      if (moved > threshold) {
        setTranslateY(partialOpenY);
      } else {
        closeSheet();
      }
    }
  }

  // ---- DRAG / POINTER EVENTS (ТОЛЬКО на "ручке") ----
  function handlePointerDown(e: React.PointerEvent<HTMLDivElement>) {
    // игнорируем правую кнопку мыши
    if (e.pointerType === 'mouse' && e.button !== 0) return;
    setWithTransition(false);
    startYRef.current = e.clientY;
    baseTranslateYRef.current = translateY;
    e.currentTarget.setPointerCapture(e.pointerId);
  }

  function handlePointerMove(e: React.PointerEvent<HTMLDivElement>) {
    if (startYRef.current === null) return;
    const delta = e.clientY - startYRef.current;
    let newY = baseTranslateYRef.current + delta;
    if (newY < 0) newY = 0;
    if (newY > fullHeight) newY = fullHeight;
    setTranslateY(newY);
  }

  function handlePointerUp(e: React.PointerEvent<HTMLDivElement>) {
    setWithTransition(true);
    snapToNextState(translateY);
    startYRef.current = null;
    e.currentTarget.releasePointerCapture(e.pointerId);
  }

  // ---- TOUCH (fallback для Safari <13) ----
  function handleTouchStart(e: React.TouchEvent<HTMLDivElement>) {
    setWithTransition(false);
    startYRef.current = e.touches[0].clientY;
    baseTranslateYRef.current = translateY;
  }
  function handleTouchMove(e: React.TouchEvent<HTMLDivElement>) {
    if (startYRef.current === null) return;
    const delta = e.touches[0].clientY - startYRef.current;
    let newY = baseTranslateYRef.current + delta;
    if (newY < 0) newY = 0;
    if (newY > fullHeight) newY = fullHeight;
    setTranslateY(newY);
  }
  function handleTouchEnd() {
    setWithTransition(true);
    snapToNextState(translateY);
    startYRef.current = null;
  }

  // Анимация
  const containerStyle: React.CSSProperties = {
    transform: `translateY(${translateY}px)`,
    transition: withTransition ? 'transform 0.3s ease' : 'none',
  };

  return (
    <div
      className={cc([
        styles.overlay,
        className,
        !withBackdrop && styles.overlayNoBackdrop,
      ])}
      style={{ display: isOpen ? 'flex' : 'none' }}
    >
      {/* Если нужен backdrop */}
      {withBackdrop && <div className={styles.backdrop} onClick={closeSheet} />}

      <div className={styles.sheet} style={containerStyle}>
        {/*
          1) "Ручка" наверху.
             Сюда вешаем pointer/touch-события, чтобы sheet двигалась
        */}
        <div
          className={styles.dragHandle}
          onPointerDown={handlePointerDown}
          onPointerMove={handlePointerMove}
          onPointerUp={handlePointerUp}

          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          {/* Кнопка + полоска, как у вас в showFullInfoButton */}
          <button
            type="button"
            className={styles.showFullInfoButton}
          />
        </div>

        {/*
          2) Блок для контента,
             в нём overflow: auto, чтобы скроллилось свободно
        */}
        <div className={styles.contentScrollbar}>
          {children}
        </div>
      </div>
    </div>
  );
};


