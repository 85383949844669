import React, { FC } from 'react';
import styles from './DescriptionGrid.module.scss';
import { DictionaryView } from "../../../../components/atoms";

interface Cottage {
  catalogs?: {
    village_type_land?: number[];
    village_type_house?: number[];
    village_vri?: number[];
    village_material?: number[];
    village_yard_territory?: number[];
    residential_complex_finishing?: number[];
    village_facade?: number[];
    village_garage?: number[];
    village_electricity?: number[];
    village_heating?: number[];
    village_water_supply?: number[];
    village_canalization?: number[];
  };
}

interface DescriptionGridProps {
  cottageData: Cottage; // Ваш объект
}

export const DescriptionGrid: FC<DescriptionGridProps> = ({ cottageData }) => {
  // Чтобы короче было, достанем catalogs сразу
  const { catalogs } = cottageData || {};

  // Для удобства все «словари» распарсим по переменным:
  const plotType       = catalogs?.village_type_land;
  const houseType      = catalogs?.village_type_house;
  const vri            = catalogs?.village_vri;
  const buildingMat    = catalogs?.village_material;
  const yardTerritory  = catalogs?.village_yard_territory;
  const finishing      = catalogs?.residential_complex_finishing;
  const facade         = catalogs?.village_facade;
  const garage         = catalogs?.village_garage;
  const electricity    = catalogs?.village_electricity;
  const heating        = catalogs?.village_heating;
  const waterSupply    = catalogs?.village_water_supply;
  const canalization   = catalogs?.village_canalization;

  return (
    <section className={styles.description}>
      <h2 className={styles.sectionTitle}>Описание</h2>
      <div className={styles.grid}>
        {/* Тип участка */}
        <div className={styles.item}>
          <p className={styles.label}>Тип участка</p>
          <p className={styles.value}>
            {plotType ? (
              <DictionaryView dictKey="village_type_land" dictItemId={plotType} />
            ) : (
              "-"
            )}
          </p>
        </div>

        {/* Тип дома */}
        <div className={styles.item}>
          <p className={styles.label}>Тип дома</p>
          <p className={styles.value}>
            {houseType ? (
              <DictionaryView dictKey="village_type_house" dictItemId={houseType} />
            ) : (
              "-"
            )}
          </p>
        </div>

        {/* ВРИ */}
        <div className={styles.item}>
          <p className={styles.label}>ВРИ</p>
          <p className={styles.value}>
            {vri ? (
              <DictionaryView dictKey="village_vri" dictItemId={vri} />
            ) : (
              "-"
            )}
          </p>
        </div>

        {/* Материал здания */}
        <div className={styles.item}>
          <p className={styles.label}>Материал здания</p>
          <p className={styles.value}>
            {buildingMat ? (
              <DictionaryView dictKey="village_material" dictItemId={buildingMat} />
            ) : (
              "-"
            )}
          </p>
        </div>

        {/* Дворовая территория */}
        <div className={styles.item}>
          <p className={styles.label}>Дворовая территория</p>
          <p className={styles.value}>
            {yardTerritory ? (
              <DictionaryView dictKey="village_yard_territory" dictItemId={yardTerritory} />
            ) : (
              "-"
            )}
          </p>
        </div>

        {/* Отделка */}
        <div className={styles.item}>
          <p className={styles.label}>Отделка</p>
          <p className={styles.value}>
            {finishing ? (
              <DictionaryView dictKey="residential_complex_finishing" dictItemId={finishing} />
            ) : (
              "-"
            )}
          </p>
        </div>

        {/* Фасад */}
        <div className={styles.item}>
          <p className={styles.label}>Фасад</p>
          <p className={styles.value}>
            {facade ? (
              <DictionaryView dictKey="village_facade" dictItemId={facade} />
            ) : (
              "-"
            )}
          </p>
        </div>

        {/* Гараж */}
        <div className={styles.item}>
          <p className={styles.label}>Гараж</p>
          <p className={styles.value}>
            {garage ? (
              <DictionaryView dictKey="village_garage" dictItemId={garage} />
            ) : (
              "-"
            )}
          </p>
        </div>

        {/* Электричество */}
        <div className={styles.item}>
          <p className={styles.label}>Электричество</p>
          <p className={styles.value}>
            {electricity ? (
              <DictionaryView dictKey="village_electricity" dictItemId={electricity} />
            ) : (
              "-"
            )}
          </p>
        </div>

        {/* Отопление */}
        <div className={styles.item}>
          <p className={styles.label}>Отопление</p>
          <p className={styles.value}>
            {heating ? (
              <DictionaryView dictKey="village_heating" dictItemId={heating} />
            ) : (
              "-"
            )}
          </p>
        </div>

        {/* Водоснабжение */}
        <div className={styles.item}>
          <p className={styles.label}>Водоснабжение</p>
          <p className={styles.value}>
            {waterSupply ? (
              <DictionaryView dictKey="village_water_supply" dictItemId={waterSupply} />
            ) : (
              "-"
            )}
          </p>
        </div>

        {/* Канализация */}
        <div className={styles.item}>
          <p className={styles.label}>Канализация</p>
          <p className={styles.value}>
            {canalization ? (
              <DictionaryView dictKey="village_canalization" dictItemId={canalization} />
            ) : (
              "-"
            )}
          </p>
        </div>
      </div>
    </section>
  );
};


