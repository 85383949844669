import React, { useCallback } from 'react';
import cc from 'classcat';
import Simplebar from 'simplebar-react';
import { BuildingEntity } from 'services';
import {
  TabItemProps,
  useTabs,
} from 'components/containers/Tabs/hooks/useTabs';
import { getObjectWord } from 'utils';
import { TabButton } from 'components/containers/Tabs/components/TabButton';
import { TabList } from 'components/containers/Tabs/components/TabList';
import {complexItemStore} from "../../../../stores/ComplexItemStore/store";
import styles from './styles.module.css';

type Props = {
  tabs: TabItemProps<BuildingEntity>[];
  onChangeTab?: (building: TabItemProps<BuildingEntity>) => void;
  initActiveTab?: number;
  wrapperClassname?: string;
};

export const BuildingNavBar = ({
  tabs,
  onChangeTab,
  initActiveTab = 0,
  wrapperClassname,
}: Props) => {
  const { tabList, changeTab, currentActiveTab } = useTabs<BuildingEntity>({
    tabList: tabs,
    currentActiveTab: initActiveTab,
  });

  const onTabChange = useCallback(
    (index: number) => () => {
      if (onChangeTab) {
        onChangeTab(tabList[index]);
      }
      changeTab(index)();
    },
    [currentActiveTab, tabList, onChangeTab],
  );

  return (
    <Simplebar className={cc([styles.wrapper, wrapperClassname])}>
      <TabList
        currentActiveTab={currentActiveTab}
        className={styles.tabListWrapper}
      >
        {tabList.map((item, index) => (
          <TabButton
            isActive={currentActiveTab === index}
            key={item?.data?.id}
            onClick={onTabChange(index)}
            label={item?.label}
            className={styles.tablistButtonChess}
            helperText={`${item?.data?.stats?.apartments} ${getObjectWord(item?.data?.stats?.apartments || '')}`}
          />
        ))}
      </TabList>
    </Simplebar>
  );
};
