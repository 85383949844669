import React, { ComponentProps, useMemo } from 'react';
import { useMediaQuery } from 'beautiful-react-hooks';
import { SelectInModal } from "../SelectinModal";
import {
  BasePopup,
  MobilePopup,
  Text,
  Icon,
} from 'components/atoms';
import arrowLeftColor from 'assets/arrowLeftColor.svg';
import { PopupDistrictsChoicer } from "../../../PopupDistrictsChoicer";
import styles from 'components/atoms/Select/SelectSingle.module.css';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  filterItem: number[] | null;
  clearFilter: () => void;
  displayingValues: { title: string, id: number }[];
  setDisplayingValues: React.Dispatch<React.SetStateAction<{ title: string, id: number }[]>>;
} & ComponentProps<typeof PopupDistrictsChoicer>;

export const DropdownOverlay = ({
  data,
  isOpen,
  onClose,
  filterItem,
  clearFilter,
  displayingValues,
  setDisplayingValues,
  store,
  filterKey,
}: Props) => {
  const isLarge = useMediaQuery('(min-width: 1024px)');

  const PopupWrapper = useMemo(() => {
    if (isLarge) {
      return (
        props: Pick<
          ComponentProps<typeof BasePopup>,
          'children' | 'isOpen' | 'onClose' | 'className'
        >,
      ) => <BasePopup size="MD" {...props} />;
    }
    return (
      props: Pick<
        ComponentProps<typeof MobilePopup>,
        'children' | 'isOpen' | 'onClose' | 'className'
      >,
    ) => (
      <MobilePopup
        IconBack={<Icon size={24} src={arrowLeftColor} />}
        headerChildren={
          <Text align="center" size="L" weight="700">
            Район
          </Text>
        }
        {...props}
      />
    );
  }, [isLarge, isOpen]);

  return (
      <SelectInModal
        data={data}
        title="Выбор района"
        values={filterItem}
        clearFilter={clearFilter}
        onClose={onClose}
        onDisplayingValuesChange={setDisplayingValues}
        displayingValues={displayingValues}
        onChange={(values) => {
          store.setField(filterKey, values as any[]);
        }}
      />
  );
};
