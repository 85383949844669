import React from 'react';

type Props = {
  isActive: boolean;
}

const ChessMobileIcon = ({isActive}: Props) => {
  return (
    isActive ? (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.5 12H12.5M12.5 12H21.5M12.5 12V3M12.5 12V21M3.5 3H21.5V21H3.5V3Z" stroke="#4E6AFF"/>
      </svg>
    ) : (
      <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M3.5 12H12.5M12.5 12H21.5M12.5 12V3M12.5 12V21M3.5 3H21.5V21H3.5V3Z" stroke="#333338"/>
      </svg>
    )
  );
};

export default ChessMobileIcon;
