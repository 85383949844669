import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import React, { memo } from 'react';
import options from 'assets/optionsColorIcon.svg';
import closeColorIcon from 'assets/closeColorIcon.svg';
import { useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import deepEqual from 'fast-deep-equal';
import { Params } from 'services';
import { TabsSelect } from 'components/containers/TabsSelect';
import { Icon, BaseLink } from 'components/atoms';
import { useLayoutTabsState } from '../../hooks/useLayoutTabsState';

import styles from './styles.module.css';
import {TabButton} from "../../pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabButton";
import GenplanIcon from "../../../../components/ui/GenplanIcon";
import GenplanIconMobile from "../../../../components/ui/GenplanIconMobile";
import GenplanListIcon from "../../../../components/ui/GenplanListIcon";
import GenplanTableIconMobile from "../../../../components/ui/GenplanTableIconMobile";
import {TabList} from "../../pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabList";
import arrowLeftDark from "assets/lightArrowLeft.svg";
import arrowLeftIcon from "assets/arrowLeftIcon.svg";
import {useTheme} from "../../../../context/theme/useTheme";
import optionsDark from "assets/optionsDarkIcon.svg";
import ChessIcon from "../../../../components/ui/chessComponents/ChessIcon";
import PlanCottageIcon from "../../../../components/ui/chessComponents/PlanCottageIcon";
import ChessMobileIcon from "../../../../components/ui/chessComponents/ChessMobileIcon";
import PlanCottageMobileIcon from "../../../../components/ui/chessComponents/PlanCottageMobileIcon";

type Props = {
  /**
   * @desc метод для открытия фильтра в шахматке
   * */
  showAllFiltersPopup: () => void;
} & Pick<ReturnType<typeof useLayoutTabsState>, 'currentActiveTab'> &
  Pick<ReturnType<typeof useLayoutTabsState>, 'tabList'> &
  Pick<ReturnType<typeof useLayoutTabsState>, 'changeTab'>;

export const LayoutPageHeaderMobileRender = ({
  showAllFiltersPopup,
  tabList,
  currentActiveTab,
  changeTab,
}: Props) => {
  const { theme } = useTheme();
  const { complexId } = useParams<Pick<Params, 'complexId'>>();
  return (
    <>
      <header className={styles.layoutPageHeaderMobileHeader}>
        <BaseLink
          path="view"
          params={{
            complexId,
          }}
          icon={<Icon size={24} src={theme === 'dark-theme' ? arrowLeftDark : arrowLeftIcon} alt="назад к ЖК"/>}
          className={styles.headerRightButton}
        />

        <TabList currentActiveTab={currentActiveTab} tabCount={3}>
          <TabButton
            label={''}
            onClick={changeTab(0)}
            isActive={currentActiveTab === 0}
            tabIcon={<ChessMobileIcon isActive={currentActiveTab === 0} />}
          />
          <TabButton
            label={""}
            onClick={changeTab(1)}
            isActive={currentActiveTab === 1}
            tabIcon={<PlanCottageMobileIcon isActive={currentActiveTab === 1} />}
          />
          <TabButton
            label={""}
            onClick={changeTab(2)}
            isActive={currentActiveTab === 2}
            tabIcon={<GenplanTableIconMobile isActive={currentActiveTab === 2} />}
          />
        </TabList>

        <ButtonBase
          onClick={showAllFiltersPopup}
          color="transparent"
          icon={<Icon src={theme === 'dark-theme' ? optionsDark : options} size={24} alt="открыть фильтр"/>}
        />
      </header>
    </>
  );
};
export const LayoutPageHeaderMobile = memo(
  observer(LayoutPageHeaderMobileRender),
  deepEqual,
);
