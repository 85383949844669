import React from 'react';
import BaseAutoSuggest, {
  InputProps,
  OnSuggestionSelected,
  SuggestionsFetchRequested,
  OnSuggestionsClearRequested,
} from 'react-autosuggest';

import styles from './styles.module.css';
import { renderSuggestion } from './renderSuggestion';
import { renderInputComponent } from './renderInputComponent';
import { RenderSuggestionsContainer } from './renderSuggestionsContainer';

/** Тема для стилизации (CSS-модули) */
const theme = {
  container: styles.container,
  input: styles.input,
  inputFocused: styles.inputFocused,
  suggestionsList: styles.suggestionsList,
  suggestionsContainer: styles.suggestionsContainer,
  suggestionsContainerOpen: styles.suggestionsContainerOpen,
  suggestion: styles.suggestion,
  suggestionHighlighted: styles.suggestionHighlighted,
};

/** Данные одной опции для выпадающего списка */
export type AutoSuggestOption<P> = {
  label: string;          // Что показываем пользователю
  value: string | number; // Какое значение (ID и т.п.)
  icon?: string;
  payload?: P;            // Дополнительные поля
};

/** Описание структуры одной секции */
type Section<P> = {
  title: string;
  suggestions: AutoSuggestOption<P>[];
};

/** Пропсы компонента */
type Props<P> = {
  /** Все подсказки одним массивом (complex, village, developer) */
  suggestions: AutoSuggestOption<P>[];
  onSuggestionSelected?: OnSuggestionSelected<AutoSuggestOption<P>>;
  onSuggestionsFetchRequested: SuggestionsFetchRequested;
  onSuggestionsClearRequested: OnSuggestionsClearRequested;
  placeholder?: string;
} & Pick<InputProps<P>, 'value' | 'onChange'>;

export function AutoSuggest<P>({
  suggestions,
  placeholder,
  onSuggestionsFetchRequested,
  onSuggestionSelected,
  onSuggestionsClearRequested,
  value,
  onChange,
}: Props<P>) {
  // Готовим три группы: комплексы, посёлки и застройщики
  const complexes = suggestions.filter((s) => s.payload?.type === 'complex');
  const villages = suggestions.filter((s) => s.payload?.type === 'village');
  const developers = suggestions.filter((s) => s.payload?.type === 'developer');

  // Формируем две "секции" для react-autosuggest
  // Формируем массив секций, добавляя их только если есть данные
  const sections: Section<P>[] = [];

  const objectSuggestions = [...complexes, ...villages];
  if (objectSuggestions.length > 0) {
    sections.push({
      title: 'Объект',
      suggestions: objectSuggestions,
    });
  }

  if (developers.length > 0) {
    sections.push({
      title: 'Застройщик',
      suggestions: developers,
    });
  }

  if (sections.length === 0) {
    sections.push({
      title: 'Нет результатов',
      suggestions: [],
    });
  }

  /** Как из секции достать массив "подсказок" */
  const getSectionSuggestions = (section: Section<P>) => section.suggestions;

  /** Как рендерить заголовок секции (строка «Объект» или «Застройщик») */
  const renderSectionTitle = (section: Section<P>) => (
    <div className={styles.sectionTitle}>
      {section.title}
    </div>
  );

  /** Что попадёт в инпут при выборе одной подсказки */
  const getSuggestionValue = (item: AutoSuggestOption<P>) => item.label;

  return (
    <BaseAutoSuggest<AutoSuggestOption<P>>
      // Много-секционный режим
      multiSection
      suggestions={sections}
      getSectionSuggestions={getSectionSuggestions}
      renderSectionTitle={renderSectionTitle}

      // Вызовы поиска и очистки
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}

      // Колбэк выбора
      onSuggestionSelected={onSuggestionSelected}

      // Функция, которая возвращает значение, подставляемое в инпут
      getSuggestionValue={getSuggestionValue}

      // Рендер одной подсказки (ваша логика с картинками)
      renderSuggestion={renderSuggestion}

      // Кастомный компонент для обёртки + скролла
      renderSuggestionsContainer={RenderSuggestionsContainer}

      // Рендер инпута
      renderInputComponent={renderInputComponent}

      // Подключаем тему со стилями
      theme={theme}

      // Прокидываем пропсы в сам input
      inputProps={{
        placeholder,
        value,
        onChange,
      }}
    />
  );
}

