import React from 'react';
import { observer } from 'mobx-react';
import { AutoSuggest } from '../../../../../../components/atoms/AutoSuggest';
import {
  SuggestComplex,
  SuggestDeveloper,
  SuggestYandex,
} from '../../../../../../services';
import { useSuggestByAddressComplexesAndDevelopers } from '../../../../../../components/containers/Search/hooks/useSuggestByAddressComplexesAndDevelopers';

export const SuggestByAddressComplexesAndDevelopersRender = () => {
  const {
    searchValue,
    onChange,
    onSuggestionsClearRequested,
    suggestions,
    onSuggestionsFetchRequested,
    onSuggestionSelected,
  } = useSuggestByAddressComplexesAndDevelopers();
  
  return (
    <AutoSuggest<SuggestComplex | SuggestDeveloper | SuggestYandex>
      value={searchValue}
      onChange={onChange}
      placeholder="Объект, застройщик"
      suggestions={suggestions}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionSelected={onSuggestionSelected}
      onSuggestionsClearRequested={onSuggestionsClearRequested}
    />
  );
};

export const SuggestByAddressComplexesAndDevelopers = observer(
  SuggestByAddressComplexesAndDevelopersRender,
);
