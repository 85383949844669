import React, { useState, useEffect, useRef } from 'react';
import cc from 'classcat';
import { useTheme } from "../../../../../../context/theme/useTheme";
import { BottomSheet } from "components/atoms/BottomSheet/BottomSheet";
import { getObjectWord } from "utils";
import arrowDown from 'assets/arrowDownGray.svg'
import closeIconDark from 'assets/closeIconDark.svg'
import closeIcon from 'assets/closeIconLight.svg'
import styles from './PlanToggle.module.scss';

interface SectionType {
  id: number;
  title: string;
  residential_complex_id: number;
  stats: {
    apartments: number;
  };
}

interface PlanToggleProps {
  sections: SectionType[];
  selectedSectionId: number | null;
  onSelectSection: (sectionId: number) => void;
  sectionCounts: { [key: number]: number };
  className?: string;
}

/**
 * "Переключатель планов":
 * - На десктопе: ряд табов (1 корпус, 2 корпус и т.п.) + синяя полоска под активной кнопкой.
 * - На мобильных: одна кнопка, по нажатию открывается BottomSheet (100vh) со списком планов.
 */
export const PlanToggle: React.FC<PlanToggleProps> = ({
  sections,
  selectedSectionId,
  onSelectSection,
  sectionCounts,
  className,
}) => {
  const [indicatorStyle, setIndicatorStyle] = useState({ width: 0, left: 0 });
  const buttonRefs = useRef<(HTMLButtonElement | null)[]>([]);
  const {theme} = useTheme();

  // Мобильная шторка
  const [showMobileSheet, setShowMobileSheet] = useState(false);

  // Определяем — мобильный ли экран
  const [isMobile, setIsMobile] = useState(false);
  useEffect(() => {
    function checkMobile() {
      setIsMobile(window.innerWidth < 768);
    }
    checkMobile();
    window.addEventListener('resize', checkMobile);
    return () => window.removeEventListener('resize', checkMobile);
  }, []);

  // Обновляем синюю полоску под активной кнопкой (только на десктопе)
  useEffect(() => {
    const index = sections.findIndex((s) => s.id === selectedSectionId);
    if (index !== -1) {
      const btn = buttonRefs.current[index];
      if (btn) {
        setIndicatorStyle({
          width: btn.offsetWidth,
          left: btn.offsetLeft,
        });
      }
    }
  }, [selectedSectionId, sections]);

  function handleSelectSection(id: number) {
    onSelectSection(id);
    setShowMobileSheet(false);
  }

  return (
    <div className={cc([styles.planToggleContainer, className])}>
      {/* Десктопная версия: горизонтальный переключатель */}
      {!isMobile && (
        <div className={styles.planToggle}>
          {sections.map((section, index) => (
            <div key={section.id}>
              <button
                ref={(el) => (buttonRefs.current[index] = el)}
                className={section.id === selectedSectionId ? styles.active : ''}
                onClick={() => onSelectSection(section.id)}
              >
                {index + 1} корпус
                <span className={styles.objectCount}>
                  {(sectionCounts[section.id] || 0) + ' ' + getObjectWord(sectionCounts[section.id] || 0)}
                </span>
              </button>
            </div>
          ))}
          <div
            className={styles.activeIndicator}
            style={{
              width: indicatorStyle.width,
              left: indicatorStyle.left,
            }}
          />
        </div>
      )}

      {/* Мобильная версия */}
      {isMobile && (
        <>
          <button
            className={styles.mobileToggleButton}
            onClick={() => setShowMobileSheet(true)}
          >
            <span>
              {selectedSectionId !== null
                ? 'План ' + (sections.findIndex((s) => s.id === selectedSectionId) + 1)
                : 'Выберите план'}
            </span>

            <span className={styles.objectCount}>
              {selectedSectionId !== null
                ? (sectionCounts[selectedSectionId] || 0) + ' ' + getObjectWord(sectionCounts[selectedSectionId] || 0)
                : ''}
            </span>
          </button>
          <img src={arrowDown} alt='arrowDown'/>

          <BottomSheet
            isOpen={showMobileSheet}
            onClose={() => setShowMobileSheet(false)}
            withBackdrop={true}
            minHeight={220}
          >
            <div className={styles.bottomSheetHeader}>
              <h2>Выберите план</h2>
              <button className={styles.closeBtn} onClick={() => setShowMobileSheet(false)}>
                <img src={theme === 'dark-theme' ? closeIcon : closeIconDark} alt="close" />
              </button>
            </div>

            {/* Список планов */}
            <div className={styles.mobileTogglePlans}>
              {sections.map((section, index) => (
                <button
                  key={section.id}
                  onClick={() => handleSelectSection(section.id)}
                  className={cc([
                    styles.bottomSheetItem,
                    section.id === selectedSectionId && styles.active,
                  ])}
                >
                  План {index + 1}
                  <span className={styles.objectCount}>
                  {(sectionCounts[section.id] || 0) + ' ' + getObjectWord(sectionCounts[section.id] || 0)}
                </span>
                </button>
              ))}
            </div>
          </BottomSheet>
        </>
      )}
    </div>
  );
};
