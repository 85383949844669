import React from 'react';
import { Text, NumberFormat, DictionaryView } from 'components/atoms';
import cc from 'classcat';
import { get } from 'lodash/fp';
import { ApartmentsViewFilterValues } from 'stores/Apartment/ApartmentsViewFilters/ApartmentsViewFilters';
import { observer } from 'mobx-react';
import { ApartmentDto } from 'services';

import styles from './styles.module.css';
import { NotWindowsOrPlanView } from '../NotWindowsOrPlanView';

type Props = {
  apartment: ApartmentDto | undefined;
  className?: string;
  isActive?: boolean;
  viewFilterStatuses?: {
    apartment_type_room: boolean | undefined;
    layouts: boolean | undefined;
    rooms: boolean | undefined;
    residential_complex_finishing: boolean | undefined;
    price_m2: boolean | undefined;
    square: boolean | undefined;
    price: boolean | undefined;
  };
};

export const GridCardTestIDs: Record<keyof ApartmentsViewFilterValues, string> &
  Record<keyof Pick<ApartmentDto, 'number'>, string> = {
  apartment_type_room: `GridCard_apartment_type_room`,
  residential_complex_finishing: `GridCard_residential_complex_finishing`,
  rooms: `GridCard_rooms`,
  price_m2: `GridCard_price_m2`,
  square: `GridCard_square`,
  price: `GridCard_price`,
  layouts: `GridCard_layouts`,
  number: `GridCard_number`,
};

export const GridCardInfoRender = ({
  apartment,
  isActive,
  viewFilterStatuses,
  className,
}: Props) => {
  if (!apartment) {
    return null;
  }

  return (
    <div className={cc([styles.wrapper, className, isActive && styles.active])}>
      <header className={styles.header}>
        {viewFilterStatuses?.apartment_type_room && (
          <Text
            whiteSpace="nowrap"
            className={cc([styles.grayText, styles.marginRight10, styles.typeRoom])}
            size="XS"
            textTransform="uppercase"
            tagName="div"
          >
            <DictionaryView
              data-test-id={GridCardTestIDs.apartment_type_room}
              dictKey="apartment_type_room"
              dictItemId={apartment.catalogs?.apartment_type_room || []}
            />
          </Text>
        )}

        {viewFilterStatuses?.layouts && (
          <div
            data-test-id={GridCardTestIDs.layouts}
            className={styles.layoutsWrapper}
          >
            <NotWindowsOrPlanView layouts={apartment.layouts} />
          </div>
        )}
      </header>

      <section className={styles.body}>
        <div>
          <Text
            whiteSpace="nowrap"
            className={cc([styles.marginRight10, styles.numberApartment])}
            data-test-id={GridCardTestIDs.number}
            tagName="div"
          >
            №{get('number', apartment)}
          </Text>
          {viewFilterStatuses?.rooms && (
            <Text
              whiteSpace="nowrap"
              tagName="div"
              className={cc([styles.marginRight10, styles.roomsApartment])}
            >
              <DictionaryView
                data-test-id={GridCardTestIDs.rooms}
                dictKey="rooms"
                dictItemId={apartment.catalogs?.rooms || []}
              />
            </Text>
          )}
        </div>

        <div>
          {viewFilterStatuses?.residential_complex_finishing && (
            <Text
              whiteSpace="nowrap"
              tagName="div"
              className={cc([styles.finishing])}
            >
              <DictionaryView
                data-test-id={GridCardTestIDs.residential_complex_finishing}
                dictKey="residential_complex_finishing"
                dictItemId={
                  apartment.catalogs?.residential_complex_finishing || []
                }
              />
            </Text>
          )}

          {viewFilterStatuses?.square && (
            <Text
              whiteSpace="nowrap"
              className={cc([styles.finishing])}
              tagName="div"
            >
              <NumberFormat
                data-test-id={GridCardTestIDs.square}
                format="meter2"
                value={apartment.square}
              />
            </Text>
          )}
        </div>
      </section>

      <footer className={styles.footer}>
        {viewFilterStatuses?.price_m2 && (
          <Text
            whiteSpace="nowrap"
            className={cc([styles.priceM])}
            tagName="div"
          >
            <NumberFormat
              data-test-id={GridCardTestIDs.price_m2}
              format="rub_meter2"
              value={apartment.price_m2}
            />
          </Text>
        )}
        {viewFilterStatuses?.price && (
          <Text
            whiteSpace="nowrap"
            tagName="div"
            className={cc([styles.price])}
          >
            <NumberFormat
              data-test-id={GridCardTestIDs.price}
              format="rub"
              value={apartment.price}
            />
          </Text>
        )}
      </footer>
    </div>
  );
};

GridCardInfoRender.defaultProps = {
  viewFilterStatuses: {
    apartment_type_room: true,
    layouts: true,
    rooms: true,
    residential_complex_finishing: true,
    price_m2: true,
    square: true,
    price: true,
  },
};

export const GridCardInfo = observer(GridCardInfoRender);
