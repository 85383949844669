import React, { ComponentProps } from 'react';
import { FilterButtonWithFilterCount } from '../../../Main/containers/SearchAndFilterBar/components/FilterButtonWithFilterCount';

import styles from './styles.module.css';

type Props = ComponentProps<typeof FilterButtonWithFilterCount>;

export const LayoutFiltersTestIDs = {
  open: 'LayoutFiltersOpen',
  reset: 'LayoutFiltersReset'
};

export const LayoutFilters = (props: Props) => (
  <div className={styles.wrapper}>
    <FilterButtonWithFilterCount hideSaveButton {...props} />
  </div>
);
