import React from 'react';
import cc from 'classcat';
import { AutoSuggestOption } from './index';
import plugSearchDeveloper from 'assets/plugSearchDeveloper.png';
import plugComplexIcon from 'assets/plugComplexIcon.png';
import verifiedBadge from 'assets/verified-badge.svg';
import styles from './styles.module.css';

/**
 * @link https://github.com/moroshko/react-autosuggest#getsectionsuggestions-required-when-multisectiontrue
 * */
export const renderSuggestion = ({ label, icon, value, payload }: AutoSuggestOption<any>) => {
  const isDeveloper = payload?.type === 'developer';
  const hasLogo = !!payload?.logo;

  const placeholderImage = isDeveloper ? plugSearchDeveloper : plugComplexIcon;

  return (
    <div className={cc([styles.suggestionInner])}>
      <div className={isDeveloper ? styles.suggestionImgDev : styles.suggestionImg}>
        {hasLogo ? (
          <img
            src={payload?.logo}
            alt=''
          />
        ) : (
          <img
            src={placeholderImage}
            alt=''
          />
        )}
      </div>
      <div className={styles.suggestionTextBlock}>
        <div className={styles.suggestionTitleBlock}>
          {
            payload?.type === 'developer' && (
              <div className={styles.suggestionVerifiedIcon}>
                <img src={verifiedBadge} alt=''/>
              </div>
            )
          }
          <span className={styles.suggestionTitle}>{label}</span>
        </div>
        {
          payload?.developer_title && (
            <span className={styles.suggestionSubtitle}>{payload?.developer_title}</span>
          )
        }
        {
          payload?.site && (
            <span className={styles.suggestionSubtitle}>{payload?.site}</span>
          )
        }
      </div>
    </div>
  );
};