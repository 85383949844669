import React, { MouseEventHandler } from 'react';
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { PriceSelector } from './PriceSelector';
import styles from './PriceFilter.module.scss';

type Props = {
  closePriceVisibility: MouseEventHandler;
};

export const PriceFilter = ({ closePriceVisibility }: Props) => (
  <>
    <div className={styles.priceWrapper}>
      <PriceSelector
        label="от"
        filterKey="priceMin"
        values={['0', '3 000 000', '5 000 000', '7 000 000', '9 000 000']}
        value={complexFilterStore.filters.getField('priceMin')}
        onChange={(value) =>
          complexFilterStore.filters.setField('priceMin', +value)
        }
      />
      {/*<div*/}
      {/*  style={{*/}
      {/*    paddingTop: '30px',*/}
      {/*    width: '40px',*/}
      {/*    textAlign: 'center',*/}
      {/*    fontWeight: 'bold',*/}
      {/*  }}*/}
      {/*>*/}
      {/*  —*/}
      {/*</div>*/}
      <PriceSelector
        label="до"
        values={[
          '9 000 000',
          '11 000 000',
          '13 000 000',
          '15 000 000',
          '17 000 000',
        ]}
        filterKey="priceMax"
        value={complexFilterStore.filters.getField('priceMax')}
        onChange={(value) =>
          complexFilterStore.filters.setField('priceMax', +value)
        }
      />
    </div>
    <div style={{ paddingTop: '20px' }}>
      <ButtonBase fullWidth color="primary" onClick={closePriceVisibility}>
        Показать
      </ButtonBase>
    </div>
  </>
);
