import React from 'react';
import { RenderSuggestionsContainerParams } from 'react-autosuggest';
import SimpleBar from 'simplebar-react';

type Props = RenderSuggestionsContainerParams & {
  complexes: any[];
  developers: any[];
  villages: any[];
  value: string | number;
};

export const RenderSuggestionsContainer = ({
  containerProps,
  children,
  complexes,
  developers,
  villages,
  value
}: Props) => {
  const { ref, ...restContainerProps } = containerProps;

  if (value === '') {
    return null;
  }

  return (
    <SimpleBar scrollableNodeProps={{ ref }} {...restContainerProps}>
      {children}
    </SimpleBar>
  );
};