import React, {FC, useState} from 'react';
import {DownloadApp} from "./components/DownloadApk/DownloadApp";
import {DownloadPWA} from "./components/DownloadPWA/DownloadPWA";
import {TabList} from "../LayoutPage/pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabList";
import {TabButton} from "../LayoutPage/pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabButton";
import styles from "./styles.module.scss";

interface Props {
  className?: string;
}

export const DownloadAppPage: FC<Props> = () => {
  const [currentActiveTabNew, setCurrentActiveTabNew] = useState<number>(0);

  return (
    <div className={styles.downloadAppPage}>
      <div className={styles.tablistContainer}>
        <TabList currentActiveTab={currentActiveTabNew} tabCount={2} classIndicator={styles.indicator} className={styles.tabList}>
          <TabButton
            label='Android'
            onClick={() => setCurrentActiveTabNew(0)}
            isActive={currentActiveTabNew === 0}
            className={styles.tabBtn}
          />
          <TabButton
            label='iOS'
            onClick={() => setCurrentActiveTabNew(1)}
            isActive={currentActiveTabNew === 1}
            className={styles.tabBtn}
          />
        </TabList>
      </div>
      {
        currentActiveTabNew === 0 ? (
          <DownloadApp />
        ) : (
          <DownloadPWA />
        )
      }
    </div>
  );
};
