import React from 'react';
import BaseAutoSuggest, {
  InputProps,
  OnSuggestionSelected,
  SuggestionsFetchRequested,
  OnSuggestionsClearRequested,
} from 'react-autosuggest';

import styles from './styles.module.css';
import { RenderSuggestionsContainer } from './renderSuggestionsContainer';
import { renderSuggestion } from './renderSuggestion';
import { renderInputComponent } from './renderInputComponent';
import { AutoSuggestOption } from '../AutoSuggest';

/**
 * @link https://github.com/moroshko/react-autosuggest#theme-optional
 */
const theme = {
  container: styles.container,
  input: styles.input,
  inputFocused: styles.inputFocused,
  suggestionsList: styles.suggestionsList,
  suggestionsContainer: styles.suggestionsContainer,
  suggestionsContainerOpen: styles.suggestionsContainerOpen,
  suggestion: styles.suggestion,
  suggestionHighlighted: styles.suggestionHighlighted,
};

export type MobileAutoSuggestOption<P> = {
  /** Заголовок, который будет показан в списке */
  label: string;
  /** Значение (например, ID), которое привязано к опции */
  value: string | number;
  /** Ссылка на иконку */
  icon?: string;
  /** Дополнительные данные, например исходные данные */
  payload?: P;
};

type Section<P> = {
  title: string;
  suggestions: AutoSuggestOption<P>[];
};

type Props<P extends any> = {
  suggestions: MobileAutoSuggestOption<P>[];
  /**
   * @link https://github.com/moroshko/react-autosuggest#onsuggestionselected-optional
   */
  onSuggestionSelected?: OnSuggestionSelected<MobileAutoSuggestOption<P>>;
  /**
   * @link https://github.com/moroshko/react-autosuggest#onsuggestionsfetchrequested-required
   */
  onSuggestionsFetchRequested: SuggestionsFetchRequested;
  onSuggestionsClearRequested: OnSuggestionsClearRequested;
  placeholder?: string;
} & Pick<InputProps<P>, 'value'> &
  Pick<InputProps<P>, 'onChange'>;

export const MobileAutoSuggest = <OptionPayload extends any>({
  suggestions,
  placeholder,
  onSuggestionsFetchRequested,
  onSuggestionSelected,
  onSuggestionsClearRequested,
  value,
  onChange,
}: Props<OptionPayload>) => {
  // Разделяем входные данные на три группы
  const complexes = suggestions.filter((s) => s.payload?.type === 'complex');
  const villages = suggestions.filter((s) => s.payload?.type === 'village');
  const developers = suggestions.filter((s) => s.payload?.type === 'developer');

  // Формируем массив секций только при наличии данных
  const sections: Section<OptionPayload>[] = [];

  const objectSuggestions = [...complexes, ...villages];
  if (objectSuggestions?.length > 0) {
    sections.push({
      title: 'Объект',
      suggestions: objectSuggestions,
    });
  }

  if (developers?.length > 0) {
    sections.push({
      title: 'Застройщик',
      suggestions: developers,
    });
  }

  const getSectionSuggestions = (section: Section<OptionPayload>) => section.suggestions;

  /** Отображение заголовка секции */
  const renderSectionTitle = (section: Section<OptionPayload>) => (
    <div className={styles.sectionTitle}>
      {section.title}
    </div>
  );

  /** Значение, которое подставится в инпут при выборе подсказки */
  const getSuggestionValue = (item: AutoSuggestOption<OptionPayload>) => item.label;

  // Функция очистки поля ввода (по клику на крестик)
  const handleClear = () => {
    onChange({} as React.FormEvent<HTMLInputElement>, {
      newValue: '',
      method: 'click',
    });
  };

  return (
    <BaseAutoSuggest<MobileAutoSuggestOption<OptionPayload>>
      // Много-секционный режим
      multiSection
      suggestions={sections}
      getSectionSuggestions={getSectionSuggestions}
      renderSectionTitle={renderSectionTitle}

      // Вызовы поиска и очистки
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      onSuggestionsClearRequested={onSuggestionsClearRequested}

      // Колбэк выбора
      onSuggestionSelected={onSuggestionSelected}

      // Функция, которая возвращает значение, подставляемое в инпут
      getSuggestionValue={getSuggestionValue}

      // Рендер одной подсказки (ваша логика с иконками)
      renderSuggestion={renderSuggestion}

      // Кастомный контейнер для списка (с логикой "нет результатов")
      renderSuggestionsContainer={RenderSuggestionsContainer}

      // Кастомный компонент инпута
      renderInputComponent={renderInputComponent}

      // Подключаем тему со стилями
      theme={theme}

      // Прокидываем пропсы в сам input
      inputProps={{
        placeholder,
        value,
        onChange,
        // Добавляем свой проп onClearClick, чтобы поле могло очиститься
        onClearClick: handleClear,
      }}
    />
  );
};


