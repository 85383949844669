import React from 'react';
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { ReactiveSelect } from 'components/containers/Search/components/ReactiveSelect';
import {
  TabList
} from "../../../../../LayoutPage/pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabList";
import {
  TabButton
} from "../../../../../LayoutPage/pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabButton";
import styles from './RoomsCottagesFilter.module.scss';
import {searchService} from "../../../../../../services";

type Props = {
  maxRoomsInViewport: number;
};

export const RoomsСottagesFilter = ({ maxRoomsInViewport }: Props) => {
  const { currentFilterTab, setFilterTab } = complexFilterStore.filters;
  const isLoading = searchService.isLoading;

  return (
    <div className={styles.container}>
      <div className={styles.filtersHeaderBottom}>
        <TabList className={styles.tabListMain} currentActiveTab={currentFilterTab} tabCount={2}>
          <TabButton
            isActive={currentFilterTab === 0}
            onClick={() => setFilterTab(0)}
            key={'ЖК'}
            label={'ЖК'}
          />
          <TabButton
            isActive={currentFilterTab === 1}
            onClick={() => setFilterTab(1)}
            key={'КП'}
            label={'КП'}
          />
        </TabList>
      </div>

      {currentFilterTab ? (
        <ReactiveSelect
          store={complexFilterStore.filters}
          onlyInternal
          placeholder=""
          filterKey="villageTypeHouse"
          dictKey="village_type_house"
          filterDictionaryItems={(dictionaryItems) =>
            infoStore.getItemsStartingAt(
              dictionaryItems,
              maxRoomsInViewport,
            )
          }
        />
      ) : (
        <ReactiveSelect
          store={complexFilterStore.filters}
          onlyInternal
          placeholder=""
          filterKey="rooms"
          dictKey="rooms"
          disabled={isLoading}
          filterDictionaryItems={(dictionaryItems) => {
            const result = [...dictionaryItems];

            // 1. «Свободная» (rooms_n) в начало
            const freeIndex = result.findIndex((item) => item.key === 'rooms_n');
            if (freeIndex !== -1) {
              const [freeItem] = result.splice(freeIndex, 1);
              result.unshift(freeItem);
            }

            // 2. «5E комнат» (rooms_5e) перед «6 комнат» (rooms_6)
            const fiveEIndex = result.findIndex((item) => item.key === 'rooms_5e');
            const sixIndex = result.findIndex((item) => item.key === 'rooms_6');

            if (fiveEIndex !== -1 && sixIndex !== -1 && fiveEIndex > sixIndex) {
              const [fiveEItem] = result.splice(fiveEIndex, 1);
              result.splice(sixIndex, 0, fiveEItem);
            }
            return result;
          }}
        />
      )}
    </div>
  );
};
