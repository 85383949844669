import React from 'react';

type Props = {
  isActive: boolean;
}

const ChessIcon = ({isActive}: Props) => {
  return (
    isActive ? (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 8H8M8 8H14M8 8V2M8 8V14M2 2H14V14H2V2Z" stroke="#4E6AFF"/>
      </svg>
    ) : (
      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2 8H8M8 8H14M8 8V2M8 8V14M2 2H14V14H2V2Z" stroke="#333338"/>
      </svg>
    )
  );
};

export default ChessIcon;
