import React from 'react';
import cc from 'classcat';
import { Badge } from 'components/atoms';
import styles from './styles.module.css';

type Props = {
  onClick?: () => void;
  label: string;
  helperText?: string | number;
  badgeText?: string;
  isActive?: boolean;
  className?: string;
  [k: string]: any;
};

export const TabButton = ({
  label,
  helperText,
  onClick,
  badgeText,
  isActive,
  className,
  ...rest
}: Props) => (
  <button
    {...rest}
    type="button"
    className={cc([styles.button, className])}
    onClick={onClick}
  >
    <div>
      <div
        className={cc({
          [styles.labelLarge]: !helperText,
          [styles.labelSmall]: !!helperText,
          [styles.labelActive]: isActive,
        })}
      >
        {label}
      </div>
      {helperText && <div className={styles.helperText}>{helperText}</div>}
    </div>
    {badgeText && (
      <div className={styles.badge}>
        <Badge isCircle color={isActive ? '#4E6AFF' : '#B9B9B9'}>
          {badgeText}
        </Badge>
      </div>
    )}
  </button>
);
