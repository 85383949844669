import React, { FC } from 'react';
import { appRoutesService } from "services";
import { Link } from "react-router-dom";
import cc from "classcat";
import flatbyIcon from 'assets/banners/flatbyIcon.svg'
import closeIcon from 'assets/closeIconWhite.svg';
import phoneImg from 'assets/banners/phoneImg.png';
import phoneImgSingle from 'assets/banners/phoneImgSingle.png';
import bannerBg from 'assets/banners/bannerRightBg.svg';
import rustoreIcon from 'assets/banners/rustoreIcon.svg';
import styles from './AppBanner.module.scss';

interface TelegramProps {
  setIsClosed: () => void;
  columns?: number;
}

export const AppBanner: FC<TelegramProps> = ({ setIsClosed, columns}) => {
  const handleClose = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsClosed();
  };

  return (
    <div className={cc([styles.bannerWrapper, columns === 2 && styles.bannerWrapperMiddle, columns === 1 && styles.bannerWrapperSmall])}>
      <Link to={appRoutesService.getRoute('downloadApp')} target="_blank" rel="noopener noreferrer" className={styles.bannerInner}>
        <div
          className={styles.banner}
        >
          <div className={styles.content}>
            <div className={styles.logoContainer}>
              <img src={flatbyIcon} alt="Flatby logo"/>
            </div>

            <div className={styles.textContainer}>
              <h2 className={styles.title}>Скачать приложение</h2>
              <p className={styles.description}>
                Загрузите наше приложение с RuStore, что бы использовать в своей работе
              </p>
            </div>
          </div>
        </div>

        <div className={styles.bannerColorRight}>
          <img src={bannerBg} alt='' />
        </div>
        <div className={styles.phoneImgRight}>
          <img src={columns === 1 ? phoneImgSingle : phoneImg} alt='phone-img'/>
        </div>
        <button
          type="button"
          className={styles.closeButton}
          onClick={handleClose}
          aria-label="Закрыть"
        >
          <img src={closeIcon} alt="close"/>
        </button>

        <div className={cc([styles.rustoreIcon, columns && columns === 1 && styles.rustoreIconSmall])}>
          <img src={rustoreIcon} alt='' />
        </div>
      </Link>
    </div>
  );
};
