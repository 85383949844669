import React, { FC } from 'react';
import { observer } from "mobx-react";
import checkIcon from '../../images/check-icon.png'
import styles from './AdvantagesList.module.scss';
import { Complex, findDictItemValue } from "../../../../services";
import { infoStore } from "../../../../stores";
import { CottageDetails } from "../../../../services/v1/villageService/types";

interface AdvantagesListProps {
  complexData: Complex;
  title?: string;
  apartmentData?: CottageDetails;
  ComplexData?: Complex;
}

export const AdvantagesList: FC<AdvantagesListProps> = observer(({ title, apartmentData, ComplexData }) => {
  const advantageNames = findDictItemValue(
    'village_apartment_advantages',
    apartmentData?.catalogs?.village_apartment_advantages ? apartmentData?.catalogs?.village_apartment_advantages : [],
  )(infoStore?.dictionaries) || [];

  const advantageComplexNames = findDictItemValue(
    'village_advantages',
    ComplexData?.catalogs?.village_advantages ? ComplexData?.catalogs?.village_advantages : [],
  )(infoStore?.dictionaries) || [];

  return (
    <section className={styles.advantages}>
      <h2 className={styles.sectionTitle}>{title ? title : 'Преимущества'}</h2>
      <div className={styles.list}>
        {advantageNames && advantageNames?.map((advantage, index) => (
          <div className={styles.item} key={index}>
            <img src={checkIcon} alt="" className={styles.icon} />
            {advantage}
          </div>
        ))}
        {advantageComplexNames && advantageComplexNames?.map((advantage, index) => (
          <div className={styles.item} key={index}>
            <img src={checkIcon} alt="" className={styles.icon} />
            {advantage}
          </div>
        ))}
      </div>
    </section>
  );
});

