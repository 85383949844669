import React, {useEffect} from 'react';
import { useParams } from "react-router-dom";
import { observer } from 'mobx-react';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import pdfIcon from 'assets/pdfIcon.svg';
import { Icon } from "../../atoms";
import { usePdfSettingsPopup } from "../../../pages/PdfPage/components/PdfSettingsPopup/hooks/usePdfSettingsPopup";
import { apartmentsService, Params } from "../../../services";
import { complexItemStore } from "../../../stores/ComplexItemStore/store";
import { feedbackStore } from "../../../stores/FeedbackStore/FeedbackStore";
import { useInitApartmentSidebar } from "../../../pages/LayoutPage/hooks/useInitApartmentSidebar";
import { rootApartmentStore } from "../../../stores/Apartment/RootApartmentStore";
import styles from './styles.module.scss';
import cc from "classcat";
import PdfIcon from "../../ui/PdfIcon";

type Props = {
  showLabels?: boolean;
  textClassName?: string;
  className?: string;
  apartment?: any;
};

export const PdfButtonRender = ({
  textClassName,
  className,
  apartment
}: Props) => {
  const { complexId } = useParams<Pick<Params, 'complexId'>>();
  const { generalPlanId } = useParams<Pick<Params, 'generalPlanId'>>();
  const { showPdfSettingsPopup } = usePdfSettingsPopup();
  const {
    isVisible,
    target: complexData,
    toggleVisibility,
    fetchComplex,
    clearTarget,
  } = complexItemStore;

  useEffect(() => {
    if (!complexData || complexData?.id !== Number(complexId)) {
      fetchComplex(Number(complexId));
      toggleVisibility(true);
      feedbackStore.setInitialValues({
        type: 'complex',
        recordId: complexId,
      });
    }
  }, [complexId, fetchComplex, toggleVisibility, complexData]);

  useInitApartmentSidebar({ apartmentsService });
  const { apartmentUIStore } = rootApartmentStore;

  const onClick = () => {
    showPdfSettingsPopup(complexData, apartment || apartmentUIStore?.activeApartment || null, Boolean(generalPlanId));
  };

  return (
    <ButtonBase
      onClick={onClick}
      icon={<PdfIcon />}
      textClassName={textClassName}
      className={cc([styles.textClassName, className])}
    >
    </ButtonBase>
  );
};

export const PdfButton = observer(PdfButtonRender);
