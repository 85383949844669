import qs from 'qs';
import { Logo } from 'services/v1/rc/view/types';
import { AttemptResult, ThrowableResult } from '../../../types';
import { makeRequestWithAuth } from '../../../libs/axios/makeRequest';
import { API_ROUTES } from '../../../constants';
import { loggerService } from '../../../loggerService';
import {
  UserInfoResponse,
  UserProfileEditRequestBody,
  UserProfileEditResponse,
} from './types';
import {attempt, attemptWithAuth} from '../../../libs/axios/attempt';

export type UserProfileService = typeof userProfileService;

export const userProfileService = {
  getUserProfile: (): Promise<AttemptResult<UserInfoResponse>> =>
    attemptWithAuth<UserInfoResponse>({
      url: API_ROUTES.user.profile,
      method: 'get',
    }),

  getUserProfilePdf: (token?: string): Promise<AttemptResult<UserInfoResponse>> =>
    attempt<UserInfoResponse>({
      url: API_ROUTES.user.profile,
      method: 'get',
      headers: token ? { 'X-Auth-Token': `${token}` } : {},
    }),


  saveUserProfile: (
    user: UserProfileEditRequestBody,
  ): Promise<AttemptResult<UserProfileEditResponse>> =>
    attemptWithAuth<UserInfoResponse>({
      url: API_ROUTES.user.profile,
      method: 'post',
      params: user,
      paramsSerializer: (params) => qs.stringify(params),
    }),

  saveCity: async (cityId: number): Promise<AttemptResult<UserProfileEditResponse>> =>
    attemptWithAuth<UserProfileEditResponse>({
      url: API_ROUTES.user.city,
      method: 'post',
      data: { cityId },
    }),

  saveUserAvatar: async (file: File): Promise<ThrowableResult<Logo>> => {
    const formData = new FormData();
    formData.append('file', file);

    try {
      const { data } = await makeRequestWithAuth<Logo>({
        url: API_ROUTES.user.profilePhoto,
        method: 'post',
        data: formData,
      });

      return data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
};
