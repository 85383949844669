import React, {useContext} from 'react';
import { Text } from 'components/atoms';
import { Section, getAllColsTitles, getMatrix, BuildingEntity } from 'services';
import { MayBe } from 'types';
import cc from 'classcat';
import { useId } from 'react-id-generator';

import { observer } from 'mobx-react';
import { gridApartmentsStylesStore } from '../../stores/GridApartmentsStylesStore';
import { BuildingNavBarTabsContext } from "../../../../context/BuildingNavBarTabsContext";
import styles from './styles.module.css';

/**
 * @desc расчет ширина заголовков секций, для каждой секции мы получаем  начальную и конечную grid-line
 * */
export const getSectionsTitleGridColumnStyle = (
  sections?: MayBe<Section[]>,
) => {
  if (!sections) {
    return [];
  }
  let leftLine = 2;
  let rightLine = 0;
  return sections.map((section) => {
    const matrix = getMatrix(section);
    const cols = matrix?.cols || 0;

    rightLine = leftLine + cols;

    const style = {
      gridColumnStart: leftLine,
      gridColumnEnd: rightLine,
    };
    leftLine = rightLine;

    return style;
  });
};

type Props = Pick<BuildingEntity, 'sections'>;

export const SectionHeaderBarRender = ({ sections }: Props) => {
  const { SECTIONS_COLUMN_HEIGHT, SECTIONS_TITLE_HEIGHT } =
    gridApartmentsStylesStore;
  const colsTitles = getAllColsTitles(sections);

  const sectionsTitleGridColumnStyle =
    getSectionsTitleGridColumnStyle(sections);
  const colsTitlesIdList: string[] = useId(colsTitles.length, 'colsTitles');
  const sectionsIdList: string[] = useId(sections?.length, 'sections');

  const { buildingsTabs } = useContext(
    BuildingNavBarTabsContext,
  );

  console.log(buildingsTabs[0]?.helperText, 'buildingsTabs[0]?.helperText')

  return (
    <>
      <div
        className={styles.sectionFirst}
        style={{
          height: SECTIONS_TITLE_HEIGHT + SECTIONS_COLUMN_HEIGHT,
        }}
      />
      {sections?.map(({ title, id }, index) => (
        <div
          key={sectionsIdList[index]}
          style={{
            ...sectionsTitleGridColumnStyle[index],
            height: SECTIONS_TITLE_HEIGHT,
          }}
          className={cc([
            styles.sectionTitle,
            index === 0 && styles.borderLeftNone,
            index === sections?.length - 1 && styles.borderRight,
          ])}
        >
          <div className={styles.sectionTitleBlock}>
            <span className={styles.sectionTitleText}>{title}</span>
            {
              buildingsTabs[0]?.helperText && buildingsTabs[0]?.helperText !== ' ' && (
                <span className={styles.sectionTitleBadge}>{buildingsTabs[0]?.helperText}</span>
              )
            }
          </div>
        </div>
      ))}
      {colsTitles?.map((colTitle, index) => (
        <div
          key={colsTitlesIdList[index]}
          className={cc([
            styles.sectionItem,
            index === 0 && styles.borderLeftNone,
            index === colsTitles?.length - 1 && styles.borderRight,
          ])}
          style={{
            gridColumnStart: index + 2,
            gridColumnEnd: index + 3,
            height: SECTIONS_COLUMN_HEIGHT,
            /** это офсет от верхней границы тк шапка имеет позиционированние sticky */
            top: SECTIONS_TITLE_HEIGHT,
          }}
        >
          <div className={styles.sectionItemText}>
            {colTitle}
          </div>
        </div>
      ))}
    </>
  );
};

export const SectionHeaderBar = observer(SectionHeaderBarRender);
