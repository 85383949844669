import { YandexSuggestResult } from 'react-app-env';
import { ID, MayBe } from 'types';

export type SuggestComplex = {
  id: ID;
  title: MayBe<string>;
  address: MayBe<string>;
  latitude: MayBe<string>;
  longitude: MayBe<string>;
};
export type SuggestVillage = SuggestComplex;
export type SuggestDeveloper = {
  id: ID;
  title: MayBe<string>;
  logo: MayBe<string>;
};
export type SuggestYandex = YandexSuggestResult;

export type SuggestResponse = {
  complexes: SuggestComplex[];
  villages: SuggestVillage[];
  developers: SuggestDeveloper[];
};

export const  isSuggestComplex = (payload: YandexSuggestResult | SuggestComplex | SuggestDeveloper): payload is SuggestComplex | SuggestVillage  => {
  // return 'latitude' in payload && 'longitude' in payload;
  return 'latitude' in payload && 'longitude' in payload && ('address' in payload || payload.type === 'village');
}
