import React, { ReactNode, CSSProperties, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import cc from 'classcat';
import { isNil } from 'lodash';
import { FilterStore } from 'stores/FilterStore';
import styles from './styles.module.css';

type Props = {
  filterKey: any;
  store: FilterStore<any, any>;
  placeholder?: string;
  prefixText?: string;
  suffixIcon?: ReactNode;
  clearable?: boolean;
  className?: string;
  disabled?: boolean;
  customStyles?: CSSProperties;
  isFormatValue?: boolean;
  minValue?: number;
  maxValue?: number;
};

export const ReactiveInputRender = ({
                                      filterKey,
                                      store,
                                      placeholder = 'Выбрать',
                                      prefixText,
                                      suffixIcon,
                                      clearable = false,
                                      className,
                                      disabled,
                                      customStyles,
                                      isFormatValue = false,
                                      minValue,
                                      maxValue,
                                    }: Props) => {
  const filterItem: number | undefined = store.filters[filterKey];
  const initialValue = String(isNil(filterItem) || filterItem === 0 ? '' : filterItem);
  const [inputValue, setInputValue] = useState(initialValue);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const updatedValue = String(isNil(filterItem) || filterItem === 0 ? '' : filterItem);
    if (updatedValue !== inputValue) {
      setInputValue(updatedValue);
    }
  }, [filterItem]);

  const formatValue = (value: string | number) =>
    value ? String(value).replace(/\B(?=(\d{3})+(?!\d))/g, ' ') : '';

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\s/g, ''); // Убираем пробелы

    if (!isNaN(Number(value)) || value === '') {
      setInputValue(value);

      if (value === '') {
        setError(null);
        store.setField(filterKey, undefined);
        return;
      }

      const numValue = Number(value);

      if (minValue !== undefined && numValue < minValue) {
        setError(`Минимальное значение: ${minValue}`);
      } else if (maxValue !== undefined && numValue > maxValue) {
        setError(`Максимальное значение: ${maxValue}`);
      } else {
        setError(null);
        store.setField(filterKey, numValue); // Сохраняем только корректное значение
      }
    }
  };

  const handleClear = () => {
    setInputValue('');
    store.setField(filterKey, undefined);
    setError(null);
  };

  return (
    <div className={cc([className, styles.inputWrapper, error && styles.inputWrapperError])}>
      {prefixText && <span className={styles.prefixText}>{prefixText}</span>}
      <input
        type="text"
        placeholder={placeholder}
        value={isFormatValue ? formatValue(inputValue) : inputValue}
        onChange={handleChange}
        disabled={disabled}
        className={cc([
          styles.inputTag,
          { [styles.inputTagWithSuffix]: suffixIcon || clearable },
          error && styles.inputError, // Добавляем стиль ошибки
        ])}
        style={customStyles}
      />
      {suffixIcon && (
        <span className={cc([styles.suffixIcon, inputValue && styles.suffixIconClose])}>
          {suffixIcon}
        </span>
      )}
      {clearable && inputValue && (
        <button type="button" className={styles.clearButton} onClick={handleClear}>
          ✕
        </button>
      )}
      {error && <div className={styles.errorText}>{error}</div>}
    </div>
  );
};

export const ReactiveInput = observer(ReactiveInputRender);

