import React from 'react';
import { BuildingFiltersStore } from 'stores';
import { FirstColumnFilter } from '../Search/components/FirstColumnFilters';
import styles from './AllFilters.module.scss';
import { SecondColumnFilter } from "../Search/components/SecondColumnFilters";
import { ThirdColumnFilters } from "../Search/components/ThirdColumnFilters";
import {MainComplexFilters} from "../Search/components/MainComplexFilters";

type Props = {
  isShowFromComplex?: boolean;
  store?: BuildingFiltersStore['filters'];
}

export const MainComplexTab = ({isShowFromComplex = false, store }: Props) => (
  <>
    <div className={styles.formColumn}>
      <MainComplexFilters isShowFromComplex={isShowFromComplex} store={store} />
    </div>

    {/*<div className={styles.formColumn}>*/}
    {/*  <SecondColumnFilter isShowFromComplex={isShowFromComplex} store={store}/>*/}
    {/*</div>*/}

    {/*<div className={styles.formColumn}>*/}
    {/*  <ThirdColumnFilters isShowFromComplex={isShowFromComplex} store={store}/>*/}
    {/*</div>*/}
  </>
);
