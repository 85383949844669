import { flow, get, map, filter, has } from 'lodash/fp';
import pathToRegexp from 'path-to-regexp';
import qs from 'qs';
import { loggerService } from '../loggerService';

export const APP_ROUTES = {
  /** @desc main page */
  main: '/',
  /** @desc residential complex */
  view: '/view/:complexId',
  myModal: '/myModal/village/:Id',

  stock: '/view/:complexId/stock/:stockId',

  downloadApp: '/download-app',

  pdfPage: '/view/:complexId/apartment/:apartmentId/pdf',
  pdfPageCottage: '/view/:complexId/cottage/:apartmentId/pdf',

  /** @desc residential complex */
  albums: '/view/:complexId/albums/:albumId',
  cottageAlbums: '/village/:cottageId/albums/:albumId',
  /** @desc residential complex apartment */
  layout: '/view/:complexId/:buildingId/apartment',
    /** @desc item apartment of village complex */
  cottage: '/view/:complexId/:buildingId/cottage',

  generalPlan: '/view/:complexId/:generalPlanId/general-plan/:viewType/:sectionId',
  generalPlanCottage: '/view/:complexId/:generalPlanId/general-plan/:viewType/:sectionId/:cottageId/:cottageNumber',

  layoutActiveTab: '/view/:complexId/:buildingId/apartment/:layoutTabs',
  layoutActiveTabAuth:
    '/view/:complexId/:buildingId/apartment/:layoutTabs/auth',
  layoutActiveApartment:
    '/view/:complexId/:buildingId/apartment/:layoutTabs/:apartmentId/:apartmentNumber',

  authEmailCodePage: '/auth/email/code',
  authEmailConfirmPage: '/auth/email/confirm/:code',
  authEmailNotFoundPage: '/auth/email/not-found',
  authEmailSendLinkPage: '/auth/email/send-auth-link',
  authEmailValidatePage: '/auth/email/validate/:code',

  authPhoneCodePage: '/auth/phone/code',
  authPhoneRegistrationPage: '/auth/phone/registration',
  authPhoneRegistrationSuccessPage: '/auth/phone/registration-success',
  authPhoneValidatePage: '/auth/phone/validate',
  authEmailChangePage: '/auth/email/change/:code',
  authKey: '/auth/key/:key',
  linkIsOutOfDatePage: '/auth/link-is-out-of-date',

  privacy: '/privacy',

  profile: '/profile',

  profileNewPhone: '/profile/editor/phone/new',
  profileConfirmNewPhone: '/profile/editor/phone/new/confirm',
  profileConfirmOldPhone: '/profile/editor/phone/old/confirm',

  profileNewEmail: '/profile/editor/email/new',
  profileConfirmNewEmail: '/profile/editor/email/new/confirm',
  profileConfirmOldEmail: '/profile/editor/email/old/confirm',
  profileLinkIsOutOfDatePage: '/profile/editor/email/link-is-out-of-date',

  favorites: '/profile/favorites',
  offers: '/profile/offers',
  feeds: '/profile/feeds',
  /** страница подборки для клиента */
  offersViewClient: '/offers/:offerTabs/:hash',
  /** страница квартиры в подборке */
  offersApartmentView: '/offers/apartment/:offerId/:hash/:apartmentId',
  /** страница квартиры в подборке */
  profileOfferViewRealtor: '/profile/offers/:offerId',
};

export type RouteIds =
  | 'hash'
  | 'offerId'
  | 'editId'
  | 'offerTabs'
  | 'albumId'
  | 'layoutTabs'
  | 'complexId'
  | 'apartmentId'
  | 'apartmentNumber'
  | 'code'
  | 'key'
  | 'stockId'
  | 'salesDepartmentType'
  | 'salesDepartmentId'
  | 'buildingId'
  | 'generalPlanId'
  | 'viewType'
  | 'sectionId'
  | 'cottageId'
  | 'cottageNumber';

export type Params = Partial<Record<RouteIds, string | undefined>>;

export class AppRoutesService {
  // eslint-disable-next-line class-methods-use-this
  getRoute(key: keyof typeof APP_ROUTES, queryString?: object): string {
    if (queryString) {
      return [APP_ROUTES[key], qs.stringify(queryString)].join('?');
    }

    return APP_ROUTES[key];
  }

  replaceRoute(
    key: keyof typeof APP_ROUTES,
    params?: Params,
    queryString?: object,
  ): string {
    this.hasAllParametersPresent(key, params);
    if (!params) {
      return this.getRoute(key, queryString);
    }
    return Object.entries(params).reduce(
      (accum: string, [key, value], _) =>
        accum.replace(`:${key}`, `${value || ''}`),
      this.getRoute(key, queryString),
    );
  }

  hasAllParametersPresent = (key: keyof typeof APP_ROUTES, params?: Params) => {
    try {
      const result = pathToRegexp(this.getRoute(key));
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const notIncludeKeys: string[] = flow(
        map(get('name')),
        filter((key) => !has(key, params)),
      )(result.keys);
      if (notIncludeKeys?.length > 0) {
        loggerService.warn(
          `this key not found: ${notIncludeKeys.toString()} for route ${this.getRoute(
            key,
          )}`,
        );
        return false;
      }
      return true;
    } catch (e) {
      return true;
    }
  };
}

export const appRoutesService = new AppRoutesService();
