import React, { useEffect, useMemo } from 'react';
import { observer } from 'mobx-react';

import { TabContentRenderer } from 'components/atoms';
import { useTabs } from 'components/containers/Tabs';

import { ID } from 'types';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { createCountFormatter } from 'utils/createCountFormatter';
import { ComplexesList } from './components/ComplexesList';

import { ApartmentsList } from './components/ApartmentsList';
import { LayoutTabsEnum } from './types';
import { FavoritesPageHeader } from './components/FavoritesPageHeader';
import { CottagesList } from "./components/CottagesList";
import { CottageList } from "./components/CottageList";

const formatApartmentsTitle = createCountFormatter({
  few: 'квартир',
  one: 'квартира',
  two: 'квартиры',
});

const formatCottagesTitle = createCountFormatter({
  few: 'объектов',
  one: 'объект',
  two: 'объекта',
});

const getComplexTitle = (complexIds: ID[]) => {
  if (complexIds.length) {
    return `${complexIds.length} ЖК в избранном`;
  }
  return undefined;
};

const getApartmentTitle = (apartmentIds: ID[]) => {
  if (apartmentIds.length) {
    return `${apartmentIds.length} ${formatApartmentsTitle(
      apartmentIds.length,
    )} в избранном`;
  }
  return undefined;
};

const getCottageComplexTitle = (cottageComplexIds: ID[]) => {
  if (cottageComplexIds.length) {
    return `${cottageComplexIds.length} КП в избранном`;
  }
  return undefined;
};

const getCottageTitle = (cottageIds: ID[]) => {
  if (cottageIds.length) {
    return `${cottageIds.length} ${formatCottagesTitle(cottageIds.length)}`;
  }
  return undefined;
};

export const FavoritesPageRenderer = () => {
  const {
    state: {
      apartmentIds,
      complexIds,
      villagesIds,
      cottageIds,
    },
  } = rootProfileStore.favoritesIdsStore;

  const favoritesTabsList = useMemo(
    () => [
      {
        label: 'Жилые комплексы',
        badgeText: complexIds.length.toString(),
      },
      {
        label: 'Квартиры',
        badgeText: apartmentIds.length.toString(),
      },
      {
        label: 'Коттеджные поселки',
        badgeText: villagesIds.length.toString(),
      },
      {
        label: 'Коттеджи',
        badgeText: cottageIds.length.toString(),
      },
    ],
    [
      apartmentIds.length,
      complexIds.length,
      villagesIds.length,
      cottageIds.length,
    ],
  );

  const { currentActiveTab, changeTab, setTabList } = useTabs({
    tabList: favoritesTabsList,
  });

  useEffect(() => {
    setTabList(favoritesTabsList);
  }, [favoritesTabsList]);

  const title = useMemo(() => {
    let title: string | undefined;
    if (currentActiveTab === 0) {
      title = getComplexTitle(complexIds);
      return title || 'Объектов нет';
    }
    if (currentActiveTab === 1) {
      title = getApartmentTitle(apartmentIds);
      return title || 'Квартир нет';
    }
    if (currentActiveTab === 2) {
      title = getCottageComplexTitle(villagesIds);
      return title || 'Объектов нет';
    }
    if (currentActiveTab === 3) {
      title = getCottageTitle(cottageIds);
      return title || 'Объектов нет';
    }

    return '';
  }, [
    currentActiveTab,
    complexIds,
    apartmentIds,
    villagesIds,
    cottageIds,
  ]);

  return (
    <>
      <FavoritesPageHeader
        tabList={favoritesTabsList}
        changeTab={changeTab}
        currentActiveTab={currentActiveTab}
        title={title}
      />
      <TabContentRenderer
        tabs={{
          [LayoutTabsEnum.complexes]: <ComplexesList />,
          [LayoutTabsEnum.apartments]: <ApartmentsList />,
          [LayoutTabsEnum.cottages]: <CottagesList />,
          [LayoutTabsEnum.cottage]: <CottageList />,
        }}
        currentActiveTab={currentActiveTab}
      />
    </>
  );
};

export const FavoritesPage = observer(FavoritesPageRenderer);
