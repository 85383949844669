import React, { useEffect, FC } from 'react';
import { observer } from 'mobx-react';
import Buildings from 'stores/Buildings';
import { BaseMapContainer } from 'components/atoms';
import LeafletStore from 'stores/LeafletStore';
import { useId } from 'react-id-generator';
import { YandexMapLayer } from 'components/atoms/Map/YandexMapLayer';
import { SetMapInstanceToStore } from './SetMapInstanceToStore';
import { infoStore } from '../../../../stores';
import { useUpdateMapCenter, useInitMapCenter } from './useMapCenter';
import { MarketTooltip } from './components/MarketTooltip';
import L from 'leaflet';
import { useMediaQuery } from "beautiful-react-hooks";

interface MainPageMapRenderProps {
  columns?: number;
}

const MainPageMapRender: FC<MainPageMapRenderProps> = ({ columns }) => {
  const isMobile = useMediaQuery('(max-width: 767px)');
  useInitMapCenter({
    infoStore,
    leafletStore: LeafletStore,
  });

  useUpdateMapCenter({
    infoStore,
    leafletStore: LeafletStore,
  });

  const [zoom, setZoom] = React.useState(isMobile ? 6 : 7);
  const { complexes } = Buildings;
  const {
    center,
    instance: mapInstance,
    setSuppressMoveHandler,
    displayedPointsType,
  } = LeafletStore;

  const idList: string[] = useId(complexes.data?.pins.length || 0, 'complexes_pins');

  useEffect(() => {
    if (!mapInstance || !mapInstance._loaded) return;
    if (isMobile) return;

    if (columns === 3) {
      mapInstance.panBy([-1, 0], { animate: false });
    } else if (columns === 2) {
      mapInstance.panBy([1, 0], { animate: false });
    }
  }, [columns, mapInstance]);

  useEffect(() => {
    if (!mapInstance || !mapInstance._loaded) return;

    if (displayedPointsType === 'developer' && complexes?.data?.pins) {
      // Фильтруем только валидные координаты
      const validPins = complexes.data.pins.filter(
        pin => !isNaN(parseFloat(pin.latitude)) && !isNaN(parseFloat(pin.longitude))
      );

      if (validPins.length === 0) {
        return;
      }

      // Создаём границы на основе отфильтрованных точек
      const bounds = L.latLngBounds(
        validPins.map(pin => [parseFloat(pin.latitude), parseFloat(pin.longitude)])
      );

      // Обновляем центр карты
      mapInstance.fitBounds(bounds, { padding: [50, 50] });
    }
  }, [complexes.data?.pins, mapInstance, displayedPointsType, setSuppressMoveHandler]);

  useEffect(() => LeafletStore.clear, []);

  return (
    <BaseMapContainer
      scrollWheelZoom
      center={center}
      zoom={zoom}
      mapInstanceForZoom={mapInstance}
    >
      <YandexMapLayer />
      {complexes.data?.pins?.map((pin, index) => (
        <MarketTooltip key={idList[index]} pin={pin} />
      ))}
      <SetMapInstanceToStore leafletStore={LeafletStore} />
    </BaseMapContainer>
  );
};

export const MainPageMap = observer(MainPageMapRender);

