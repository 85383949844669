import React from 'react';
import { useTheme } from "../../../context/theme/useTheme";

const CeilingsIcon = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.272 20.9992L8.72678 20.9992L3.81828 20.9998C3.71083 20.9998 3.60443 20.9782 3.50515 20.936C3.40587 20.8939 3.31566 20.8321 3.23968 20.7542C3.16369 20.6763 3.10341 20.5838 3.06229 20.482C3.02117 20.3802 3 20.2711 3 20.161V10.4657C3 10.3488 3.02382 10.2333 3.06993 10.1264C3.11604 10.0195 3.18342 9.92368 3.26776 9.84505L11.449 2.2182C11.5996 2.07781 11.7959 2.00001 11.9994 2C12.203 1.99999 12.3992 2.07778 12.5498 2.21817L20.7322 9.84505C20.8166 9.92367 20.8839 10.0195 20.9301 10.1264C20.9762 10.2333 21 10.3489 21 10.4657V20.161C21 20.2711 20.9788 20.3802 20.9377 20.482C20.8966 20.5838 20.8363 20.6763 20.7603 20.7542C20.6843 20.8321 20.5941 20.8939 20.4949 20.936C20.3956 20.9782 20.2892 20.9998 20.1817 20.9998L15.272 20.9992Z"
            stroke="#585B68" strokeWidth="2" strokeLinecap="round" stroke-linejoin="round"/>
          <path d="M9.5 10.5L12 8M12 8L14.5 10.5M12 8V17M12 17L9.5 14.5M12 17L14.5 14.5" stroke="#585B68"
                strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
      :
      (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M15.272 20.9992L8.72678 20.9992L3.81828 20.9998C3.71083 20.9998 3.60443 20.9782 3.50515 20.936C3.40587 20.8939 3.31566 20.8321 3.23968 20.7542C3.16369 20.6763 3.10341 20.5838 3.06229 20.482C3.02117 20.3802 3 20.2711 3 20.161V10.4657C3 10.3488 3.02382 10.2333 3.06993 10.1264C3.11604 10.0195 3.18342 9.92368 3.26776 9.84505L11.449 2.2182C11.5996 2.07781 11.7959 2.00001 11.9994 2C12.203 1.99999 12.3992 2.07778 12.5498 2.21817L20.7322 9.84505C20.8166 9.92367 20.8839 10.0195 20.9301 10.1264C20.9762 10.2333 21 10.3489 21 10.4657V20.161C21 20.2711 20.9788 20.3802 20.9377 20.482C20.8966 20.5838 20.8363 20.6763 20.7603 20.7542C20.6843 20.8321 20.5941 20.8939 20.4949 20.936C20.3956 20.9782 20.2892 20.9998 20.1817 20.9998L15.272 20.9992Z"
            stroke="#AFB4CB" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
          <path d="M9.5 10.5L12 8M12 8L14.5 10.5M12 8V17M12 17L9.5 14.5M12 17L14.5 14.5" stroke="#AFB4CB"
                strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
      )
  )
    ;
};

export default CeilingsIcon;
