import { AxiosRequestConfig } from 'axios';
import { API_ROUTES, unsetted } from 'services/constants';
import { MapBounds } from 'types/MapBounds';
import qs from 'qs';

import {
  RequestPageInfo,
  ResponsePageInfo,
  ThrowableResult,
} from 'services/types';
import { makeRequest } from 'services/libs/axios/makeRequest';
import { loggerService } from 'services/loggerService';
import {
  ShortBuildingObjectDto,
  ShortBuildingObject,
  SearchRawData,
  SearchData,
  SearchParameters,
} from './types';
import {makeObservable, observable} from "mobx";

type Params = {
  page: number;
  limit: number;
  mapBounds: MapBounds;
  sort: string;
  sortDirection: string;
  search: string;
  catalogIds: number[];
};

export class SearchService {

  isLoading = false;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
    });
  }

  async search(
    filters: Partial<
      SearchParameters & Omit<RequestPageInfo, 'page' | 'limit'>
    >,
    mapBounds?: MapBounds,
    pageInfo?: RequestPageInfo,
    isType?: boolean
  ): Promise<
    ThrowableResult<Omit<ResponsePageInfo<SearchData>, 'filterData'>>
  > {
    this.isLoading = true;

    if (isType) {
      if (filters.villageIds) {
        filters.type = 'village';
      } else if (filters.complexIds) {
        filters.type = 'rc';
      }
    }

    try {
      const params: Partial<Params> = {
        ...pageInfo,
        mapBounds,
      };

      const requestedObject: AxiosRequestConfig = {
        url: API_ROUTES.search,
        method: 'get',
        params: {
          ...params,
          ...filters,
        },
        paramsSerializer: (params) => qs.stringify(params),
      };

      const { data } = await makeRequest<ResponsePageInfo<SearchRawData>>(
        requestedObject,
      );

      return {
        ...data,
        data: {
          pins: data?.data?.pins || [],
          list: data?.data?.list?.map(this.mapShortDtoToPlain) || [],
        },
      };
    } catch (e) {
      loggerService.error(e);
      throw e;
    } finally {
      this.isLoading = false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  mapShortDtoToPlain(dto: ShortBuildingObjectDto): ShortBuildingObject {
    return {
      id: dto.id,
      address: dto.address || unsetted,
      title: dto.title || unsetted,
      developer: dto?.developer ? dto?.developer?.title : '',
      latitude: Number(dto.latitude),
      longitude: Number(dto.longitude),
      logo: dto.logo ? dto.logo.url : '',
      photo: dto.photo ? dto.photo.logo : '',
      badges: dto.catalogs.residential_complex_badges ?? [],
      stats: dto.stats ?? {},
      type: dto.type || unsetted,
    };
  }
}

export const searchService = new SearchService();
