import React, { ComponentProps, ReactNode } from 'react';
import cc from 'classcat';
import ReactModal from 'react-modal';
import { MobilePopupHeader } from './components/MobilePopupHeader';
import { useTheme } from "../../../../context/theme/useTheme";
import styles from './styles.module.css';

type Props = {
  isOpen: boolean;
  children?: ReactNode;
  className?: string;
  isClose?: boolean;
} & ComponentProps<typeof MobilePopupHeader>;

export const MobilePopup = ({
  children,
  headerChildren,
  IconBack,
  onClose,
  isOpen,
  className,
  isClose = false,
}: Props) => {
  const {theme} = useTheme();

  return (
    <ReactModal
      ariaHideApp={!isOpen}
      isOpen={isOpen}
      onRequestClose={onClose}
      overlayClassName={cc([className, styles.overlay, theme])}
      className={styles.content}
    >
      <MobilePopupHeader
        headerChildren={headerChildren}
        onClose={onClose}
        IconBack={IconBack}
        isClose={isClose}
      />
      <div className={styles.scrollbarWrapper}>{children}</div>
    </ReactModal>
  )
};
