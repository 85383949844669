import React from 'react';
import { Complex } from 'services/v1/rc/view/types';
import { Block } from 'pages/ComplexPage/components/Block';
import { ComplexAddressView } from 'components/atoms';

import { useMediaQuery } from 'beautiful-react-hooks';
import { ComplexInfoHeader } from './components/ComplexInfoHeader';
import { ComplexInfoAddress } from './components/ComplexInfoAddress';

import styles from './styles.module.css';
import { FavoritesAddButton } from '../../../../components/containers/FavoritesAddButton';

type Props = {
  complex: Complex;
  polygon?: [number, number][];
  isVillage?: boolean;
};

export const ComplexInfo = ({ complex, polygon, isVillage = false }: Props) => {
  const { addressDetail, id } = complex;
  const isLarge = useMediaQuery('(max-width: 1280px)');

  return (
    <Block className={styles.complexInfoWrapper}>
      <ComplexInfoHeader complex={complex} />
      <div className={styles.complexInfoAddress}>
        <div className={styles.actionButtons}>
          {
            isVillage ? (
              <FavoritesAddButton villageId={id} showLabels={!isLarge} />
            ) : (
              <FavoritesAddButton complexID={id} showLabels={!isLarge} />
            )
          }
        </div>
        <div className={styles.complexAddress}>
          {addressDetail && Array.isArray(addressDetail) && (
            <ComplexAddressView address={addressDetail} />
          )}
        </div>
        <ComplexInfoAddress complex={complex} polygon={polygon} />
      </div>
    </Block>
  );
};
