import React, { ComponentProps, memo } from 'react';
import { observer } from 'mobx-react';
import deepEqual from 'fast-deep-equal';
import { ComplexInfo } from '../../components/ComplexInfo';
import { HeaderRight } from '../../components/HeaderRight';
import { refreshStore } from '../../stores/RefreshStore/RefreshStore';
import { complexItemStore } from '../../../../stores/ComplexItemStore/store';
import style from './styles.module.css';
import { ComplexInfoSkeleton } from '../../components/ComplexInfoSkeleton';
import {villageStore} from "../../../../stores";
import cc from "classcat";
import ArrowLeftIcon from "../../../../components/ui/ArrowLeftIcon";
import arrowLeft from "../../../../assets/darkArrowLeft.svg";
import styles from "../../components/HeaderRight/styles.module.css";
import { BaseLink } from "../../../../components/atoms";
import { useParams } from "react-router-dom";
import { Params } from "../../../../services";
import useWindowSize from "../../../../hooks/useWindowSize";

type Props = {
  headerProps: ComponentProps<typeof HeaderRight>;
  isVillage?: boolean;
  currentActiveTabNew?: number;
};

export const LayoutPageHeaderDesktopRender = ({ headerProps, isVillage = false }: Props) => {
  const { target: complexData, requestApi } = complexItemStore;
  const { width } = useWindowSize();
  const { complexId } = useParams<Pick<Params, 'complexId'>>();

  return (
    <div className={cc([style.layoutPageHeaderDesktopWrapper, isVillage && style.layoutPageHeaderDesktopWrapperVillage])}>
      <header className={style.layoutPageHeaderDesktopHeader}>
        <div className={style.layoutPageHeaderDesktopLeftContent}>
          {
            width && width > 769 && (
              <BaseLink
                path="view"
                params={{
                  complexId,
                }}
                icon={<ArrowLeftIcon />}
                className={styles.headerRightButton}
              >
              </BaseLink>
            )
          }
          {requestApi.isLoading && <ComplexInfoSkeleton />}
          {!requestApi.isLoading && (
            <ComplexInfo
              onRefresh={refreshStore.call}
              updated_at={isVillage ? villageStore?.villageDetails?.updated_at : complexData?.updated_at}
              logo={isVillage ? (villageStore?.villageDetails?.logo || null) : (complexData?.logo || null)}
              title={isVillage ? (villageStore?.villageDetails?.title || '') : (complexData?.title || '')}
              address={isVillage ? (villageStore?.villageDetails?.address || null) : (complexData?.address || null)}
              isVillage={isVillage}
            />
          )}
        </div>

        <div className={style.layoutPageHeaderDesktopRightContent}>
          <HeaderRight {...headerProps} isVillage={isVillage} currentActiveTabNew={headerProps?.currentActiveTabNew} />
        </div>
      </header>
    </div>
  );
};

export const LayoutPageHeaderDesktop = memo(
  observer(LayoutPageHeaderDesktopRender),
  deepEqual,
);
