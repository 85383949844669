import React from 'react';

type Props = {
  isActive: boolean;
}

const ResidentialComplexesIcon = ({isActive}: Props) => {
  return (
    isActive ? (
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.8335 7.16732H14.6668V14.1673H2.3335V1.83398H9.3335V6.66732V7.16732H9.8335ZM8.50016 7.16732H9.00016V6.66732V5.33398V4.83398H8.50016H6.50016H6.00016V5.33398V6.66732V7.16732H6.50016H8.50016ZM6.50016 7.50065H6.00016V8.00065V9.33398V9.83398H6.50016H8.50016H9.00016V9.33398V8.00065V7.50065H8.50016H6.50016ZM5.16683 7.16732H5.66683V6.66732V5.33398V4.83398H5.16683H3.16683H2.66683V5.33398V6.66732V7.16732H3.16683H5.16683ZM3.16683 7.50065H2.66683V8.00065V9.33398V9.83398H3.16683H5.16683H5.66683V9.33398V8.00065V7.50065H5.16683H3.16683ZM3.16683 10.1673H2.66683V10.6673V13.334V13.834H3.16683H8.50016H9.00016V13.334V10.6673V10.1673H8.50016H3.16683ZM9.3335 13.334V13.834H9.8335H11.1668H11.6668V13.334V11.1673H12.0002V13.334V13.834H12.5002H13.8335H14.3335V13.334V8.00065V7.50065H13.8335H9.8335H9.3335V8.00065V13.334ZM2.66683 4.00065V4.50065H3.16683H5.16683H5.66683V4.00065V2.66732V2.16732H5.16683H3.16683H2.66683V2.66732V4.00065ZM6.50016 2.16732H6.00016V2.66732V4.00065V4.50065H6.50016H8.50016H9.00016V4.00065V2.66732V2.16732H8.50016H6.50016Z"
          fill="#4E6AFF" stroke="#4E6AFF"/>
      </svg>
    ) : (
      <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.83301 7.16732H14.6663V14.1673H2.33301V1.83398H9.33301V6.66732V7.16732H9.83301ZM8.49967 7.16732H8.99967V6.66732V5.33398V4.83398H8.49967H6.49967H5.99967V5.33398V6.66732V7.16732H6.49967H8.49967ZM6.49967 7.50065H5.99967V8.00065V9.33398V9.83398H6.49967H8.49967H8.99967V9.33398V8.00065V7.50065H8.49967H6.49967ZM5.16634 7.16732H5.66634V6.66732V5.33398V4.83398H5.16634H3.16634H2.66634V5.33398V6.66732V7.16732H3.16634H5.16634ZM3.16634 7.50065H2.66634V8.00065V9.33398V9.83398H3.16634H5.16634H5.66634V9.33398V8.00065V7.50065H5.16634H3.16634ZM3.16634 10.1673H2.66634V10.6673V13.334V13.834H3.16634H8.49967H8.99967V13.334V10.6673V10.1673H8.49967H3.16634ZM9.33301 13.334V13.834H9.83301H11.1663H11.6663V13.334V11.1673H11.9997V13.334V13.834H12.4997H13.833H14.333V13.334V8.00065V7.50065H13.833H9.83301H9.33301V8.00065V13.334ZM2.66634 4.00065V4.50065H3.16634H5.16634H5.66634V4.00065V2.66732V2.16732H5.16634H3.16634H2.66634V2.66732V4.00065ZM6.49967 2.16732H5.99967V2.66732V4.00065V4.50065H6.49967H8.49967H8.99967V4.00065V2.66732V2.16732H8.49967H6.49967Z"
          fill="#333338" stroke="#333338"/>
      </svg>
    )
  );
};

export default ResidentialComplexesIcon;
