import React, {FC, useState} from 'react';
import obSaleItem from '../../assets/on-sale-item.svg';
import reservationIcon from '../../assets/reservation-icon.svg';
import soldIcon from '../../assets/sold-icon.svg';
import addedIcon from 'assets/addedIcon.svg';
import removedIcon from 'assets/removedIcon.svg';
import cc from "classcat";
import { Cottage } from '../../../../../../services/v1/villageService/types';
import { observer } from 'mobx-react';
import { infoStore } from 'stores';
import { DictionaryKeys } from 'services';
import { OffersStatusAndAddButton } from "../../../../../../components/containers/OffersStatusAndAddButton";
import { FavoritesAddButton } from "../../../../../../components/containers/FavoritesAddButton";
import { CottagePlan } from "../CottageView/CottagePlan/CottagePlan";
import useWindowSize from "../../../../../../hooks/useWindowSize";
import styles from './TableRow.module.scss';
import { Alert } from 'components/atoms/Alert';
import {rootProfileStore} from "../../../../../../stores/user/RootProfileStore";
import ReservedIcon from "../../../../../../components/ui/ReservedIcon";
import OnSaleIcon from "../../../../../../components/ui/OnSaleIcon";

interface TableRowProps {
  data: Cottage;
  onRowClick: (cottage: Cottage) => void;
  isSelected?: boolean;
}

export const TableRow: FC<TableRowProps> = observer(({ data, onRowClick, isSelected }) => {
  const { width } = useWindowSize();
  const [alertMessage, setAlertMessage] = useState('');
  const [alertIcon, setAlertIcon] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);

  // Функция для форматирования чисел (добавляет пробелы между тысячами)
  const formatValue = (value: number | string): string => {
    const strValue = String(value);
    return strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  const handleFavoriteToggle = async () => {
    const { includeCottage } = rootProfileStore.favoritesIdsStore;
    const isFavorite = includeCottage(data.id);

    if (isFavorite) {
      setAlertMessage('Удалено из избранного');
      setAlertIcon(removedIcon);
    } else {
      setAlertMessage('Добавлено в избранное');
      setAlertIcon(addedIcon);
    }

    setShowAlert(true);
    setTimeout(() => setShowAlert(false), 1500);
  };

  // Функция для получения значений из словарей
  const getDictionaryValues = (dictionaryKey: DictionaryKeys, ids: number[] | undefined): string => {
    if (!ids || ids.length === 0) return '-';
    const dict = infoStore.dictionaries[dictionaryKey];
    if (!dict || !dict.items) return '-';
    const values = ids.map(id => {
      const item = dict.items.find(item => item.id === id);
      return item ? item.value : '-';
    });
    return values.join(', ');
  };

  // Получение статуса квартиры
  const statusId = data?.catalogs?.apartment_status?.[0];
  const status = statusId
    ? getDictionaryValues('apartment_status', [statusId])
    : '-';

  const statusClass =
    status === 'В продаже' || status === 'Свободно'
      ? styles.available
      : status === 'Бронь' || status === 'Резерв'
        ? styles.reserved
        : styles.sold;

  // Получение значений из словарей для других полей
  const villageTypeHouseValues = getDictionaryValues('village_type_house', data?.catalogs?.village_type_house);
  const villageMaterialValues = getDictionaryValues('village_material', data?.catalogs?.village_material);
  const residentialComplexFinishingValues = getDictionaryValues('residential_complex_finishing', data?.catalogs?.residential_complex_finishing);

  // Desktop-версия
  if (width && width > 768) {
    return (
      <>
        <div className={cc([styles.tableRow, statusClass, isSelected && styles.selected])}
             onClick={() => onRowClick(data)}>
          <div className={cc([styles.tableCell, styles.tableCellStatus])}>
            {(status === 'В продаже' || status === 'Свободно') && <OnSaleIcon />}
            {(status === 'Бронь' || status === 'Резерв') && <ReservedIcon />}
            {status === 'Продана' && <img src={soldIcon} alt='Icon'/>}
            <span>{status}</span>
          </div>
          {/*<div className={styles.tableCell}>*/}
          {/*  {data.layouts != null && data.layouts[0]?.photo ? (*/}
          {/*    <img className={styles.tableCellImg} src={data.layouts[0].photo} alt=""/>*/}
          {/*  ) : (*/}
          {/*    <span>-</span>*/}
          {/*  )}*/}
          {/*</div>*/}
          <div className={styles.tableCell}>
            {data?.layouts && data?.layouts?.length > 0 ? (
              <div
                className={styles.logo}
                onClick={(event) => event.stopPropagation()} // Остановка всплытия
              >
                <CottagePlan apartment={data} isLevelStepper={false} className={styles?.tableCellPlan}/>
              </div>
            ) : (
              <span>-</span>
            )}
          </div>
          <div className={styles.tableCell}>{data?.number ?? '-'}</div>
          <div className={styles.tableCell}>{data?.number_floors ?? '-'}</div>
          <div className={styles.tableCell}>{data?.number_bedrooms ?? '-'}</div>
          <div className={styles.tableCell}>{villageTypeHouseValues}</div>
          <div className={styles.tableCell}>{data?.square_land ? `${data?.square_land} сот` : '-'}</div>
          <div className={styles.tableCell}>{data?.square ? `${data?.square} м²` : '-'}</div>
          <div className={styles.tableCell}>{data?.price_m2 ? `${formatValue(data?.price_m2)} ₽` : '-'}</div>
          <div className={cc([styles.tableCell, styles.tableCellPrice])}>
            {data?.price ? `${formatValue(data?.price)} ₽` : '-'}
          </div>
          <div className={styles.tableCell}>{villageMaterialValues}</div>
          <div className={styles.tableCell}>{residentialComplexFinishingValues}</div>
          <div className={styles.actionButtons} onClick={(event) => event.stopPropagation()}>
            {/*<OffersStatusAndAddButton apartmentId={data?.id}/>*/}
            <FavoritesAddButton cottageId={data?.id} onClick={handleFavoriteToggle}/>
          </div>
        </div>

        {showAlert && (
          <div className={styles.alertWrapper}>
            <Alert isShow={true} severity={'standard'} icon={alertIcon ? alertIcon : ''}>
              {alertMessage}
            </Alert>
          </div>
        )}
      </>
    );
  }

  // Мобильная версия (width <= 768)
  return (
    <div
      className={cc([styles.mobileRow, statusClass, isSelected && styles.selected])}
      onClick={() => onRowClick(data)}
    >
      <div className={cc([styles.mobileRowInner, statusClass])}>
        {/* Вертикальная цветная полоса слева */}
        <div className={styles.mobileLeftBar}/>

        <div className={styles.mobileContent}>
          {/* Шапка с номером и отделкой (например, 2 Предчистовая) */}
          <div className={styles.mobileHeader}>
            <div className={styles.mobileNumber}>
              №{data?.number ?? <span className={styles.mobileNothing}>-</span>}
            </div>
          </div>

          <div className={styles.mobileFinishing}>
            {data?.number_floors ? <span className={styles.mobileFinishingFloors}>{data.number_floors}</span> :
              <span className={styles.mobileNothing}>-</span>} {residentialComplexFinishingValues}
          </div>

          {/* Секции со спальнями и площадью */}
          <div className={styles.mobileBedrooms}>
            {data?.number_bedrooms
              ? `${data.number_bedrooms} спальни, ${data.square ?? <span className={styles.mobileNothing}>-</span>} м²`
              : <span className={styles.mobileNothing}>-</span>}
          </div>

          {/* Материал */}
          <div className={styles.mobileMaterial}>
            {villageMaterialValues && villageMaterialValues !== '-' ? villageMaterialValues :
              <span className={styles.mobileNothing}>-</span>}
          </div>
        </div>

        {/* Блок с ценами и типом (правый столбец) */}
        <div className={styles.mobilePrices}>
          <div className={styles.mobileType}>
            {villageTypeHouseValues}
          </div>
          <div className={styles.mobilePriceM2}>
            {data?.price_m2 ? `${formatValue(data.price_m2)} ₽/м²` : <span className={styles.mobileNothing}>-</span>}
          </div>
          <div className={styles.mobileFullPrice}>
            {data?.price ? `${formatValue(data.price)} ₽` : <span className={styles.mobileNothing}>-</span>}
          </div>
          <div>
            <span className={styles.mobileNothing}>-</span>
          </div>
        </div>
      </div>
    </div>
  );
});

