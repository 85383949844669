import React, {useState} from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react';
import { ChoicerUserCity } from 'components/containers/ChoicerUserCity';
import { appRoutesService } from 'services';
import eselloLogoLight from 'assets/eselloLogoLight.svg';
import eselloLogoDark from 'assets/eselloLogoDark.svg';
import { FeatureToggle } from 'components/atoms';
import { NavbarRightContent } from './components/NavbarRightContent';
import { useTheme } from "../../../../../context/theme/useTheme";
import {
  CitySelectionModal
} from "../../../../../components/containers/ChoicerUserCity/components/CitySelectionModal/CitySelectionModal";
import styles from './styles.module.css';

export const NavbarDesktopRender = () => {
  const { theme, toggleTheme } = useTheme();
  const [isCityModalOpen, setCityModalOpen] = useState(false);

  return (
    <header className={styles.desktopWrapper}>
      <div className={styles.leftContent}>
        <Link to={appRoutesService.getRoute('main')} className={styles.logo}>
          <img src={theme === 'dark-theme' ? eselloLogoDark : eselloLogoLight} alt="site logo"/>
        </Link>

        <div onClick={() => setCityModalOpen(true)}>
          <ChoicerUserCity/>
        </div>
        {/*<FeatureToggle hideForProduction>*/}
        {/*  <NavList className={styles.navListWrapper}/>*/}
        {/*</FeatureToggle>*/}
      </div>

      <div className={styles.rightContent}>
        <NavbarRightContent/>
      </div>

      <CitySelectionModal isOpen={isCityModalOpen} onClose={() => setCityModalOpen(false)} />
    </header>
  )
};

export const NavbarDesktop = observer(NavbarDesktopRender);
