import React, { memo } from 'react';
import { complexFilterStore } from 'stores/ComplexFilterStore';
import { infoStore } from 'stores/InfoStore';
import { requestApiInstance } from 'stores/request/RequestApiStore';
import { ReactiveSelect } from './ReactiveSelect';
import { ReactiveInput } from '../components/ReactiveInput';
import styles from '../../AllFilters/AllFilters.module.scss';
import { ContextValueVillageFilters } from 'context';
import { ReactiveButtonGroupSelect } from "../../../atoms/ReactiveButtonGroupSelect/ReactiveButtonGroupSelect";
import { PopupDistrictsChoicer } from "../../PopupDistrictsChoicer";
import { ReactiveButtonsGroup } from "./ReactiveButtonsGroup";
import { DistrictsChoicerModal } from "../../DistrictsChoicerModal";
import {filter, flow, sortBy} from "lodash/fp";
import {District} from "../../../../services";

type Props = {
  isShowFromVillage?: boolean,
  store?: { filters: ContextValueVillageFilters['villageDataFilter'] },
  handleChangeStore?:ContextValueVillageFilters["handleChangeVillageFilter"],
  complexId?: string
}

export const MainVillageFilters = memo(({isShowFromVillage, store, handleChangeStore, complexId}: Props) => {
  const { districts } = infoStore;
  const { isLoading } = requestApiInstance;

  const districtsNew = flow(
    filter(({ type, rc_count }) => type === 'район' && rc_count > 0),
    sortBy(({ title }: District) => title),
  )(districts);

  return (
    <>
      {/*<div className={styles.formSingleItem}>*/}
      {/*  <span className={styles.formLabel}>Район</span>*/}
      {/*  <PopupDistrictsChoicer*/}
      {/*    data={districts || []}*/}
      {/*    filterKey="districtIds"*/}
      {/*    store={complexFilterStore.filters}*/}
      {/*    disabled={isLoading || isShowFromVillage}*/}
      {/*    placeholder="Любой"*/}
      {/*  />*/}
      {/*</div>*/}

      {
        districtsNew && districtsNew?.length > 0 && (
          <div className={styles.formSingleItem}>
            <span className={styles.formLabel}>Район</span>
            {districts && (
              <DistrictsChoicerModal
                data={districts || []}
                filterKey="districtIds"
                store={complexFilterStore.filters}
                disabled={isLoading || isShowFromVillage}
                placeholder="Любой"
                usePortal={false}
              />
            )}
          </div>
        )
      }

      <span className={styles.formLabel}>Стоимость</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="priceMin"
          placeholder=""
          prefixText="от"
          suffixIcon="₽"
          isFormatValue={true}
          clearable={true}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="priceMax"
          placeholder=""
          prefixText="до"
          suffixIcon="₽"
          isFormatValue={true}
          clearable={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип участка</span>
        <ReactiveButtonGroupSelect
          store={complexFilterStore.filters}
          filterKey="villageTypeLand"
          dictKey="village_type_land"
          placeholder="Не важно"
          disabled={isShowFromVillage}
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>ВРИ</span>
        <ReactiveSelect
          store={complexFilterStore.filters}
          filterKey="villageVRI"
          dictKey="village_vri"
          disabled={isShowFromVillage}
          placeholder='Любой'
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Тип дома</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="villageTypeHouse"
          dictKey="village_type_house"
          onChangeVillageTypeHouseContext={handleChangeStore}
          multiselect={true}
        />
      </div>

      <span className={styles.formLabel}>Расстояние до города</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="distanceToCityMin"
          placeholder=""
          prefixText="от"
          suffixIcon='км.'
          className={styles.complexInputLeft}
          disabled={isShowFromVillage}
          clearable={true}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="distanceToCityMax"
          placeholder=""
          prefixText="до"
          suffixIcon='км.'
          className={styles.complexInputRight}
          disabled={isShowFromVillage}
          clearable={true}
        />
      </div>

      <span className={styles.formLabel}>Дата обновления КП</span>
      <div className={styles.formSingleItem}>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="publishPeriod"
          placeholder="Любой"
          onChangeVillageTypeHouseContext={handleChangeStore}
          // values={{day: '24 часа', week: 'За неделю', month: 'За месяц'}}
          buttons={[
            {label: '24 часа', value: 'day', id: 1},
            {label: 'За неделю', value: 'week', id: 2},
            {label: 'За месяц', value: 'month', id: 3},
          ]}
          multiselect={false}
        />
      </div>

      <span className={styles.formLabel}>Агентское вознаграждение, %</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="agentCommissionPercentMin"
          placeholder=""
          prefixText="от"
          suffixIcon='₽'
          className={styles.complexInputLeft}
          disabled={isShowFromVillage}
          isFormatValue={true}
          clearable={true}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="agentCommissionPercentMax"
          placeholder=""
          prefixText="до"
          suffixIcon='₽'
          className={styles.complexInputRight}
          disabled={isShowFromVillage}
          isFormatValue={true}
          clearable={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Статус продаж</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="salesStatus"
          dictKey="residential_complex_sales_status"
          placeholder="Не важно"
          // onChangeVillageTypeHouseContext={handleChangeStore}
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Статус строительства</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          filterKey="constructionPhase"
          dictKey="construction_phase_status"
          onChangeVillageTypeHouseContext={handleChangeStore}
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Способ оплаты</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="paymentType"
          dictKey="residential_complex_payment_type"
          multiselect={true}
        />
      </div>

      <span className={styles.formLabel}>Год постройки</span>
      <div className={styles.formInputs}>
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="constructionYearMin"
          placeholder=""
          prefixText="от"
          suffixIcon=""
          minValue={2000}
          maxValue={2100}
          className={styles.complexInputLeft}
          disabled={isShowFromVillage}
          clearable={true}
        />
        <ReactiveInput
          store={complexFilterStore.filters}
          filterKey="constructionYearMax"
          placeholder=""
          prefixText="до"
          suffixIcon=""
          minValue={2000}
          maxValue={2100}
          className={styles.complexInputRight}
          disabled={isShowFromVillage}
          clearable={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Водоснабжение</span>
        <ReactiveButtonGroupSelect
          store={complexFilterStore.filters}
          filterKey="villageWaterSupply"
          dictKey="village_water_supply"
          placeholder="Любой"
          disabled={isShowFromVillage}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Отопление</span>
        <ReactiveButtonGroupSelect
          store={complexFilterStore.filters}
          filterKey="villageHeating"
          dictKey="village_heating"
          disabled={isShowFromVillage}
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Электричество</span>
        <ReactiveButtonGroupSelect
          store={complexFilterStore.filters}
          filterKey="villageElectricity"
          dictKey="village_electricity"
          placeholder="Любой"
          disabled={isShowFromVillage}
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Канализация</span>
        <ReactiveButtonGroupSelect
          store={complexFilterStore.filters}
          filterKey="villageCanalization"
          dictKey="village_canalization"
          disabled={isShowFromVillage}
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Преимущества</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageAdvantages"
          dictKey="village_advantages"
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Этажность</span>
        <ReactiveButtonGroupSelect
          buttons={[
            {label: '1 этаж', value: '1', id: 1},
            {label: '2 этажа', value: '2', id: 2},
            {label: '3+ этажа', value: '3+', id: 3},
          ]}
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageFloor"
          multiselect={true}
        />
      </div>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Количество спален</span>
        <ReactiveButtonGroupSelect
          buttons={[
            {label: "1", value: '1', id: 1},
            {label: "2", value: '2', id: 2},
            {label: "3", value: '3', id: 3},
            {label: "4+", value: '4+', id: 4}
          ]}
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageBedrooms"

        />
      </div>
      <div>
        <span className={styles.formLabel}>Площадь дома (м²)</span>
        <div className={styles.formInputs}>
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="squareMin"
            placeholder=""
            prefixText="от"
            suffixIcon="м²"
            clearable={true}
          />
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="squareMax"
            placeholder=""
            prefixText="до"
            suffixIcon="м²"
            clearable={true}
          />
        </div>
      </div>
      <div>
        <span className={styles.formLabel}>Площадь участка (Сотка)</span>
        <div className={styles.formInputs}>
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="squareLandMin"
            placeholder=""
            prefixText="от"
            suffixIcon="Сотка"
            clearable={true}
          />
          <ReactiveInput
            store={complexFilterStore.filters}
            filterKey="squareLandMax"
            placeholder=""
            prefixText="до"
            suffixIcon="Сотка"
            clearable={true}
          />
        </div>
      </div>
      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Материал здания</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey='villageMaterial'
          dictKey='village_material'
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Отделка</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey='villageFacade'
          dictKey='village_facade'
          multiselect={true}
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Гараж</span>
        <ReactiveSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="villageGarage"
          dictKey="village_garage"
        />
      </div>

      <div className={styles.formSingleItem}>
        <span className={styles.formLabel}>Количество машиномест</span>
        <ReactiveButtonGroupSelect
          store={store || complexFilterStore.filters}
          onChangeVillageTypeHouseContext={handleChangeStore}
          filterKey="parkingSpaces"
          buttons={[
            {label: "1", value: '1', id: 1},
            {label: "2", value: '2', id: 2},
            {label: "3", value: '3', id: 3},
            {label: "4", value: '4', id: 4},
            {label: "5+", value: '5+', id: 5}
          ]}
          multiselect={true}
        />
      </div>

      <div className={styles.formSubColumnWrapper}>
        <div className={styles.formSingleItem}>
          <span className={styles.formLabel}>Дворовая территория</span>
          <ReactiveButtonGroupSelect
            store={store || complexFilterStore.filters}
            onChangeVillageTypeHouseContext={handleChangeStore}
            filterKey="villageYardTerritory"
            dictKey="village_yard_territory"
            multiselect={true}
          />
        </div>
      </div>
    </>
  );
});
