import React from 'react';
import { useTheme } from "../../../context/theme/useTheme";

const BathroomsArea = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M20 4.83V13H22V19C22 20.1 21.1 21 20 21C20 21.55 19.55 22 19 22H5C4.45 22 4 21.55 4 21C2.9 21 2 20.1 2 19V13H5V12.25C5.00008 11.868 5.0974 11.4924 5.28278 11.1585C5.46816 10.8245 5.7355 10.5433 6.05962 10.3412C6.38374 10.1391 6.75397 10.0229 7.13542 10.0034C7.51688 9.98396 7.89701 10.0619 8.24 10.23C8.5 10.35 8.74 10.52 8.93 10.73L10.33 12.28C10.58 12.55 10.85 12.79 11.15 13H18V4.83C18.0002 4.66657 17.952 4.50673 17.8616 4.37061C17.7711 4.2345 17.6424 4.12821 17.4916 4.06512C17.3408 4.00203 17.1748 3.98498 17.0143 4.0161C16.8539 4.04722 16.7062 4.12512 16.59 4.24L15.34 5.49C15.38 5.66 15.41 5.82 15.41 6C15.41 6.4 15.29 6.77 15.09 7.08L12.33 4.32C12.64 4.12 13.01 4 13.41 4C13.59 4 13.76 4.03 13.92 4.08L15.17 2.83C15.7 2.3 16.42 2 17.17 2C18.73 2 20 3.27 20 4.83ZM4 19H20V15H4V19ZM9 7C9 8.10457 8.10457 9 7 9C5.89543 9 5 8.10457 5 7C5 5.89543 5.89543 5 7 5C8.10457 5 9 5.89543 9 7Z"
                fill="#585B68"/>
        </svg>
      )
      :
      (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M20 4.83V13H22V19C22 20.1 21.1 21 20 21C20 21.55 19.55 22 19 22H5C4.45 22 4 21.55 4 21C2.9 21 2 20.1 2 19V13H5V12.25C5.00008 11.868 5.0974 11.4924 5.28278 11.1585C5.46816 10.8245 5.7355 10.5433 6.05962 10.3412C6.38374 10.1391 6.75397 10.0229 7.13542 10.0034C7.51688 9.98396 7.89701 10.0619 8.24 10.23C8.5 10.35 8.74 10.52 8.93 10.73L10.33 12.28C10.58 12.55 10.85 12.79 11.15 13H18V4.83C18.0002 4.66657 17.952 4.50673 17.8616 4.37061C17.7711 4.2345 17.6424 4.12821 17.4916 4.06512C17.3408 4.00203 17.1748 3.98498 17.0143 4.0161C16.8539 4.04722 16.7062 4.12512 16.59 4.24L15.34 5.49C15.38 5.66 15.41 5.82 15.41 6C15.41 6.4 15.29 6.77 15.09 7.08L12.33 4.32C12.64 4.12 13.01 4 13.41 4C13.59 4 13.76 4.03 13.92 4.08L15.17 2.83C15.7 2.3 16.42 2 17.17 2C18.73 2 20 3.27 20 4.83ZM4 19H20V15H4V19ZM9 7C9 8.10457 8.10457 9 7 9C5.89543 9 5 8.10457 5 7C5 5.89543 5.89543 5 7 5C8.10457 5 9 5.89543 9 7Z"
                fill="#AFB4CB"/>
        </svg>
      )
  );
};

export default BathroomsArea;
