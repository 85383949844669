import React, {useState, ReactNode, useCallback} from 'react';
import cc from 'classcat';
import Dropdown from 'rc-dropdown';
import 'rc-dropdown/assets/index.css';
import { Checkbox } from 'components/atoms/Checkbox';

import { TagButton } from '../TagButton';
import { useTheme } from "../../../context/theme/useTheme";
import useWindowSize from "../../../hooks/useWindowSize";
import {BottomSheet} from "../BottomSheet/BottomSheet";
import closeIcon from "../../../assets/closeIconLight.svg";
import closeIconDark from "../../../assets/closeIconDark.svg";
import styles from './SelectSingle.module.css';

/** @deprecated */
const DEFAULT_DROPDOWN_STATE = false;

/** @deprecated */
type Props = {
  items: Record<string, string>;
  value: Array<string | number> | null;
  placeholder: string;
  onChange: (newValue: Array<string | number> | null) => void;
  multiselect?: boolean;
  onlyInternal?: boolean;
  children?: any;
  disabled?: boolean;
  usePortal?: boolean;
};

type SelectIntervalOverlayProps = {
  items: Record<string, string>;
  multiselect?: boolean;
  handleChangeMultiselect: (value: string) => void;
  handleChangeSingle: (value: string) => void;
  value: Array<string | number> | null;
  disabled?: boolean;
};

export const SelectIntervalOverlay = ({
  items,
  multiselect,
  handleChangeSingle,
  handleChangeMultiselect,
  value,
  disabled,
}: SelectIntervalOverlayProps) => (
  <>
    {Object.entries(items)
      .sort(([aKey], [bKey]) => {
        if (aKey === '2982') return -1;
        if (bKey === '2982') return 1;

        if (aKey === '3050') return 1;
        if (bKey === '3050') return -1;
        return aKey.localeCompare(bKey);
      })
      .map(([itemValue, item]) => (
        <li className={styles.option} key={itemValue}>
          <Checkbox
            checked={Boolean(value?.includes(itemValue))}
            name={`${itemValue}_${item}`}
            onChange={() =>
              multiselect
                ? handleChangeMultiselect(itemValue)
                : handleChangeSingle(itemValue)
            }
            label={item}
            disabled={disabled}
          />
        </li>
      ))}
  </>
);

export const SelectSingle = ({
  onlyInternal = false,
  multiselect = false,
  items,
  value,
  placeholder,
  onChange,
  children,
  disabled,
  usePortal = true,
}: Props) => {
  const [dropdownVisible, toggleDropdownVisibility] = useState(
    DEFAULT_DROPDOWN_STATE,
  );
  const [isOpen, setOpen] = useState(false);

  const { theme } = useTheme();
  const { width } = useWindowSize();


  const handleChangeMultiselect = (newValue: string) => {
    if (value === null) {
      onChange([newValue]);
      return;
    }

    if (value.includes(newValue) && value.length === 1) {
      onChange(null);
      return;
    }

    if (value.includes(newValue)) {
      onChange(value.filter((i) => i !== newValue));
      return;
    }

    onChange([...value, newValue]);
  };

  const handleChangeSingle = (newValue: string) => {
    if (value === null) {
      onChange([newValue]);
      return;
    }

    if (value.includes(newValue)) {
      onChange(null);
      return;
    }

    onChange([newValue]);
  };

  const handleSortVisibility = useCallback(() => {
    setOpen(!isOpen);
  }, [isOpen]);

  const displayingValue: ReactNode = value && value.length > 0 ? (
    value
      .map((id) => (
        <TagButton
          key={items[id]}
          type="button"
          isSelected
          disabled={disabled}
          className="selectedButton"
          onDelete={(e) => {
            e.stopPropagation();
            multiselect
                ? handleChangeMultiselect(String(id))
                : handleChangeSingle(String(id))
           }}>
            {items[id]}
        </TagButton>
      ))
  ) : (
    <span className={styles.placeholder}>{placeholder}</span>
  );

  if (onlyInternal) {
    return (
      <SelectIntervalOverlay
        items={items}
        multiselect={multiselect}
        handleChangeMultiselect={handleChangeMultiselect}
        handleChangeSingle={handleChangeSingle}
        value={value}
        disabled={disabled}
      />
    );
  }

  const handleVisibilityChange = (value: boolean) => {
    if (!disabled) {
      toggleDropdownVisibility(value);
    }
  };

  return (
    width && width < 769 ? (
      <div>
        <div className={disabled ? styles.disabledContainer : ''} onClick={handleSortVisibility}>
          {children}
          {!children && (
            <div
              onClick={handleSortVisibility}
              className={cc([
                styles.root,
                {
                  [styles.disabled]: disabled,
                  [styles.withTags]: value,
                  [styles.isOpen]: dropdownVisible,
                },
              ])}
            >
              {displayingValue}
            </div>
          )}
        </div>
        <BottomSheet
          isOpen={isOpen}
          onClose={() => setOpen(false)}
          withBackdrop={true}
        >
          <div className={styles.bottomSheetHeader}>
            <h2>Выберите вид</h2>
            <button className={styles.closeBtn} onClick={() => setOpen(false)}>
              <img src={theme === 'dark-theme' ? closeIcon : closeIconDark} alt="close"/>
            </button>
          </div>
          <ul className={styles.dropdownOverlay}>
            <SelectIntervalOverlay
              items={items}
              multiselect={multiselect}
              handleChangeMultiselect={handleChangeMultiselect}
              handleChangeSingle={handleChangeSingle}
              value={value}
            />
          </ul>
        </BottomSheet>
      </div>
    ) : (
      <Dropdown
        trigger={['click']}
        overlayClassName={cc([styles.overlayWrapper, theme])}
        onVisibleChange={handleVisibilityChange}
        visible={dropdownVisible}
        overlay={
          <ul className={styles.dropdownOverlay}>
            <SelectIntervalOverlay
              items={items}
              multiselect={multiselect}
              handleChangeMultiselect={handleChangeMultiselect}
              handleChangeSingle={handleChangeSingle}
              value={value}
            />
          </ul>
        }
        animation="slide-up"
        getPopupContainer={
          usePortal
            ? undefined
            : (triggerNode) => triggerNode.parentNode as HTMLElement
        }
        // align={{ offset: [0, -1] }}
      >
        <div className={disabled ? styles.disabledContainer : ''}>
          {children}
          {!children && (
            <div
              className={cc([
                styles.root,
                {
                  [styles.disabled]: disabled,
                  [styles.withTags]: value,
                  [styles.isOpen]: dropdownVisible,
                },
              ])}
            >
              {displayingValue}
            </div>
          )}
        </div>
      </Dropdown>
    )
  );
};
