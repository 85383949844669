import React from 'react';
import { useTheme } from "../../../context/theme/useTheme";

const LivingArea = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 5V10H9V5H5V13H9V12H10V17H9V14H5V19H12V17H13V19H19V17H21V21H3V3H21V15H19V10H13V15H12V9H19V5H10Z"
                fill="#585B68"/>
        </svg>
      )
      :
      (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M10 5V10H9V5H5V13H9V12H10V17H9V14H5V19H12V17H13V19H19V17H21V21H3V3H21V15H19V10H13V15H12V9H19V5H10Z"
                fill="#AFB4CB"/>
        </svg>
      )
  );
};

export default LivingArea;
