import React, { MouseEvent, useEffect } from 'react';
import { Link } from 'react-router-dom';
import cc from 'classcat';
import { useId } from 'react-id-generator';
import { observer } from 'mobx-react';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { ResidentialVillageCard } from './ResidentialVillageCard';
import { appRoutesService } from 'services';
import { EmptyFavourites } from "../EmptyFavourites/EmptyFavourites";
import styles from './styles.module.css';

export const CottagesListRenderer = () => {
  const {
    state: { villages },
    loadFavoritesVillages,
    setVillages,
  } = rootProfileStore.favoritesStore;

  const { favoritesIds } = rootProfileStore.favoritesComposeAuth;
  const { villagesIds } = favoritesIds;

  const idList: string[] = useId(villages.length, 'villages');

  const toggleCottageComplexFavorites = (id: number) => async (evt: MouseEvent) => {
    evt.preventDefault();
    await rootProfileStore.favoritesComposeAuth.toggleVillage(id);

    if (!favoritesIds.villagesIds.length) {
      setVillages([]);
      return;
    }
    // Иначе перезагрузим
    await loadFavoritesVillages(favoritesIds.villagesIds);
  };

  useEffect(() => {
    if (!villagesIds.length) {
      setVillages([]);
      return;
    }
    void loadFavoritesVillages(villagesIds);
  }, [villagesIds, loadFavoritesVillages, setVillages]);

  return (
    <main
      className={cc([
        styles.main,
        {
          [styles.noDataFavorites]: !villages.length,
        },
      ])}
    >
      {!villages?.length && (
        <EmptyFavourites
          title="В избранном пока нет коттеджных поселков"
          description="Нажимайте на сердечко, чтобы объекты оказались на этой странице"
        />
      )}

      {villages?.length > 0 && (
        <ul className={styles.list}>
          {villages?.map((village, index) => (
            <li key={idList[index]} className={styles.listItem}>
              <Link
                to={{
                  pathname: appRoutesService.replaceRoute('view', {
                    complexId: village.id?.toString(),
                  }),
                }}
                className={styles.card}
              >
                <ResidentialVillageCard
                  buildingObject={village}
                  toggleComplexFavorites={toggleCottageComplexFavorites}
                  imageClassName={styles.imageFavorites}
                />
              </Link>
            </li>
          ))}
        </ul>
      )}
    </main>
  );
};

export const CottagesList = observer(CottagesListRenderer);
