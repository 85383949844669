import React from 'react';
import { complexFilterStore } from 'stores';
import { observer } from 'mobx-react';
import { ButtonBase } from '../../../../../pages/LayoutPage/components/ButtonBase';
import closeIconDark from 'assets/closeIconDark.svg';
import closeIconLight from 'assets/closeIconLight.svg';
// import { TabButton, TabList } from '../../../Tabs';
import { TOpenType } from 'types/filters';
import { useTheme } from "../../../../../context/theme/useTheme";
import {
  TabList
} from "../../../../../pages/LayoutPage/pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabList";
import {
  TabButton
} from "../../../../../pages/LayoutPage/pages/GeneralPlanVillage/components/CottageView/CottagePlanPopup/TabButton";
import GenplanIcon from "../../../../ui/GenplanIcon";
import GenplanIconMobile from "../../../../ui/GenplanIconMobile";
import ResidentialComplexesIcon from "../../../../ui/ResidentialComplexesIcon";
import CottageVillagesIcon from "../../../../ui/CottageVillagesIcon";
import styles from './styles.module.css';

const tabs = [
  {
    label: 'Жилые комплексы',
  },
  {
    label: 'Коттеджные поселки',
  },
];

type Props = {
  onClose: () => void;
  openType?: TOpenType;
};

export const FilterHeader = observer(({ onClose, openType = 'main' }: Props) => {
  const { currentFilterTab, setFilterTab } = complexFilterStore.filters;
  const { theme } = useTheme();

  return (
    <div>
      <div className={styles.filtersHeader}>
        <h2 className={styles.title}>Фильтры</h2>

        <ButtonBase
          onClick={onClose}
          className={styles.closeButton}
        >
          <img src={theme === 'dark-theme' ? closeIconLight : closeIconDark} alt="close"/>
        </ButtonBase>
      </div>

      <div className={styles.filtersHeaderBottom}>
        {openType === 'main' && <TabList className={styles.tabListMain} currentActiveTab={currentFilterTab} tabCount={2}>
          <TabButton
            isActive={currentFilterTab === 0}
            onClick={() => setFilterTab(0)}
            key={'Жилые комплексы'}
            label={'Жилые комплексы'}
            tabIcon={<ResidentialComplexesIcon isActive={currentFilterTab === 0} />}
          />
          <TabButton
            isActive={currentFilterTab === 1}
            onClick={() => setFilterTab(1)}
            key={'Коттеджные поселки'}
            label={'Коттеджные поселки'}
            tabIcon={<CottageVillagesIcon isActive={currentFilterTab === 1} />}
          />
        </TabList>}
      </div>
    </div>
  );
});
