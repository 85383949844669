import React from 'react';
import { flow, get } from 'lodash/fp';
import styles from './CottageSquareInfo.module.css';
import {
  DictionaryView,
  NumberFormat,
} from '../../../../../../../components/atoms';
import { DictionaryKeys } from '../../../../../../../services';
import { Cottage } from "../../../../../../../services/v1/villageService/types";
import TotalArea from "../../../../../../../components/ui/cottageCardInfo/TotalArea";
import BathroomsArea from "../../../../../../../components/ui/cottageCardInfo/BathroomsArea";
import LivingArea from "../../../../../../../components/ui/cottageCardInfo/LivingArea";
import KitchenArea from "../../../../../../../components/ui/cottageCardInfo/KitchenArea";
import CeilingsIcon from "../../../../../../../components/ui/cottageCardInfo/CeilingsIcon";
import ContractType from "../../../../../../../components/ui/cottageCardInfo/ContractType";

type Props = {
  apartment?: Cottage;
};
export const CottageSquareInfo = ({ apartment }: Props) => {
  const residential_complex_contract_type = flow<
    any,
    Record<DictionaryKeys, number[]>,
    number[]
  >(
    get('catalogs'),
    get('residential_complex_contract_type'),
  )(apartment);

  return (
    <div className={styles.squareListWrapper}>
      <div>
        <div className={styles.squareItemContent}>
          {/*<NumberFormat value={apartment?.square} format="meter2" />*/}
          <div className={styles.squareItemWrapper}>
            <TotalArea />
          </div>
          <div className={styles.squareTextBlock}>
            <div className={styles.squareItemLabel}>Общая</div>
            <span>{parseInt(apartment?.square || '0', 10)} м²</span>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.squareItemContent}>
          {/*<NumberFormat value={apartment?.square_bathroom} format="meter2" />*/}
          <div className={styles.squareItemWrapper}>
            <BathroomsArea />
          </div>
          <div className={styles.squareTextBlock}>
            <div className={styles.squareItemLabel}>Санузла</div>
            <span>{parseInt(apartment?.square_bathroom || '0', 10)} м²</span>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.squareItemContent}>
          {/*<NumberFormat value={apartment?.square_living} format="meter2" />*/}
          <div className={styles.squareItemWrapper}>
            <LivingArea />
          </div>
          <div className={styles.squareTextBlock}>
            <div className={styles.squareItemLabel}>Жилая</div>
            <span>{parseInt(apartment?.square_living || '0', 10)} м²</span>
          </div>
        </div>
      </div>
      <div>
        <div className={styles.squareItemContent}>
          {/*<NumberFormat value={apartment?.square_kitchen} format="meter2" />*/}
          <div className={styles.squareItemWrapper}>
            <KitchenArea />
          </div>
          <div className={styles.squareTextBlock}>
            <div className={styles.squareItemLabel}>Кухни</div>
            <span>{parseInt(apartment?.square_kitchen || '0', 10)} м²</span>
          </div>
        </div>
      </div>

      {
        apartment?.ceiling_height && (
          <div>
            <div className={styles.squareItemContent}>
              <div className={styles.squareItemWrapper}>
                <CeilingsIcon/>
              </div>
              <div className={styles.squareTextBlock}>
                <div className={styles.squareItemLabel}>Потолки</div>
                <span>{apartment?.ceiling_height}м</span>
              </div>
            </div>
          </div>
        )
      }

      {
        residential_complex_contract_type && (
          <div>
            <div className={styles.squareItemContent}>
              {/*<NumberFormat value={apartment?.square_kitchen} format="meter2" />*/}
              <div className={styles.squareItemWrapper}>
                <ContractType/>
              </div>
              <div className={styles.squareTextBlock}>
                <div className={styles.squareItemLabel}>Тип договора</div>
                <span>
              <DictionaryView
                dictKey="residential_complex_contract_type"
                dictItemId={residential_complex_contract_type}
              />
            </span>
              </div>
            </div>
          </div>
        )
      }
      {/*{apartment?.number_floors && (*/}
      {/*  <div>*/}
      {/*    <div className={styles.squareItemLabel}>Этаж</div>*/}
      {/*    <div className={styles.squareItemContent}>*/}
      {/*      <DictionaryView*/}
      {/*        dictKey="residential_complex_contract_type"*/}
      {/*        dictItemId={residential_complex_contract_type}*/}
      {/*      />*/}
      {/*      <span>{apartment?.number_floors}</span>*/}
      {/*    </div>*/}

      {/*    <div>*/}

      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </div>
  );
};
