import React, { useMemo } from 'react';
import { flow, get, isNil } from 'lodash/fp';
import cc from 'classcat';
import {AlbumList, ErrorBoundary, OverlayZoomButton} from 'components/atoms';
import {
  ApartmentDto,
  BuildingEntity,
  Complex,
  Feature,
  findBuildingByIdInComplex, Params,
} from 'services';
import { Header } from '../Header';
import { RenderBuildingToMap } from '../RenderBuildingToMap';
import { ShowApartmentOnFloorLayout } from '../ShowApartmentOnFloorLayout';
import { getWindowsFromLayers, reversePolygonCoords } from '../../../../utils';
import { useBuildingOnMapPopup } from '../../../../containers/BuildingOnMapPopup';

import styles from './styles.module.css';
import { dataTestIdToProps } from '../../../../../../utils/test/dataTestIdToProps';
import { rootApartmentStore } from '../../../../../../stores/Apartment/RootApartmentStore';
import {CottageHeader} from "../../../GeneralPlanVillage/components/CottageView/CottageHeader/CottageHeader";
import {Reward} from "../../../GeneralPlanVillage/components/CottageView/Reward/Reward";
import {villageStore} from "../../../../../../stores";
import {ContactCard} from "../../../GeneralPlanVillage/components/CottageView/ContactCard/ContactCard";
import {complexItemStore} from "../../../../../../stores/ComplexItemStore/store";
import {ContactCardApartment} from "../ContactCardApartment/ContactCardApartment";
import {TextDescription} from "../../../GeneralPlanVillage/components/CottageView/TextDescription/TextDescription";
import {useParams} from "react-router-dom";

type Props = {
  apartment?: ApartmentDto;
  complex: Complex | null;
};

const ApartmentViewContentTestIDs = {
  wrapper: 'ApartmentViewContent_wrapper',
};

export const ApartmentViewContent = ({ apartment, complex }: Props) => {
  const { apartmentUIStore } = rootApartmentStore;
  const { complexId } =
    useParams<Pick<Params, 'complexId' | 'generalPlanId'>>();

  const isNotShowFloorPlan = flow(
    get('layouts'),
    isNil,
  )(apartmentUIStore.activeApartment);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const building: BuildingEntity | undefined = findBuildingByIdInComplex(
    apartment?.building_id?.toString(),
  )(complex);

  const windows: Feature[] | undefined = useMemo(
    () => getWindowsFromLayers(apartment?.layouts),
    [apartment?.layouts],
  );

  const reversedPolygon = useMemo(
    () => reversePolygonCoords(building?.polygon),
    [building?.polygon],
  );

  const { showBuildingOnMapPopup } = useBuildingOnMapPopup({
    windows,
    polygon: reversedPolygon,
  });

  const {
    target: complexData,
  } = complexItemStore;

  return (
    <>
      <div
        {...dataTestIdToProps(ApartmentViewContentTestIDs.wrapper)}
        className={cc([
          styles.wrapper,
          {
            [styles.wrapperIsNotFloorPlan]: isNotShowFloorPlan,
          },
        ])}
      >
        {/* @todo: PromotionsHeader */}
        {/*<Header apartment={apartment} />*/}
        <CottageHeader apartment={apartment} isCottage={false} />

        {
          apartment?.agent_commission_sum && <Reward count={apartment?.agent_commission_sum} percent={apartment?.agent_commission_percent ? apartment?.agent_commission_percent : '' } />
        }

        {/* @todo: Map */}
        {!isNotShowFloorPlan && reversedPolygon && windows && (
          <div className={styles.mapWrapper}>
            <ErrorBoundary>
              <OverlayZoomButton
                className={styles.complexMap}
                onClick={showBuildingOnMapPopup}
              >
                <RenderBuildingToMap
                  hideZoomControl
                  className={styles.complexMap}
                  polygon={reversedPolygon}
                  windows={windows}
                />
              </OverlayZoomButton>
            </ErrorBoundary>
          </div>
        )}

        {
          complexData && complexData?.salesDepartments && (
            <ContactCardApartment />
          )
        }

        {
          apartment?.albums && apartment?.albums?.length > 0 && complexId && (
            <div className={styles.complexAlbums}>
              <AlbumList albums={apartment?.albums} complexId={String(apartment?.id)} isCottage={true} />
            </div>
          )
        }

        {
          apartment?.description && (
            <TextDescription description={apartment?.description ? apartment?.description : ''} />
          )
        }
      </div>

      {!isNotShowFloorPlan && (
        <div className={styles.floorPlanWrapper}>
          <ErrorBoundary>
            <ShowApartmentOnFloorLayout
              hideZoomControl
              apartment={apartmentUIStore.activeApartment}
            />
          </ErrorBoundary>
        </div>
      )}
    </>
  );
};
