import React, { useEffect } from 'react';
import { useParams } from "react-router-dom";
import { observer } from "mobx-react";
import {apartmentsService, ComplexAlbum, Params} from "../../services";
import { feedbackStore } from "../../stores/FeedbackStore/FeedbackStore";
import { complexItemStore } from "../../stores/ComplexItemStore/store";
import { useInitApartmentSidebar } from "../LayoutPage/hooks/useInitApartmentSidebar";
import { rootApartmentStore } from "../../stores/Apartment/RootApartmentStore";
import styles from './styles.module.scss';
import qs from "qs";
import { BusinessCard } from "./components/BusinessCard/BusinessCard";
import { ResidentialComplex } from "./components/ResidentialComplex/ResidentialComplex";
import { ApartmentInfo } from "./components/ApartmentInfo/ApartmentInfo";
import { ApartmentDetails } from "./components/ApartmentDetails/ApartmentDetails";
import { villageStore } from "../../stores";
import { ComplexDescription } from "./components/ComplexDescription/ComplexDescription";
import { AdvantagesList } from "./components/AdvantagesList/AdvantagesList";
import { DescriptionGrid } from "./components/DescriptionGrid/DescriptionGrid";
import { MapSection } from "./components/MapSection/MapSection";
import { PhotosSection } from "./components/PhotosSection/PhotosSection";

interface Props {
  className?: string;
}

export const PdfPageCottageComponent: React.FC<Props> = ({ className }) => {
  const { complexId } = useParams<Pick<Params, 'complexId'>>();
  const queryParams = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const objectNameQP = queryParams.objectName === 'true';
  const developerNameQP = queryParams.developerName === 'true';
  const objectAddressQP = queryParams.objectAddress === 'true';
  const badgesQP = queryParams.badges === 'true';
  const descriptionQP = queryParams.description === 'true';
  const advantagesComplexQP = queryParams.advantagesComplex === 'true';
  const advantagesCottageQP = queryParams.advantagesCottage === 'true';
  const photosQp = queryParams.photos === 'true';
  const constructionProgressQP = queryParams.constructionProgress === 'true';
  const locationAndViewQP = queryParams.locationAndView === 'true';
  const priceQP = queryParams.price === 'true';
  const layoutQP = queryParams.layout === 'true';
  const agentContactQP = queryParams.agentContact === 'true';

  const {
    isVisible,
    target: complexData,
    toggleVisibility,
    fetchComplex,
    clearTarget,
  } = complexItemStore;

  useEffect(() => {
    if (!complexData || complexData?.id !== Number(complexId)) {
      fetchComplex(Number(complexId));
      toggleVisibility(true);
      feedbackStore.setInitialValues({
        type: 'complex',
        recordId: complexId,
      });
    }
  }, [complexId, fetchComplex, toggleVisibility, complexData]);

  useInitApartmentSidebar({ apartmentsService });
  const { apartmentUIStore } = rootApartmentStore;

  useEffect(() => {
    if (apartmentUIStore?.activeApartment?.id) {
      villageStore.fetchCottageDetails(String(apartmentUIStore?.activeApartment?.id));
    }
  }, [apartmentUIStore?.activeApartment?.id]);

  const { cottageDetails } = villageStore;

  // Получаем изображения
  const images = [];
  // Если есть фотографии планировки в apartmentData.layouts, добавляем их
  if (cottageDetails && cottageDetails?.layouts) {
    cottageDetails.layouts.forEach((layout: any) => {
      if (layout?.photo) {
        images.push(layout?.photo);
      }
    });
  } else {
    // Если нет изображений, добавляем заглушку
    images.push('');
  }

  // Извлекаем первые 5 фотографий из presentation
  const presentationPhotos = cottageDetails?.presentation?.slice(0, 5) || [];

  // Распределяем фото по группам
  const photos = [
    presentationPhotos[0] ? [presentationPhotos[0]?.url] : [],
    presentationPhotos[1] && presentationPhotos[2]
      ? [presentationPhotos[1]?.url, presentationPhotos[2]?.url]
      : presentationPhotos[1] ? [presentationPhotos[1]?.url] : [],
    presentationPhotos[3] ? [presentationPhotos[3]?.url] : [],
    presentationPhotos[4] ? [presentationPhotos[4]?.url] : [],
  ];

  const constructionAlbums = complexData?.albums?.filter(
    (album) => album.name === "Ход строительства"
  );

  return (
    <div className={`${styles.wrapper} ${className}`}>
      {
        agentContactQP && <BusinessCard />
      }
      {
        complexData &&
        <ResidentialComplex
          objectNameQP={objectNameQP}
          complexData={complexData}
          developerNameQP={developerNameQP}
        />
      }
      {
        cottageDetails && <ApartmentInfo apartmentData={cottageDetails} />
      }
      {
        cottageDetails && complexData && (
          <ApartmentDetails
            images={images}
            apartmentData={cottageDetails}
            complexData={complexData}
            objectNameQP={objectNameQP}
            objectAddressQP={objectAddressQP}
            badgesQP={badgesQP}
            priceQP={priceQP}
            layoutQP={layoutQP}
          />
        )
      }
      {
        complexData && descriptionQP && <ComplexDescription complexData={complexData} objectNameQP={objectNameQP} />
      }
      {
        complexData && advantagesComplexQP && <AdvantagesList title='Преимущество поселка' ComplexData={complexData} />
      }
      {
        complexData && <DescriptionGrid cottageData={cottageDetails} />
      }
      {
        cottageDetails && advantagesCottageQP && <AdvantagesList title='Преимущество объекта' apartmentData={cottageDetails} />
      }
      {
        complexData && cottageDetails && locationAndViewQP && (
          <MapSection complexData={complexData} apartmentData={cottageDetails} />
        )
      }
      {
        photosQp && presentationPhotos && presentationPhotos?.length > 0 && (
          <PhotosSection
            title="Фотографии"
            photos={photos}
          />
        )
      }
      {
        constructionProgressQP && constructionAlbums && constructionAlbums.length > 0 && (
          <PhotosSection
            title="Ход строительства"
            date={(() => {
              if (!constructionAlbums || constructionAlbums.length === 0) {
                return null; // Если альбомов нет, возвращаем null
              }

              // Находим самый последний альбом по дате
              const latestAlbum = constructionAlbums.reduce((latest, current) => {
                const latestDate = new Date(latest?.dateAt ? latest?.dateAt : '');
                const currentDate = new Date(current?.dateAt ? current?.dateAt : '');

                return currentDate > latestDate ? current : latest;
              });

              // Форматируем дату
              const latestDate = new Date(latestAlbum?.dateAt ? latestAlbum?.dateAt : '');
              const formattedDate = latestDate.toLocaleDateString("ru-RU", {
                month: "long",
                year: "numeric",
              });

              return formattedDate; // Возвращаем отформатированную дату
            })()}
            photos={(() => {
              // Фильтруем альбомы с названием "Ход строительства"
              const constructionAlbums = complexData?.albums?.filter(
                (album) => album.name === "Ход строительства"
              );

              if (!constructionAlbums || constructionAlbums.length === 0) {
                return [];
              }

              // Сортируем альбомы по дате (самые новые вперед)
              constructionAlbums.sort((a, b) => new Date(b?.dateAt ? b?.dateAt : '').getTime() - new Date(a?.dateAt ? a?.dateAt : '').getTime());

              // Сохраняем фотографии из всех альбомов, начиная с последнего
              const constructionPhotos = constructionAlbums.reduce((photos: string[], album: ComplexAlbum) => {
                if (album?.photo?.url) {
                  photos.push(album?.photo?.url);
                }
                return photos;
              }, []);

              // Берем последние 5 фото
              const lastFivePhotos = constructionPhotos.reverse().slice(-5);

              // Форматируем для вывода в блоки
              return [
                lastFivePhotos[0] ? [lastFivePhotos[0]] : [],
                lastFivePhotos[1] && lastFivePhotos[2]
                  ? [lastFivePhotos[1], lastFivePhotos[2]]
                  : [],
                lastFivePhotos[3] ? [lastFivePhotos[3]] : [],
                lastFivePhotos[4] ? [lastFivePhotos[4]] : [],
              ];
            })()}
          />
        )
      }
    </div>
  );
};

export const PdfPageCottage = observer(PdfPageCottageComponent);