import React from 'react';
import { observer } from 'mobx-react';
import { infoStore, cityStore } from 'stores';
import {District, userProfileService} from "../../../../../services";
import { rootProfileStore } from "../../../../../stores/user/RootProfileStore";
import styles from './CityList.module.scss';
import { Icon } from "../../../../atoms";
import closeIcon from "../../../../../assets/closeIconLight.svg";
import closeIconDark from "../../../../../assets/closeIconDark.svg";
import { ButtonBase } from "../../../../../pages/LayoutPage/components/ButtonBase";
import { useTheme } from "../../../../../context/theme/useTheme";

type Props = {
  isOpen?: boolean;
  onClose: () => void;
};

// Группируем города по регионам
const groupCitiesByRegion = (cities) => {
  return cities.reduce((acc, city) => {
    const regionKey = `${city.region} ${city.region_type}`;
    if (!acc[regionKey]) {
      acc[regionKey] = [];
    }
    acc[regionKey].push(city);
    return acc;
  }, {});
};

export const CityList = observer(({ onClose }: Props) => {
  const {theme} = useTheme();
  // const handleCitySelect = async (cityId: number) => {
  //   const selectedCity = cityStore?.cities?.find(city => city.id === cityId);
  //
  //   if (selectedCity) {
  //     try {
  //       const { authUser } = rootProfileStore.authStore;
  //       const user = authUser?.user;
  //
  //       if (!user?.name || !user?.surname || !user?.role) {
  //         console.error("Ошибка: Не все обязательные поля заполнены.");
  //         return;
  //       }
  //
  //       await userProfileService.saveUserProfile({
  //         name: user?.name,
  //         surname: user?.surname,
  //         role: user?.role,
  //         agencyId: user?.agency?.id,
  //         cityId: selectedCity?.id,
  //       });
  //       infoStore.addCity(selectedCity);
  //       await infoStore.loadInfo();
  //     } catch (error) {
  //       console.error("Ошибка обновления города:", error);
  //     }
  //   }
  //   onClose();
  // };

  const handleCitySelect = async (cityId: number) => {
    const selectedCity = cityStore?.cities?.find(city => city.id === cityId);

    if (selectedCity && selectedCity?.id) {
      try {
        await userProfileService.saveCity(selectedCity?.id);
        infoStore.addCity(selectedCity);
        await infoStore.loadInfo();
      } catch (error) {
        console.error("Ошибка обновления города:", error);
      }
    }
    onClose();
  };

  // Группируем города по регионам
  const groupedCities = groupCitiesByRegion(cityStore?.cities || []);

  return (
    <div className={styles.modalContent}>
      <div className={styles.modalContentHeader}>
        <h2>Выберите регион или город</h2>
        <ButtonBase
          onClick={onClose}
          icon={<Icon src={theme === 'dark-theme' ? closeIcon : closeIconDark} className={styles.closeIcon}/>}
          className={styles.textClassName}
        />
      </div>
      <div className={styles.cityGrid}>
        {Object.entries(groupedCities).map(([region, cities]) => (
          <div key={region} className={styles.cityColumn}>
            <h3 className={styles.regionTitle}>{region}</h3>
            {cities?.map(city => (
              <div key={city.id} className={styles.cityItem} onClick={() => handleCitySelect(city.id)}>
                {city.name}
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
});
