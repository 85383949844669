import React from 'react';
import { RenderSuggestionsContainerParams } from 'react-autosuggest';
import SimpleBar from 'simplebar-react';
import search from '../../../assets/search.svg';
import styles from './styles.module.css';

export const RenderSuggestionsContainer = ({
  containerProps,
  children,
  query,
}: RenderSuggestionsContainerParams) => {
  const { ref, ...restContainerProps } = containerProps;
  const hasChildren = React.Children.count(children) > 0;
  const hasQuery = query.trim().length > 0;

  if (!hasChildren && hasQuery) {
    return (
      <div {...restContainerProps}>
        <div ref={ref} className={styles.noResultsWrapper}>
          <img className={styles.noResultsIcon} src={search} alt="" />
          <p className={styles.noResultsText}>Для вашего запроса нет результатов</p>
        </div>
      </div>
    );
  }

  if (!hasChildren) {
    return null;
  }

  return (
    <SimpleBar scrollableNodeProps={{ ref }} {...restContainerProps}>
      {children}
    </SimpleBar>
  );
};

