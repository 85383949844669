import React, { useMemo, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import cc from 'classcat';
import { flow, get } from 'lodash/fp';
import { observer } from 'mobx-react';

import {
  DictionaryBadge,
  DictionaryView,
  Icon,
  NumberFormat,
  Text,
  PlaceholderImage,
} from 'components/atoms';

import favouriteFilled from 'assets/favouriteFilled.svg';
import favouriteOutline from 'assets/favouriteOutline.svg';

import {
  ApartmentDto,
  appRoutesService,
  DictionaryKeys,
  getFirstLayoutPhoto,
} from 'services';
import { ButtonBase } from 'pages/LayoutPage/components/ButtonBase';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import styles from './styles.module.css';

type Props = {
  apartment: ApartmentDto;
  toggleApartment: (id: number) => (evt: MouseEvent) => Promise<void>;
};

const ApartmentsCardRenderer = ({ apartment, toggleApartment }: Props) => {
  const { includeApartment } = rootProfileStore.favoritesIdsStore;

  const residential_complex_contract_type = flow<
    any,
    Record<DictionaryKeys, number[]>,
    number[]
  >(
    get('catalogs'),
    get('residential_complex_contract_type'),
  )(apartment);

  const apartmentTypeRoomId = useMemo(
    () =>
      flow<any, Record<DictionaryKeys, number[]>, number[]>(
        get('catalogs'),
        get('apartment_type_room'),
      )(apartment),
    [apartment],
  );

  const apartment_status = flow<
    any,
    Record<DictionaryKeys, number[]>,
    number[]
  >(
    get('catalogs'),
    get('apartment_status'),
  )(apartment);

  const linkTo = useMemo(
    () =>
      appRoutesService.replaceRoute('layoutActiveApartment', {
        apartmentId: apartment?.id?.toString() || '',
        layoutTabs: 'grid',
        complexId: apartment?.residential_complex_id?.toString(),
        buildingId: apartment?.building_id?.toString(),
        apartmentNumber: apartment.number?.toString() || '',
      }),
    [
      apartment?.residential_complex_id,
      apartment?.building_id,
      apartment.number,
      apartment?.id,
    ],
  );

  const apartmentPreviewImage = getFirstLayoutPhoto(apartment);

  return (
    <Link to={linkTo} className={styles.card}>
      {apartmentPreviewImage && (
        <div className={styles.imageWrapper}>
          <img
            src={apartmentPreviewImage}
            className={styles.image}
            alt="фото квартиры"
          />
        </div>
      )}

      {!apartmentPreviewImage && (
        <PlaceholderImage className={styles.imageWrapper} type="FLOOR_PLAN" />
      )}

      <ButtonBase
        icon={
          <Icon
            src={cc({
              [favouriteOutline]:
                apartment.id && !includeApartment(apartment.id),
              [favouriteFilled]: apartment.id && includeApartment(apartment.id),
            })}
          />
        }
        onClick={apartment.id ? toggleApartment(apartment.id) : undefined}
        className={styles.apartmentsFavoritesImage}
      />

      <div className={styles.infoWrapper}>
        <div className={styles.mainApartmentInfo}>
          <div className={styles.left}>
            <DictionaryBadge
              badgeInner
              dictKey="apartment_status"
              dictItemId={apartment_status}
              className={styles.status}
            />

            <Text className={styles.flatName} weight="bold">
              <DictionaryView
                dictKey="apartment_type_room"
                dictItemId={apartmentTypeRoomId}
              />{' '}
              <NumberFormat value={apartment?.square} format="meter2" />
            </Text>

            {/*<Text className={styles.apartmentComplexAddress}>*/}
            {/*  {apartment.complex?.address}*/}
            {/*</Text>*/}

            {/*<Link*/}
            {/*  to={appRoutesService.replaceRoute('view', {*/}
            {/*    complexId: apartment.complex?.id.toString(),*/}
            {/*  })}*/}
            {/*  className={styles.apartmentComplexLink}*/}
            {/*>*/}
            {/*  {apartment.complex?.title}*/}
            {/*</Link>*/}
          </div>

          <div className={styles.right}>
            <NumberFormat
              value={apartment?.price_m2}
              format="rub_meter2"
              className={styles.priceForMeter}
            />
            <NumberFormat
              value={apartment?.price}
              format="rub"
              className={styles.apartmentPrice}
            />
          </div>
        </div>

        <ul className={styles.squareListWrapper}>
          <li>
            <div className={styles.squareItemLabel}>Общая S</div>
            <div className={styles.squareItemContent}>
              <NumberFormat value={apartment?.square} format="meter2" />
            </div>
          </li>
          <li>
            <div className={styles.squareItemLabel}>S санузла</div>
            <div className={styles.squareItemContent}>
              <NumberFormat
                value={apartment?.square_bathroom}
                format="meter2"
              />
            </div>
          </li>
          <li>
            <div className={styles.squareItemLabel}>Жилая S</div>
            <div className={styles.squareItemContent}>
              <NumberFormat value={apartment?.square_living} format="meter2" />
            </div>
          </li>
          <li>
            <div className={styles.squareItemLabel}>S кухни</div>
            <div className={styles.squareItemContent}>
              <NumberFormat value={apartment?.square_kitchen} format="meter2" />
            </div>
          </li>
          {residential_complex_contract_type && (
            <li>
              <div className={styles.squareItemLabel}>Тип договора</div>
              <div className={styles.squareItemContent}>
                <DictionaryView
                  dictKey="residential_complex_contract_type"
                  dictItemId={residential_complex_contract_type}
                />
              </div>
            </li>
          )}
        </ul>
      </div>
    </Link>
  );
};

export const ApartmentsCard = observer(ApartmentsCardRenderer);
