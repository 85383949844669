import React, { ReactNode, useCallback } from 'react';
import cc from 'classcat';
import { flow, sortBy, filter, map } from 'lodash/fp';
import { District } from 'services';
import { TagButton } from '../TagButton';
import mapPointIcon from 'assets/mapPointIcon.svg';

import styles from './styles.module.css'

type SelectInModalProps = {
  data: Array<District>;
  title: ReactNode;
  values: Array<number> | null;
  displayingValues: { title: string, id: number }[];
  onDisplayingValuesChange: React.Dispatch<React.SetStateAction<{ title: string, id: number }[]>>;
  onChange: (newValue: Array<number> | null) => void;
  onClose: () => void;
  clearFilter: () => void;
};

export const SelectInModal = ({
  data,
  title,
  values,
  displayingValues,
  onDisplayingValuesChange,
  onChange,
  onClose,
  clearFilter,
}: SelectInModalProps) => {

  const handleClick = useCallback(

    (id: number) => {

      if (!values) {
        values = [];
      }

      const valueToDisplay = data.find((el) => el.id === id);
      if (valueToDisplay) {
        // eslint-disable-next-line no-unused-expressions
        displayingValues.map(el => el.id).includes(valueToDisplay.id)
          ? onDisplayingValuesChange(
              displayingValues.filter((el) => el.id !== valueToDisplay.id),
            )
          : onDisplayingValuesChange([
              ...displayingValues,
              valueToDisplay,
            ]);
      }
      // eslint-disable-next-line no-unused-expressions
      values.includes(id)
        ? onChange(values.filter((el) => el !== id))
        : onChange([...values, id]);
    },
    [values],
  );

  const handleDeleteItem = useCallback(
    (id) => {
      if (values) {
        onChange(values.filter((el) => el !== id));
      }
    },
    [values],
  );

  const regions = flow(
    filter(({ type }) => type === 'округ'),
    sortBy(({ title }: District) => title),
  )(data);

  const districts = flow(
    filter(({ type, rc_count }) => type === 'район' && rc_count > 0),
    sortBy(({ title }: District) => title),
  )(data);

  const regionWithDistricts = map((region: District) => {
    const districtsWithRegion = districts
      .filter((district: District) => district.parent_id === region.id)
      .map((district: District) => ({
        ...district,
        parent_id: region.id, // чтобы районы имели правильный parent_id округа
      }));

    return [
      region,
      ...districtsWithRegion,
    ];
  })(regions);


  // const filteredData = flattenDeep(regionWithDistricts) as District[];
  const filteredData = regionWithDistricts.flat();

  return (
    <section>
      <div className={styles.modalSelectWrapper}>
        <ul className={styles.list}>
          {filteredData.map(({ id, title, parent_id, type }: District) => (
            <>
              {
                type === 'район' && (
                  <li key={id} className={cc([{[styles.regionBtn]: !parent_id}])}>
                    <TagButton
                      type="button"
                      onClick={() => handleClick(id)}
                      onDelete={() => handleDeleteItem(id)}
                      size={parent_id ? 'MD' : 'LG'}
                      // isSelected={values && values.includes(id)}
                      className={styles.regionListBtn}
                    >
                      <div className={styles.regionBtnContent}>
                        <img src={mapPointIcon} alt=''/>
                        <div className={styles.regionBtnContentText}>
                          <span className={styles.regionBtnContentTitle}>{title}</span>
                          <span className={styles.regionBtnContentSubtitle}>
                            {data.find((el) => el.id === parent_id)?.title_full}
                          </span>
                        </div>
                      </div>
                    </TagButton>
                  </li>
                )
              }
            </>
          ))}
        </ul>
      </div>
    </section>
  );
};
