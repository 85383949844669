import React from 'react';
import { toString, negate, isNil } from 'lodash/fp';
import { observer } from 'mobx-react';
import hideIcon from 'assets/hide.svg';
import {
  ApartmentsViewFilterValues,
  apartmentUIPropertyVisibilityFilter,
} from 'stores/Apartment/ApartmentsViewFilters/ApartmentsViewFilters';
import { UIPropertyVisibilityFilters } from 'stores';
import { Icon, SelectSingle } from 'components/atoms';
import { ButtonBase } from '../ButtonBase';
import styles from './styles.module.scss';

type Props = {
  viewFilter?: UIPropertyVisibilityFilters<ApartmentsViewFilterValues>;
};

// todo: заменить селект на поповер или еще какой компонент, подумать.
export const ApartmentViewFilterRender = ({ viewFilter }: Props) => {
  const currentValue = viewFilter?.options
    .map(({ active }, index) => (active ? index : null))
    .filter(negate(isNil))
    .map(toString);

  const items = Object.fromEntries(
    viewFilter?.options?.map(({ label }, index) => [index, label]) || [],
  );

  return (
    <>
      <SelectSingle
        placeholder=""
        items={items}
        multiselect
        onChange={(activeIndexes) => {
          viewFilter?.options.forEach((item, index) => {
            if (!activeIndexes?.includes(index.toString())) {
              if (item.active) {
                viewFilter?.setActive(item.value);
              }
              return;
            }
            if (!item.active) {
              viewFilter?.setActive(item.value);
            }
          });
        }}
        value={currentValue || null}
      >
        <ButtonBase
          icon={<Icon src={hideIcon} alt="grid view" />}
          className={styles.buttonViewFilter}
        >
          <div className={styles.buttonViewFilterInner}>
            <span>Вид</span>
            {
              currentValue && currentValue?.length > 0 && (
                <span className={styles.buttonViewFilterCount}>{currentValue?.length}</span>
              )
            }
          </div>
        </ButtonBase>
      </SelectSingle>
    </>
  );
};

ApartmentViewFilterRender.defaultProps = {
  viewFilter: apartmentUIPropertyVisibilityFilter,
};

export const ApartmentViewFilter = observer(ApartmentViewFilterRender);
