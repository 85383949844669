import React from 'react';
import { ApartmentDto } from 'services/v1/rc/apartment/types';
import { useInitApartmentSidebar } from 'pages/LayoutPage/hooks/useInitApartmentSidebar';
import { apartmentsService } from 'services';
import { observer } from 'mobx-react';
import { complexItemStore } from 'stores/ComplexItemStore/store';
import { ApartmentViewPopup } from './components/ApartmentViewPopup';
import { ApartmentViewContent } from './components/ApartmentViewContent';
import { rootApartmentStore } from '../../../../stores/Apartment/RootApartmentStore';
import {CottageViewPopup} from "../GeneralPlanVillage/components/CottageView/CottageViewPopup/CottageViewPopup";

type Props = {
  apartment?: ApartmentDto;
  onClose?: () => void;
  isOpen: boolean;
};

export const ApartmentViewRender = ({ apartment, onClose, isOpen }: Props) => {
  const { target: complexData } = complexItemStore;

  const { state } = rootApartmentStore.apartmentAccessStatusStore;

  useInitApartmentSidebar({ apartmentsService });

  if (!state.isAccessToView) {
    return null;
  }

  return (
    <CottageViewPopup
      number={apartment?.number}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ApartmentViewContent apartment={apartment} complex={complexData} />
    </CottageViewPopup>
  );
};

export const ApartmentView = observer(ApartmentViewRender);
