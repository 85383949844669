import React, { useMemo, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { flow, get } from 'lodash/fp';
import { observer } from 'mobx-react';
import {
  Text,
  DictionaryView,
  NumberFormat,
  DictionaryBadge,
  Alert,
} from 'components/atoms';
import {
  DictionaryKeys,
  getAllApartmentPlans,
  getApartmentPreview,
} from 'services';
import { MayBe } from 'types';
import { FavoritesAddButton } from 'components/containers/FavoritesAddButton';
import { OffersStatusAndAddButton } from 'components/containers/OffersStatusAndAddButton';
import { CottagePlan } from '../CottagePlan/CottagePlan';
import { CottageSquareInfo } from '../CottageSquareInfo/CottageSquareInfo';
import { Cottage } from "../../../../../../../services/v1/villageService/types";
import { PdfButton } from "../../../../../../../components/containers/PdfButton";
import LinkIcon from "../../../../../../../components/ui/LinkIcon";
import styles from './CottageHeader.module.scss';
import cc from "classcat";
import {rootProfileStore} from "../../../../../../../stores/user/RootProfileStore";
import removedIcon from "../../../../../../../assets/removedIcon.svg";
import addedIcon from "../../../../../../../assets/addedIcon.svg";
import AddCollectionIcon from "../../../../../../../components/ui/AddCollectionIcon";
import {TooltipInfo} from "../../../../../../../components/atoms/TooltipInfo";

type Props = {
  apartment?: Cottage;
  isCottage?: boolean;
};

export const CottageHeaderRenderer = ({ apartment, isCottage = true }: Props) => {
  const [alertMessage, setAlertMessage] = useState('');
  const [alertIcon, setAlertIcon] = useState<string | null>(null);
  const [showAlert, setShowAlert] = useState(false);
  const [currentLayoutIndex, setCurrentLayoutIndex] = useState(0);

  const [showTooltipOffers, setShowTooltipOffers] = useState(false);
  const [showTooltipLink, setShowTooltipLink] = useState(false);
  const [showTooltipMore, setShowTooltipMore] = useState(false);

  const apartmentVillageTypeRoomId = useMemo(
    () =>
      flow<any, Record<DictionaryKeys, number[]>, number[]>(
        get('catalogs'),
        get('village_type_house'),
      )(apartment),
    [apartment],
  );

  const apartmentTypeRoomId = useMemo(
    () =>
      flow<any, Record<DictionaryKeys, number[]>, number[]>(
        get('catalogs'),
        get('apartment_type_room'),
      )(apartment),
    [apartment],
  );

  const apartment_status = flow<any, Record<DictionaryKeys, number[]>, number[]>(
    get('catalogs'),
    get('apartment_status'),
  )(apartment);

  const apartmentPreview: MayBe<string> = useMemo(
    () => getApartmentPreview(apartment),
    [apartment],
  );
  const apartmentPlans: MayBe<string>[] = getAllApartmentPlans(apartment);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (showAlert) {
      timer = setTimeout(() => {
        setShowAlert(false);
      }, 2000); // Уведомление исчезнет через 2 секунды
    }
    return () => clearTimeout(timer); // Очистка таймера при размонтировании компонента
  }, [showAlert]);

  const handleFavoriteToggle = async () => {
    const { includeCottage } = rootProfileStore.favoritesIdsStore;
    const isFavorite = includeCottage(apartment?.id);

    if (isFavorite) {
      setAlertMessage('Удалено из избранного');
      setAlertIcon(removedIcon);
    } else {
      setAlertMessage('Добавлено в избранное');
      setAlertIcon(addedIcon);
    }

    setShowAlert(true);
  };

  const handleFavoriteApartmentToggle = async () => {
    const { includeApartment } = rootProfileStore.favoritesIdsStore;
    const isFavorite = includeApartment(apartment?.id);

    if (isFavorite) {
      setAlertMessage('Удалено из избранного');
      setAlertIcon(removedIcon);
    } else {
      setAlertMessage('Добавлено в избранное');
      setAlertIcon(addedIcon);
    }

    setShowAlert(true);
  };

  return (
    <header>
      <div className={styles.topWrapper}>
        {
          apartment?.layouts && apartment?.layouts.length > 0 && (
            <div className={styles.logo}>
              <CottagePlan apartment={apartment} />
            </div>
          )
        }

        <div className={styles.topRight}>
          <div className={styles.infoWrapper}>
            <div className={cc([styles.infoWrapperInner, apartment?.layouts && apartment?.layouts.length > 0 && styles.infoWrapperInnerMobile])}>
              {
                apartment?.layouts && apartment?.layouts.length > 0 && (
                  <DictionaryBadge
                    badgeInner
                    dictKey="apartment_status"
                    dictItemId={apartment_status}
                    className={styles.infoWrapperStatus}
                  />
                )
              }
              <div className={styles.infoWrapperInnerLeft}>
                <Text
                  align="left"
                  display="block"
                  className={styles.infoWrapperType}
                >

                  {
                    !isCottage ? (
                      <DictionaryView
                        dictKey="apartment_type_room"
                        dictItemId={apartmentTypeRoomId}
                      />
                    ) : (
                      <DictionaryView
                        dictKey="village_type_house"
                        dictItemId={apartmentVillageTypeRoomId}
                      />
                    )
                  }

                  {
                    !apartment?.layouts && (
                      <DictionaryBadge
                        badgeInner
                        dictKey="apartment_status"
                        dictItemId={apartment_status}
                        className={styles.infoWrapperStatus}
                      />
                    )
                  }
                </Text>
                <Text
                  className={styles.price_m2}
                  align="right"
                  lineHeight="XL"
                  size="XS"
                  display="block"
                  weight="bold"
                >
                  {
                    apartment?.price_m2 && <NumberFormat value={apartment?.price_m2} format="rub_meter2" />
                  }
                </Text>
                <Text className={styles.price} align="right" size="L" display="block" weight="bold">
                  {
                    apartment?.price && (
                      <NumberFormat value={apartment?.price} />
                    )
                  }
                </Text>
              </div>
            </div>
          </div>
        </div>
      </div>

      {apartment?.id && isCottage && (
        <div className={styles.actionButtonsWrapper}>
          {/*<OffersStatusAndAddButton apartmentId={apartment.id} className={styles.headerBtnIcon} headerMenu={true} />*/}
          <div className={styles.buttonBlock} onMouseEnter={() => setShowTooltipOffers(true)}
               onMouseLeave={() => setShowTooltipOffers(false)}>
            <button className={cc([styles.headerBtnIcon, styles.disabled])}>
              <AddCollectionIcon/>
            </button>
            {showTooltipOffers && (
              <TooltipInfo
                content="Скоро станет доступным, мы работаем над этим"
                width="138px"
                position='right'
              />
            )}
          </div>
          <FavoritesAddButton
            cottageId={apartment.id}
            className={styles.headerBtnIcon}
            headerMenu={true}
            onClick={handleFavoriteToggle}
          />
          <div className={styles.buttonBlock}
               onMouseEnter={() => setShowTooltipLink(true)}
               onMouseLeave={() => setShowTooltipLink(false)}
          >
            <button className={cc([styles.headerBtnIcon, styles.disabled])}><span><LinkIcon/></span></button>
            {showTooltipLink && (
              <TooltipInfo
                content="Скоро станет доступным, мы работаем над этим"
                width="138px"
                position='bottom'
              />
            )}
          </div>
          <PdfButton apartment={apartment} className={styles.headerBtnIcon}/>
          <div className={styles.buttonBlock} onMouseEnter={() => setShowTooltipMore(true)} onMouseLeave={() => setShowTooltipMore(false)}>
            <button className={cc([styles.btnReadMore, styles.disabled])}>Подробнее</button>
            {showTooltipMore && (
              <TooltipInfo
                content="Скоро станет доступным, мы работаем над этим"
                width="138px"
                position='bottom'
              />
            )}
          </div>
        </div>
      )}

      {apartment?.id && !isCottage && (
        <div className={styles.actionButtonsWrapper}>
          <OffersStatusAndAddButton
            apartmentId={apartment.id}
            className={styles.headerBtnIcon}
            headerMenu={true}
          />

          <FavoritesAddButton
            apartmentId={apartment.id}
            className={styles.headerBtnIcon}
            headerMenu={true}
            onClick={handleFavoriteApartmentToggle}
          />

          <div className={styles.buttonBlock}
               onMouseEnter={() => setShowTooltipLink(true)}
               onMouseLeave={() => setShowTooltipLink(false)}
          >
            <button className={cc([styles.headerBtnIcon, styles.disabled])}><span><LinkIcon/></span></button>
            {showTooltipLink && (
              <TooltipInfo
                content="Скоро станет доступным, мы работаем над этим"
                width="138px"
                position='bottom'
              />
            )}
          </div>
          <PdfButton apartment={apartment} className={styles.headerBtnIcon}/>
          <div className={styles.buttonBlock} onMouseEnter={() => setShowTooltipMore(true)}
               onMouseLeave={() => setShowTooltipMore(false)}>
            <button className={cc([styles.btnReadMore, styles.disabled])}>Подробнее</button>
            {showTooltipMore && (
              <TooltipInfo
                content="Скоро станет доступным, мы работаем над этим"
                width="138px"
                position='bottom'
              />
            )}
          </div>
        </div>
      )}

      <div className={styles.squareListWrapper}>
        <CottageSquareInfo apartment={apartment}/>
      </div>

      {showAlert && ReactDOM.createPortal(
        <div className={styles.alertWrapper}>
          <Alert isShow={true} severity={'standard'} icon={alertIcon || ''}>
            {alertMessage}
          </Alert>
        </div>,
        document.body
      )}
    </header>
  );
};

export const CottageHeader = observer(CottageHeaderRenderer);