import { action, makeObservable, observable } from 'mobx';

export class GridApartmentsStylesStore {
  FLOOR_SIDEBAR_WIDTH = 100;

  APARTMENT_VIEW_WIDTH = 400;

  SECTIONS_TITLE_HEIGHT = 25;

  SECTIONS_COLUMN_HEIGHT = 40;

  MAX_COLUMN_WIDTH = 150;

  constructor() {
    makeObservable(this, {
      SECTIONS_TITLE_HEIGHT: observable,
      SECTIONS_COLUMN_HEIGHT: observable,
      FLOOR_SIDEBAR_WIDTH: observable,
      APARTMENT_VIEW_WIDTH: observable,
      setLargeValues: action,
      setSmallValues: action,
    });
  }

  setLargeValues = () => {
    this.SECTIONS_COLUMN_HEIGHT = 40;
    this.SECTIONS_TITLE_HEIGHT = 25;
    this.FLOOR_SIDEBAR_WIDTH = 100;
  };

  setSmallValues = () => {
    this.SECTIONS_COLUMN_HEIGHT = 32;
    this.SECTIONS_TITLE_HEIGHT = 25;
    this.FLOOR_SIDEBAR_WIDTH = 32;
  };
}

export const gridApartmentsStylesStore = new GridApartmentsStylesStore();
