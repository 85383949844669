import React from 'react';
import { observer } from 'mobx-react';
import cc from "classcat";
import { MainComplexTab } from "./MainComplexTab";
import { MainVillageTab } from "./MainVillageTab";
import { BuildingFiltersStore, complexFilterStore } from 'stores';
import { TOpenType } from 'types/filters';
import { ComplexTab } from './ComplexTab';
import { VillageTab } from './VillageTab';
import { ContextValueVillageFilters } from 'context';
import styles from './AllFilters.module.scss';

type Props = {
  openType?: TOpenType,
  store?: BuildingFiltersStore['filters'] | {filters: ContextValueVillageFilters['villageDataFilter']},
  handleChangeStore?: ContextValueVillageFilters["handleChangeVillageFilter"],
  complexId?: string,
}

export const AllFilters = observer(({ openType = 'main', store, handleChangeStore, complexId }: Props) => {
  const renderContent = () => {
    if (openType === 'complex') {
      return <ComplexTab isShowFromComplex store={store as BuildingFiltersStore['filters']} />;
    }

    if (openType === 'village') {
      return (
        <VillageTab
          isShowFromVillage
          store={store}
          handleChangeStore={handleChangeStore}
          complexId={complexId}
        />
      );
    }

    if (openType === 'main') {
      return complexFilterStore.filters.filterTab === 0 ? (
        <MainComplexTab />
      ) : (
        <MainVillageTab
          store={store}
          handleChangeStore={handleChangeStore}
        />
      );
    }

    return null;
  };

  return (
    <div className={cc([styles.formWrapper, openType === 'main' && styles.formWrapperMain])}>
      {renderContent()}
    </div>
  );
});
