import React, { useState } from 'react';
import { useModal } from 'react-modal-hook';
import {
  List,
  Icon,
  FeatureToggle,
  MobilePopup,
  Text,
  ListItem,
  Switch,
} from 'components/atoms';

import hideGrayIcon from 'assets/hideGray.svg';
import userGrayIcon from 'assets/userGray.svg';
import logoutGrayIcon from 'assets/logoutGray.svg';
import { ChoicerUserCity } from 'components/containers/ChoicerUserCity';
import { Router } from 'react-router';
import { Link } from 'react-router-dom';
import { ComponentToggleIsAuth } from 'components/containers/ComponentToggleIsAuth';

import { useHistory } from 'hooks';
import { FavoritesNavButton } from 'components/containers/FavoritesNavButton';
import { OffersNavButton } from 'components/containers/OffersNavButton';
import { rootProfileStore } from 'stores/user/RootProfileStore';
import { appRoutesService } from 'services';
import { ProfileButtonAndLoginMobile } from '../../ProfileButtonAndLogin';
import { BottomSheet } from "../../../../../components/atoms/BottomSheet/BottomSheet";
import { CityMobile } from "./CityMobile/CityMobile";
import { BannerApp } from "../../../../ProfilePage/components/BannerApp/BannerApp";
import styles from './styles.module.css';

type Props = {
  hideNavbarPopupMenu?: () => void;
};

export const NavbarPopupMenu = ({ hideNavbarPopupMenu }: Props) => {
  const { logout } = rootProfileStore.authStore;

  return (
    <>
      <div className={styles.wrapperProfileSection}>
        <div className={styles.wrapperLoginButtonWrapper}>
          <ProfileButtonAndLoginMobile/>
        </div>

        <List>
          <ComponentToggleIsAuth
            componentIsAuth={
              <>
                <ListItem
                  component="div"
                  icon={<Icon src={hideGrayIcon}/>}
                  badge={<Switch/>}
                >
                  <Text weight="700">Я с клиентом</Text>
                </ListItem>
                <ListItem
                  component={Link}
                  to={appRoutesService.replaceRoute('profile')}
                  icon={<Icon src={userGrayIcon}/>}
                >
                  <Text weight="700">Профиль</Text>
                </ListItem>
              </>
            }
          />

          <FavoritesNavButton onClick={hideNavbarPopupMenu}/>

          <OffersNavButton/>

          <ComponentToggleIsAuth
            componentIsAuth={
              <ListItem onClick={logout} icon={<Icon src={logoutGrayIcon}/>}>
                <Text weight="700">Выход</Text>
              </ListItem>
            }
          />
        </List>
      </div>

      <div className={styles.wrapperBannerApp}>
        <BannerApp />
      </div>

      {/*<FeatureToggle hideForProduction>*/}
      {/*  <List className={styles.wrapperMenuSection}>*/}
      {/*    <ListItem>*/}
      {/*      <Text size="XL" weight="700">*/}
      {/*        Купить*/}
      {/*      </Text>*/}
      {/*    </ListItem>*/}
      {/*    <ListItem>*/}
      {/*      <Text size="XL" weight="700">*/}
      {/*        Сдать*/}
      {/*      </Text>*/}
      {/*    </ListItem>*/}
      {/*    <ListItem>*/}
      {/*      <Text size="XL" weight="700">*/}
      {/*        Продать*/}
      {/*      </Text>*/}
      {/*    </ListItem>*/}
      {/*  </List>*/}
      {/*</FeatureToggle>*/}
    </>
  );
};

export const useNavbarPopupMenu = () => {
  const history = useHistory();
  const [isOpenNavbarPopupMenu, setOpen] = useState(false);
  const [isCityModalOpen, setCityModalOpen] = useState(false);
  const [showNavbarPopupMenu, hideNavbarPopupMenu] = useModal(
    () => (
      <>
        <MobilePopup
          headerChildren={
            <div className={styles.citySelector} onClick={() => setCityModalOpen(true)}>
              <ChoicerUserCity/>
            </div>
          }
          isOpen
          onClose={() => {
            setOpen(false);
            hideNavbarPopupMenu();
          }}
          isClose={true}
        >
          <Router history={history}>
            <NavbarPopupMenu
              hideNavbarPopupMenu={() => {
                setOpen(false);
                hideNavbarPopupMenu();
              }}
            />
          </Router>
        </MobilePopup>
        <BottomSheet
          isOpen={isCityModalOpen}
          onClose={() => setCityModalOpen(false)}
          minHeight={200}
          withBackdrop={true}
          className={styles.bottomSheet}
        >
          <CityMobile onClose={() => setCityModalOpen(false)} />
        </BottomSheet>
      </>
    ),
    [isOpenNavbarPopupMenu, setOpen, isCityModalOpen],
  );

  const toggleNavbarPopupMenu = () => {
    if (isOpenNavbarPopupMenu) {
      setOpen(false);
      hideNavbarPopupMenu();
    } else {
      setOpen(true);
      showNavbarPopupMenu();
    }
  };

  return {
    toggleNavbarPopupMenu,
    showNavbarPopupMenu: () => {
      setOpen(true);
      showNavbarPopupMenu();
    },
    hideNavbarPopupMenu: () => {
      setOpen(false);
      hideNavbarPopupMenu();
    },
  };
};
