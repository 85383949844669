import { API_ROUTES } from 'services/constants';
import qs from 'qs';
import { MayBe } from 'types';
import { makeRequest } from '../../../libs/axios/makeRequest';
import { ThrowableResult } from '../../../types';
import { SuggestResponse, SuggestYandex } from './types';
import { loggerService } from '../../../loggerService';

export const suggestService = {
  /**
   * @link https://api.dev-esello.ru/v1/doc#operations-info-get_v1_suggest
   * */
  suggest: async (
    search: string,
  ): Promise<ThrowableResult<SuggestResponse>> => {
    try {
      const result = await makeRequest<SuggestResponse>({
        url: API_ROUTES.suggest,
        method: 'get',
        params: { search },
        paramsSerializer: (params) => qs.stringify(params),
      });

      if (!result?.data?.villages) {
        result.data.villages = [];
      }

      return result?.data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
  yandexSuggest: async (
    search: string,
  ): Promise<ThrowableResult<MayBe<SuggestYandex[]>>> => {
    if (!window.ymaps) {
      loggerService.error('[yandexSuggest]: ymaps is not defined');
      return null;
    }
    try {
      return await window.ymaps.suggest(['Россия', search].join(', '), {
        results: 3,
      });
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
};
