import React from 'react';
import { observer } from 'mobx-react';

import { FavoritesNavButton } from 'components/containers/FavoritesNavButton';
import { OffersNavButton } from 'components/containers/OffersNavButton';

import { ProfileButtonAndLoginDesktop } from '../../../../ProfileButtonAndLogin';
import themeLight from 'assets/theme/theme-light.svg'
import themeDark from 'assets/theme/theme-dark.svg'

import styles from './styles.module.css';
import { useTheme } from "../../../../../../../context/theme/useTheme";

export const NavbarRightContentRenderer = () => {
  const { theme, toggleTheme } = useTheme();

  return (
    <div className={styles.wrapper}>
      <FavoritesNavButton/>

      <OffersNavButton/>

      <ProfileButtonAndLoginDesktop/>

      {
        process.env.REACT_APP_API_V1 === 'https://test.api.flatby.pro' && (
          <div className={styles.wrapperTheme} onClick={toggleTheme}>
            <img src={theme === 'dark-theme' ? themeLight : themeDark} alt="theme"/>
          </div>
        )
      }
    </div>
  )
};

export const NavbarRightContent = observer(NavbarRightContentRenderer);
