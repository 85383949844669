import { useCallback, useState } from 'react';

type Props = {
  initialCount?: number;
  minCount?: number;
  maxCount?: number;
};

export const useCounter = ({ initialCount, maxCount, minCount }: Props) => {
  const [count, _setCount] = useState(initialCount || 0);

  const onDecrement = useCallback(() => {
    _setCount((prev) => {
      if (minCount !== undefined && prev <= minCount) return prev;
      return prev - 1;
    });
  }, [minCount]);

  const onIncrement = useCallback(() => {
    _setCount((prev) => {
      if (maxCount !== undefined && prev >= maxCount) return prev;
      return prev + 1;
    });
  }, [maxCount]);

  const setCount = useCallback(
    (val: number) => {
      _setCount(() => {
        if (maxCount !== undefined && val > maxCount) return maxCount;
        if (minCount !== undefined && val < minCount) return minCount;
        return val;
      });
    },
    [maxCount, minCount],
  );

  return {
    count,
    onDecrement,
    onIncrement,
    setCount,
  };
};
