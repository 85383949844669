import { makeObservable, observable, action, computed } from 'mobx';
import { ID, MayBe } from 'types';
import { toString } from 'lodash';
import { favoritesService } from 'services/v1/rc/favorites';
import {
  ApartmentDto,
  apartmentsService,
  searchService,
  ShortBuildingObject,
} from '../../../services';
import { findById } from '../../../utils/fp';
import { RequestApiStore } from '../../request/RequestApiStore';
import {villageService} from "../../../services/v1/villageService";

type State = {
  apartments: ApartmentDto[];
  complexes: ShortBuildingObject[];
  cottages: any[];
  villages: any[];
};

/**
 * @desc стор хранит полноценные избранного с полноценными объектами,
 * используется для отрисовки избранного в профиле
 * */
export class FavoritesStore {
  state: State = {
    apartments: [],
    complexes: [],
    cottages: [],
    villages: [],
  };

  requestApi: RequestApiStore;

  constructor() {
    this.requestApi = new RequestApiStore();
    makeObservable(this, {
      state: observable,

      clear: action,
      setComplexes: action,
      setApartments: action,
      setCottages: action,
      setVillages: action,

      apartments: computed,
      complexes: computed,
      villages: computed,
      cottages: computed,
    });
  }

  get apartments() {
    return this.state.apartments;
  }

  get complexes() {
    return this.state.complexes;
  }

  get villages() {
    return this.state.villages;
  }

  get cottages() {
    return this.state.cottages;
  }

  clear = () => {
    this.state = {
      apartments: [],
      complexes: [],
      villages: [],
      cottages: [],
    };
  };

  getComplexById = (id: ID): MayBe<ShortBuildingObject> =>
    this.state.complexes.find(findById(id.toString())) || null;

  getApartmentById = (id: ID): MayBe<ApartmentDto> =>
    this.state.apartments.find(findById(id.toString())) || null;

  /**
   * @desc перезаписывает список ЖК
   * */
  setComplexes = (complex: ShortBuildingObject[]) => {
    this.state.complexes = complex;
  };

  /**
   * @desc перезаписывает список квартир
   * */
  setApartments = (apartments: ApartmentDto[]) => {
    this.state.apartments = apartments;
  };

  setCottages = (cottages: any[]) => {
    this.state.cottages = cottages;
  };

  setVillages = (villages: any[]) => {
    this.state.villages = villages;
  };

  loadFavorites = async () => {
    this.requestApi.setLoading(true);
    const [error, result] = await favoritesService.list();
    if (result) {
      this.requestApi.setLoading(false);
    }
    if (error) {
      this.requestApi.setLoading(false);
      this.requestApi.setError(true);
      throw new Error(error.toString());
    }
    return [error, result];
  };

  loadFavoritesComplexes = async (complexIds: ID[]) => {
    this.requestApi.setLoading(true);
    this.requestApi.setError(false);

    try {
      const result = await searchService.search({
        complexIds: complexIds.map(toString),
      });
      this.requestApi.setLoading(false);

      this.setComplexes(result.data?.list || []);
    } catch (e) {
      this.setComplexes([]);
      this.requestApi.setError(true);
      this.requestApi.setLoading(false);
    }
  };

  loadFavoritesApartments = async (apartmentIds: ID[]) => {
    this.requestApi.setLoading(true);
    this.requestApi.setError(false);

    try {
      const result = await apartmentsService.getApartments(
        {},
        {
          apartmentIds: apartmentIds.map(toString),
        },
      );
      this.requestApi.setLoading(false);

      this.setApartments(result.data || []);
    } catch (e) {
      this.requestApi.setError(true);
      this.requestApi.setLoading(false);
    }
  };

  loadFavoritesCottages = async (cottageIds: ID[]) => {
    this.requestApi.setLoading(true);
    this.requestApi.setError(false);

    try {
      const result = await villageService.getCottages(
        {},
        { apartmentIds: cottageIds.map(toString) }
      );

      this.requestApi.setLoading(false);
      this.setCottages(result?.data || []);
    } catch (e) {
      this.requestApi.setError(true);
      this.requestApi.setLoading(false);
    }
  };

  loadFavoritesVillages = async (villageIds: ID[]) => {
    this.requestApi.setLoading(true);
    this.requestApi.setError(false);

    try {
      const result = await searchService.search({
        complexIds: villageIds.map(toString),
      });

      // Заглушка
      this.setVillages(result.data?.list || []);
      this.requestApi.setLoading(false);
    } catch (e) {
      this.setVillages([]);
      this.requestApi.setError(true);
      this.requestApi.setLoading(false);
    }
  };
}
