import React, { RefObject } from 'react';
import cc from 'classcat';
import { Polygon } from 'react-leaflet';
import { observer } from 'mobx-react';

import { Block } from 'pages/ComplexPage/components/Block';

import { BuildingsPolygons } from 'components/atoms/Map/BuildingsPolygons';
import {
  BaseMapContainer,
  ErrorBoundary,
  FlexBox,
  WindowMarkers,
} from 'components/atoms';
import { BuildingEntity, Feature } from 'services';
import { LeafletStoreClass } from 'stores/LeafletStore';
import { YandexMapLayer } from 'components/atoms/Map/YandexMapLayer';

import { InfoMap } from '../InfoMap/InfoMap';
import styles from './styles.module.css';

type Props = {
  polygon?: [number, number][];
  polygon2?: [number, number][];
  buildings?: Array<BuildingEntity>;
  innerRef?: RefObject<HTMLDivElement>;
  windows?: Feature[];
  sectionTitle?: string;
  description?: string;
  className?: string;
  mapClassName?: string;
};

export const NearbyObjectsRender = ({
  polygon,
  polygon2,
  buildings,
  innerRef,
  sectionTitle,
  description,
  className,
  mapClassName,
  windows,
}: Props) => {
  const mapInstance = new LeafletStoreClass();

  return (
    <Block className={className} title={sectionTitle}>
      <FlexBox className={cc([styles.root, mapClassName])} alignItems="stretch">
        {/*
        @todo: скрыт до появления функционала в API (https://team-1603321582509.atlassian.net/browse/MN-1053)
        */}
        {/* <FlexBox */}
        {/*  flexDirection="column" */}
        {/*  className={styles.typeList} */}
        {/*  width="100%" */}
        {/*  alignItems="stretch" */}
        {/* > */}
        {/*  <span className={styles.listHeading} ref={innerRef}> */}
        {/*    {description} */}
        {/*  </span> */}
        {/* </FlexBox> */}

        <FlexBox className={styles.map} width="100%">
          {polygon && (
            <BaseMapContainer
              mapInstanceForZoom={mapInstance.instance}
              center={polygon[0]}
              scrollWheelZoom={false}
              touchZoom={false}
              zoom={14}
            >
              <YandexMapLayer />
              <Polygon pathOptions={{ color: '#4E6AFF' }} positions={polygon} />
              {
                polygon2 && (
                  <Polygon pathOptions={{ color: '#4E6AFF' }} positions={polygon2} />
                )
              }
              <BuildingsPolygons buildings={buildings} />
              <InfoMap storeInstance={mapInstance} polygon={polygon} />
              <ErrorBoundary>
                <WindowMarkers windows={windows} />
              </ErrorBoundary>
            </BaseMapContainer>
          )}
        </FlexBox>
      </FlexBox>
    </Block>
  );
};

export const NearbyObjects = observer(NearbyObjectsRender);
