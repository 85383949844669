import { FC } from "react";
import styles from "./DownloadPWA.module.scss";
import flatbyLogo from "assets/banners/flatbyIcon.svg";
import shareIcon from 'assets/banners/shareIcone.svg';
import step1Img from "assets/banners/step1.png";
import step2Img from "assets/banners/step2.png";
import step3Img from "assets/banners/step3.png";
import step4Img from "assets/banners/step4.png";
import handIcon from 'assets/banners/hand.svg'
import cc from "classcat";
import {Icon} from "../../../../components/atoms";

export const DownloadPWA: FC = () => {
  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <img src={flatbyLogo} alt="Flatby Logo" className={styles.logo}/>
        <h2 className={styles.title}>Зайдите на сайт flatby.pro с мобильного устройства</h2>

        <div className={styles.step}>
          <div className={styles.imageBlock}>
            <img src={handIcon} alt='hand' className={styles.imageBlockIcon1}/>
            <img src={step1Img} alt="Step 1" className={styles.image}/>
          </div>
          <div className={styles.stepText}>
            <span className={styles.stepNumber}>1</span>
            <h3>Выбор меню</h3>
            <p>Нажмите на кнопку <img src={shareIcon} alt="Share" className={styles.stepNumberImg}/></p>
          </div>
        </div>

        <div className={cc([styles.step, styles.step2])}>
          <div className={styles.stepText}>
            <span className={styles.stepNumber}>2</span>
            <h3>Добавить на экран</h3>
            <p>Выберите пункт добавить “На экран “Домой”</p>
          </div>
          <div className={styles.imageBlock}>
            <img src={handIcon} alt='hand' className={styles.imageBlockIcon2}/>
            <img src={step2Img} alt="Step 2" className={styles.image}/>
          </div>
        </div>

        <div className={cc([styles.step, styles.step3])}>
          <div className={styles.imageBlock}>
            <img src={handIcon} alt='hand' className={styles.imageBlockIcon3}/>
            <img src={step3Img} alt="Step 3" className={styles.image}/>
          </div>
          <div className={styles.stepText}>
            <span className={styles.stepNumber}>3</span>
            <h3>Добавить</h3>
            <p>Добавьте приложение на мобильное устройство</p>
          </div>
        </div>

        <div className={styles.step4}>
          <img src={step4Img} alt='step 4' />
        </div>
      </div>
    </div>
  );
};
