import React from 'react';
import { observer } from 'mobx-react';
import { ButtonBase } from '../../../pages/LayoutPage/components/ButtonBase';
import { rootProfileStore } from '../../../stores/user/RootProfileStore';

import AddFavoriteIcon from '../../ui/AddFavoriteIcon';
import AddFavoriteIconSmall from '../../ui/AddFavoriteIconSmall';

type Props = {
  showLabels?: boolean;
  complexID?: number;
  apartmentId?: number;

  // Новые поля для коттеджей
  cottageId?: number;
  villageId?: number;

  className?: string;
  headerMenu?: boolean;
  onClick?: () => void;
};

export const FavoritesAddButtonRender = ({
  showLabels,
  complexID,
  apartmentId,
  cottageId,
  villageId,
  className,
  headerMenu,
  onClick,
}: Props) => {
  const {
    includeComplex,
    includeApartment,
    includeCottage,
    includeVillage,
  } = rootProfileStore.favoritesIdsStore;

  const handleClick = () => {
    if (complexID) {
      void rootProfileStore.favoritesComposeAuth.toggleComplex(complexID);
    }

    if (apartmentId) {
      void rootProfileStore.favoritesComposeAuth.toggleApartment(apartmentId);
    }

    // Новые вызовы
    if (cottageId) {
      void rootProfileStore.favoritesComposeAuth.toggleCottage(cottageId);
    }

    if (villageId) {
      void rootProfileStore.favoritesComposeAuth.toggleVillage(villageId);
    }
  };

  const isFavorite =
    (complexID && includeComplex(complexID)) ||
    (apartmentId && includeApartment(apartmentId)) ||
    (cottageId && includeCottage(cottageId)) ||
    (villageId && includeVillage(villageId));

  return (
    <ButtonBase
      icon={
        headerMenu
          ? <AddFavoriteIcon isFavorite={isFavorite} />
          : <AddFavoriteIconSmall isFavorite={isFavorite} />
      }
      onClick={() => {
        handleClick(); // Логика добавления/удаления
        if (onClick) onClick(); // Если передали внешний обработчик
      }}
      textClassName={className}
      className={className}
    >
      {showLabels && <span>Добавить в избранное</span>}
    </ButtonBase>
  );
};

export const FavoritesAddButton = observer(FavoritesAddButtonRender);
