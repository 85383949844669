import {FC} from 'react';
import smallArrowRight from "assets/smallArrowRight.svg";
import flatbyLogo from "assets/banners/flatbyIcon.svg";
import styles from './BannerApp.module.scss'
import {appRoutesService} from "../../../../services";
import {Link} from "react-router-dom";

export const BannerApp: FC = () => {
  return (
    <Link to={appRoutesService.getRoute('downloadApp')} target="_blank" rel="noopener noreferrer" className={styles.bannerInner}>
      <div className={styles.bannerApp}>
        <img src={flatbyLogo} alt='logo' className={styles.bannerAppImg} />
        <div>
          <span className={styles.bannerAppTitle}>Загрузить Flatby</span>
          <span className={styles.bannerAppText}>Доступна в мобильном приложении!</span>
        </div>
        <img src={smallArrowRight} alt='' />
      </div>
    </Link>
  );
};
