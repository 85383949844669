import qs from 'qs';
import { RequestPageInfo, ThrowableResult } from '../../types';
import { makeRequest } from '../../libs/axios/makeRequest';
import { API_ROUTES } from '../../constants';
import { loggerService } from '../../loggerService';
import {
  VillageDetails,
  CottagesResponse,
  CottageDetails,
} from './types';
import { BuildingSearchParameters } from "../rc/building/types";
import { authTokenStorageService } from "../../storage";

export const villageService = {
  async getVillageDetails(
    villageId: string,
  ): Promise<ThrowableResult<VillageDetails>> {
    try {
      const { data } = await makeRequest<VillageDetails>({
        url: API_ROUTES.village.view.replace(':villageId', villageId),
        method: 'get',
      });

      return data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  async getCottages(
    params?: RequestPageInfo,
    filter?: BuildingSearchParameters,
  ): Promise<ThrowableResult<CottagesResponse>> {
    try {
      const token = authTokenStorageService?.getToken();
      const { data } = await makeRequest<CottagesResponse>({
        url: API_ROUTES.village.cottages,
        method: 'get',
        headers: token ? { 'X-Auth-Token': `${token?.token}` } : {},
        params: {
          ...params,
          ...filter,
        },
        // paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'repeat' }),
        paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'indices' }),
      });

      return data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },

  async getCottageDetails(
    apartmentId: string,
  ): Promise<ThrowableResult<CottageDetails>> {
    try {
      const { data } = await makeRequest<CottageDetails>({
        url: API_ROUTES.village.cottage.replace(':apartmentId', apartmentId),
        method: 'get',
      });

      return data;
    } catch (e) {
      loggerService.error(e);
      throw e;
    }
  },
};
