import React from 'react';
import { useTheme } from "../../../context/theme/useTheme";

const TotalArea = () => {
  const { theme } = useTheme();
  return (
    theme === 'dark-theme' ? (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M7.28039 6.21978L4.50006 3.43945L1.71973 6.21978L2.78039 7.28044L3.75006 6.31077V20.2501H17.6894L16.7197 21.2198L17.7804 22.2804L20.5607 19.5001L17.7804 16.7198L16.7197 17.7804L17.6894 18.7501H5.25006V6.31077L6.21973 7.28044L7.28039 6.21978ZM16.7858 9.53704V10.2593H21V9.44444H18.2088V9.40741L19.3946 8.35185C19.7291 8.05556 20.0027 7.79167 20.2155 7.56019C20.4284 7.3287 20.585 7.10494 20.6853 6.88889C20.7856 6.67284 20.8358 6.43827 20.8358 6.18519C20.8358 5.8642 20.7507 5.57716 20.5804 5.32407C20.4101 5.0679 20.173 4.86728 19.8689 4.72222C19.5649 4.57407 19.2122 4.5 18.8108 4.5C18.2088 4.5 17.7147 4.66204 17.3285 4.98611C16.9424 5.3071 16.7493 5.73148 16.7493 6.25926H17.698C17.698 5.97222 17.7983 5.74074 17.999 5.56481C18.1997 5.3858 18.4642 5.2963 18.7926 5.2963C19.1027 5.2963 19.3627 5.37809 19.5725 5.54167C19.7823 5.70525 19.8872 5.92593 19.8872 6.2037C19.8872 6.3642 19.8507 6.51543 19.7777 6.65741C19.7047 6.79938 19.5968 6.94599 19.4539 7.09722C19.311 7.24846 19.1331 7.41975 18.9203 7.61111L16.7858 9.53704ZM13.7757 7.88889L11.3311 13.5185L8.92297 7.88889H8.57635H7.91959H7.5V15H8.57635V9.45944L10.8932 15H11.7689L14.0858 9.55957V15H15.1622V7.88889H14.7973H14.0858H13.7757Z"
                fill="#585B68"/>
        </svg>
      )
      :
      (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd"
                d="M7.28039 6.21978L4.50006 3.43945L1.71973 6.21978L2.78039 7.28044L3.75006 6.31077V20.2501H17.6894L16.7197 21.2198L17.7804 22.2804L20.5607 19.5001L17.7804 16.7198L16.7197 17.7804L17.6894 18.7501H5.25006V6.31077L6.21973 7.28044L7.28039 6.21978ZM16.7858 9.53704V10.2593H21V9.44444H18.2088V9.40741L19.3946 8.35185C19.7291 8.05556 20.0027 7.79167 20.2155 7.56019C20.4284 7.3287 20.585 7.10494 20.6853 6.88889C20.7856 6.67284 20.8358 6.43827 20.8358 6.18519C20.8358 5.8642 20.7507 5.57716 20.5804 5.32407C20.4101 5.0679 20.173 4.86728 19.8689 4.72222C19.5649 4.57407 19.2122 4.5 18.8108 4.5C18.2088 4.5 17.7147 4.66204 17.3285 4.98611C16.9424 5.3071 16.7493 5.73148 16.7493 6.25926H17.698C17.698 5.97222 17.7983 5.74074 17.999 5.56481C18.1997 5.3858 18.4642 5.2963 18.7926 5.2963C19.1027 5.2963 19.3627 5.37809 19.5725 5.54167C19.7823 5.70525 19.8872 5.92593 19.8872 6.2037C19.8872 6.3642 19.8507 6.51543 19.7777 6.65741C19.7047 6.79938 19.5968 6.94599 19.4539 7.09722C19.311 7.24846 19.1331 7.41975 18.9203 7.61111L16.7858 9.53704ZM13.7757 7.88889L11.3311 13.5185L8.92297 7.88889H8.57635H7.91959H7.5V15H8.57635V9.45944L10.8932 15H11.7689L14.0858 9.55957V15H15.1622V7.88889H14.7973H14.0858H13.7757Z"
                fill="#AFB4CB"/>
        </svg>
      )
  );
};

export default TotalArea;
