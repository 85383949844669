import React, { FC, useRef, useState } from 'react';
import rewardIcon from '../../../assets/reward-icon.png';
import infoIcon from '../../../assets/info-icon.svg';
import styles from './Reward.module.scss';
import cc from "classcat";
import { TooltipInfo } from "../../../../../../../components/atoms/TooltipInfo";

interface Props {
  className?: string;
  count?: number;
  percent?: string | null;
}

export const Reward: FC<Props> = ({ className, count, percent }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const formattedPercent = percent ? percent : null;

  const formatValue = (value: number | string): string => {
    const strValue = String(value);
    return strValue.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  };

  return (
    <div className={cc([styles.reward, className])}>
      <div className={styles.iconWrapper}>
        <img src={rewardIcon} alt="Reward Icon" className={styles.rewardIcon} />
      </div>
      <div className={styles.content}>
        <div className={styles.amount}>{count ? formatValue(Math.floor(count)) : ''} ₽</div>
        <div className={styles.description}>Агентское вознаграждение за этот объект</div>
      </div>
      <div className={styles.contentRight}>
        {percent && <span className={styles.percent}>{formattedPercent}%</span>}
        <div
          className={styles.infoIconWrapper}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <img src={infoIcon} alt="Info Icon" className={styles.infoIcon} />
          {showTooltip && (
            <TooltipInfo
              content="Сумма агентского вознаграждения которую платит застройщик за этот объект"
              width="214px"
              position='left'
            />
          )}
        </div>
      </div>
    </div>
  );
};